
import { deserializeResourceRef, ResourceRef } from '../shared';
import { EventVisibilitySetting } from './eventTemplate';


// ### EventTemplateGroup ### //

export type EventTemplateGroup = {
  id: string;
  published: boolean;
  archived: boolean;
  visible: boolean;

  name: string;
  intro: string;
  description: string;
  media: ResourceRef;
  thumbnailMedia: ResourceRef;
  extraMedia: ResourceRef[];
  extraThumbnailMedia: ResourceRef[];
  groupedEventTemplates: GroupedEventTemplate[];
  showGroupOnly: boolean;
  detailsUrl: string;
  visibilitySetting: EventVisibilitySetting;
  createdTs: number;
}

export type GroupedEventTemplate = {
  eventTemplateId: string;
  name: string;
}

export function deserializeEventTemplateGroup(raw: any) {
  return {
    id: raw.id,
    published: raw.published,
    archived: raw.archived,
    visible: raw.visible,

    name: raw.name,
    intro: raw.intro,
    description: raw.description,
    media: deserializeResourceRef(raw.media),
    thumbnailMedia: deserializeResourceRef(raw.thumbnailMedia),
    extraMedia: raw.extraMedia.map(deserializeResourceRef),
    extraThumbnailMedia: raw.extraThumbnailMedia.map(deserializeResourceRef),
    groupedEventTemplates: raw.groupedEventTemplates.map(deserializeGroupedEventTemplate),
    showGroupOnly: !!raw.showGroupOnly,
    detailsUrl: raw.detailsUrl,
    visibilitySetting: raw.visibilitySetting,
    createdTs: raw.createdTs,
  } as EventTemplateGroup;
}

export function deserializeGroupedEventTemplate(raw: any) {
  return {
    eventTemplateId: raw.eventTemplateId,
    name: raw.name,
  } as GroupedEventTemplate;
}

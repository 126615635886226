
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function Svg() {
  const { windowWidth } = useWindowDimensions();

  return (
    <svg width={windowWidth} height='460' viewBox='0 0 1440 460' fill='none'>
      <path d='M1499.9 -168.316C1702.57 27.4565 1637.57 349.299 1393.39 306.712C1224.08 277.183 968.095 372.078 761.016 373.289C693.755 373.682 472.16 310.003 373.951 375.552C74.1224 575.672 -14.6695 387.782 -109.706 145.727C-239.605 -185.118 212.313 -226.845 559.939 -223.102C732.952 -227.463 1297.23 -364.088 1499.9 -168.316Z' fill='#E9E9FF'/>
    </svg>
  );
}

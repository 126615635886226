import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AppBar, Box, Container, IconButton, Stack } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import { Colors } from 'common/src/constants';
import Env from 'common/src/env';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import { DefaultStyleAttrs } from '../../../constants/styles';
import useAppSelector from '../../../hooks/useAppSelector';
import { useEventTemplate } from '../../../hooks/useResource';
import { selectCart, selectSeatMapHoldTokenExpireTs } from '../../../redux/slices/cart';

import { Page, Text } from 'common/src/components/base';
import CancellationPolicy from '../../../components/CancellationPolicy';
import CountDown from '../../../components/CountDown';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(Env.getStripePublishableKey());

export default function OrderPaymentScreen() {
  const { t } = useTranslation('wbevt.events');

  const navigate = useNavigate();

  const { windowHeight } = useWindowDimensions();
  const holdTokenExpireTs = useAppSelector(selectSeatMapHoldTokenExpireTs);

  const cart = useAppSelector(selectCart);
  const { selectedEventTemplateId: eventTemplateId, selectedSurveyResponses } = cart;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const ticketOrderId = query.get('toid');
  const setupIntentSecret = query.get('sis');

  const onExpiration = ()=>{
    navigate(`/e/${eventTemplateId}/r`);
  };

  const options = {
    clientSecret: setupIntentSecret,
    appearance: {
      theme: 'stripe',
    },
  } as StripeElementsOptions;

  const eventTemplate = useEventTemplate(eventTemplateId);
  if (!eventTemplateId) {
    navigate('/');
    return null;
  }

  if (!eventTemplate) {
    return null;
  }

  if (!ticketOrderId || !setupIntentSecret) {
    navigate('/');
    return null;
  }

  return (
    <Page>
      <AppBar position='fixed' color='transparent'>
        <Box px={DefaultStyleAttrs.mx}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <IconButton onClick={() => navigate('/p/cart')}>
              <KeyboardBackspaceIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 30 }} />
            </IconButton>
            <Text size='subtitle' color='system'>{t('registration.register')}</Text>
            <Box />
          </Stack>
        </Box>
      </AppBar>

      <Box
        mt={50} mb={75} height={windowHeight - 50-75} py={20} overflow='auto'
        bgcolor={Colors.DEFAULT_BACKGROUND}
      >
        {eventTemplate.seatMapId && holdTokenExpireTs &&
        <CountDown expiryTs={holdTokenExpireTs} onExpiration={onExpiration} />
        }
        <Container>
          <Box mx={50} mt={30}>
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm
                ticketOrderId={ticketOrderId}
                selectedSurveyResponses={selectedSurveyResponses}
              />
            </Elements>

            <CancellationPolicy eventTemplate={eventTemplate} />
          </Box>
        </Container>
      </Box>
    </Page>
  );
}

import { deserializeResourceRef, ResourceRef } from '../shared';


// ### EventLocation ### //

export type EventLocation = {
  summary: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  latlng: string;
  addressNotes: string;
}

export function deserializeEventLocation(raw: any) {
  return {
    summary: raw.summary,
    address: raw.address,
    city: raw.city,
    state: raw.state,
    zipcode: raw.zipcode,
    latlng: raw.latlng,
    addressNotes: raw.addressNotes,
  } as EventLocation;
}

export function getHumanReadableEventAddress(location: EventLocation) {
  let addressStr = '';
  if (location.address) {
    addressStr += (location.address + ', ');
  }
  if (location.city) {
    addressStr += (location.city + ' ');
  }
  if (location.state) {
    addressStr += (location.state + ', ');
  }
  if (location.zipcode) {
    addressStr += (location.zipcode + ' ');
  }
  if (location.addressNotes) {
    if (addressStr) {
      addressStr += ('(' + location.addressNotes + ')');
    } else {
      addressStr = location.addressNotes;
    }
  }
  return addressStr;
}


// ### EventFeePolicy ### //

export type EventFeePolicy = {
  taxPercentage: number;
  feePercentage: number;
  feeInCents: number;
}

export function deserializeEventFeePolicy(raw: any) {
  return {
    taxPercentage: +raw.taxPercentage,
    feePercentage: +raw.feePercentage,
    feeInCents: +raw.feeInCents,
  } as EventFeePolicy;
}


// ### EventContact ### //

export type EventContact = {
  name: string;
  type: EventContactType;
  principal: string;
  refMedia: ResourceRef;
}

export enum EventContactType {
  PHONE = 'phone',
  WECHAT = 'wechat'
}

export function deserializeEventContact(raw: any) {
  return {
    name: raw.name,
    type: raw.type as EventContactType,
    principal: raw.principal,
    refMedia: deserializeResourceRef(raw.refMedia),
  } as EventContact;
}


// ### Misc ### //

export enum Currency {
  USD = 'usd',
  CAD = 'cad'
}

export type Vendor = {
  id: string;
  name: string;
  description: string;
  refMedia: ResourceRef;
  createdTs: number;
}

export function deserializeVendor(raw: any) {
  return {
    id: raw.id,
    name: raw.name,
    description: raw.description,
    refMedia: deserializeResourceRef(raw.refMedia),
    thumbnailRefMedia: raw.thumbnailRefMedia ? deserializeResourceRef(raw.thumbnailRefMedia) : null,
    createdTs: +raw.createdTs,
  } as Vendor;
}

export type EventFeedback = {
  rating: number;
  comments: string;
  optOut: boolean;
}

export function deserializeEventFeedback(raw: any) {
  return {
    rating: raw.rating? +raw.rating : 0,
    comments: raw.comments? raw.comments : '',
    optOut: raw.optOut,
  } as EventFeedback;
}

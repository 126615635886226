import moment, { Moment } from 'moment-timezone';

type TZ_TYPE = string | 'local';

export function getEventDateLabel(ts1: number, ts2: number, tz: TZ_TYPE): string {
  const mmt1 = toMoment(ts1, tz);
  const mmt2 = toMoment(ts2, tz);
  const removeYear = isSameYear(mmt1) && isSameYear(mmt2);
  const startDate = getHumanReadableDateHelper(mmt1, removeYear);
  const endDate = getHumanReadableDateHelper(mmt2, removeYear);
  if (startDate === endDate) {
    return `${startDate} (${getDayOfWeek(mmt1)})`;
  } else {
    return `${startDate} - ${endDate}`;
  }
}

export function getHumanReadableDateTimeRange(ts1: number, ts2: number, tz: TZ_TYPE): string {
  const mmt1 = toMoment(ts1, tz);
  const mmt2 = toMoment(ts2, tz);
  const removeYear = isSameYear(mmt1) && isSameYear(mmt2);
  const startDateTime = getHumanReadableDateTimeWithWeek(ts1, tz, !removeYear);
  let endDateTime;
  if (getHumanReadableDateHelper(mmt1) === getHumanReadableDateHelper(mmt2)) {
    endDateTime = `${getHumanReadableTimeHelper(mmt2, 'hh:mm')}`;
  } else {
    endDateTime = getHumanReadableDateTimeWithWeek(ts2, tz, !removeYear);
  }
  return `${startDateTime} - ${endDateTime}`;
}

export function getHumanReadableDateTime(ts: number, tz: TZ_TYPE, forceShowYear?: boolean): string {
  const mmt = toMoment(ts, tz);
  const removeYear = isSameYear(mmt) && !forceShowYear;
  return `${getHumanReadableDateHelper(mmt, removeYear)} ${getHumanReadableTimeHelper(mmt, 'hh:mm')}`;
}

export function getHumanReadableDateTimeWithWeek(ts: number, tz: TZ_TYPE, forceShowYear?: boolean): string {
  const mmt = toMoment(ts, tz);
  const removeYear = isSameYear(mmt) && !forceShowYear;
  return `${getDayOfWeek(mmt)} ${getHumanReadableDateHelper(mmt, removeYear)} ${getHumanReadableTimeHelper(mmt, 'hh:mm')}`;
}

// only return week date hour, example: 星期一 12/12/22 11pm
export function getHumanReadableDateHourTimeWithWeek(ts: number, tz: TZ_TYPE, forceShowYear?: boolean): string {
  const mmt = toMoment(ts, tz);
  const removeYear = isSameYear(mmt) && !forceShowYear;
  return `${getDayOfWeek(mmt)} ${getHumanReadableDateHelper(mmt, removeYear)} ${getHumanReadableTimeHelper(mmt, 'hh')}`;
}

export function getHumanReadableDate(ts: number, tz: TZ_TYPE): string {
  const mmt = toMoment(ts, tz);
  return getHumanReadableDateHelper(mmt);
}

export function getCurrentDateTime(tz: TZ_TYPE): string {
  const mmt = toMoment(moment.now(), tz);
  return `${getHumanReadableDateHelper(mmt)} ${getHumanReadableTimeHelper(mmt, 'hh:mm:ss.SSS')}`;
}

function isSameYear(mmt: Moment): boolean {
  const now = nowWithEquivalentTimezone(mmt);
  return now.year() === mmt.year();
}

function nowWithEquivalentTimezone(mmt: Moment): Moment {
  let now = moment();
  const tz = mmt.tz();
  if (tz) {
    now = now.tz(tz);
  }
  return now;
}

function getDayOfWeek(mmt: Moment): string {
  switch (mmt.day()) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      throw new Error('BUG: Invalid day of week ' + mmt.day());
  }
}

export function getHumanReadableTimeHelper(mmt: Moment, format: 'hh' | 'hh:mm' | 'hh:mm:ss.SSS'): string {
  switch (format) {
    case 'hh':
      return mmt.format('ha');
    case 'hh:mm':
      return mmt.format('h:mma');
    case 'hh:mm:ss.SSS':
      return mmt.format('h:mm:ss.SSSa');
    default:
      throw new Error('BUG: Invalid format ' + format);
  }
}

export function getHumanReadableDateHelper(mmt: Moment, removeYear?: boolean): string {
  const month = mmt.month() + 1;
  const day = mmt.date();
  if (removeYear) {
    return `${month}/${day}`;
  } else {
    const year = mmt.year() - 2000;
    return `${month}/${day}/${year}`;
  }
}

function toMoment(ts: number, tz: TZ_TYPE): Moment {
  let mmt = moment(ts);
  if (tz !== 'local') {
    mmt = mmt.tz(tz);
  }
  return mmt;
}

export function getAge(dobStr: string): number {
  // If year > 1900, meaning the date is an actual date, do the usual computation and do the right thing.
  // Otherwise, date is encoded with 1700+age, we would just take year - 1700.

  const dob = new Date(dobStr); // This parses as UTC date and treats as local time, so we need to use UTC functions
  if (dob.getUTCFullYear() > 1900) {
    const now = new Date();
    let years = now.getFullYear() - dob.getUTCFullYear();
    if (now.getMonth() < dob.getUTCMonth() ||
      now.getMonth() === dob.getUTCMonth() && now.getDate() < dob.getUTCDate()) {
      years--;
    }
    return years;
  } else {
    return dob.getUTCFullYear() - 1700;
  }
}

export function utcDateToFormatedStr(date: Date): string {
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const year = date.getUTCFullYear();

  return [year, month, day].join('-');
}

// example: 2021-01-01T00:00:00.000Z
export function getISOTime(ts: number): string {
  const date = new Date(ts);
  return date.toISOString();
}

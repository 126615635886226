import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AppBar, Box, Button, Container, IconButton, Modal, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { AuthContext, LoginResult } from 'common/src/context/auth';
import useNotifier from 'common/src/hooks/useNotifier';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import { SurveyResponse } from 'common/src/models/event';
import { collateSelectedTicketOptions, SelectedTicketOption } from 'common/src/models/event/ticket';
import { RegistrationStatus } from 'common/src/models/registration';
import { DefaultStyleAttrs } from '../../../constants/styles';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { useEventTemplate, useEventTicketInventory } from '../../../hooks/useResource';
import { setCart } from '../../../redux/slices/cart';
import { holdSeats } from '../../../redux/slices/event';
import { selectMyUserInfo } from '../../../redux/slices/user';
import { validateQuestionnaire } from '../../../utils/validation';

import { Page, Text } from 'common/src/components/base';
import CancellationPolicy from '../../../components/CancellationPolicy';
import { EventTemplateIntroHeader } from '../../../components/IntroHeader';
import ContactInfo from './ContactInfo';
import QuestionnaireModal from './QuestionnaireModal';
import TicketSelectorView from './TicketSelectorView';
import WaiverModal from './WaiverModal';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function TicketSelectionScreen() {
  const { t } = useTranslation('wbevt.events');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notifier = useNotifier();
  const { eventTemplateId } = useParams();
  const { windowHeight } = useWindowDimensions();

  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for TicketSelectionScreen');
  }

  const eventTemplate = useEventTemplate(eventTemplateId);
  const eventTicketInventory = useEventTicketInventory(eventTemplateId);
  const [waiverAccepted, setWaiverAccepted] = React.useState(false);
  const [showQuestionnaireModal, setShowQuestionnaireModal] = React.useState(false);
  const [showWaiverModal, setShowWaiverModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  const myUserInfo = useAppSelector(selectMyUserInfo);

  const initStatus = useAppSelector((state) => state.init.status);
  const { authStatus } = React.useContext(AuthContext);
  const ready = authStatus === 'loggedOut' || authStatus === 'loggedIn' && initStatus === 'initialized';

  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phoneToken, setPhoneToken] = React.useState('');
  const [selectedTicketOptions, setSelectedTicketOptions] = React.useState<SelectedTicketOption[]>([]);

  const [surveyResponses, setSurveyResponses] = React.useState<SurveyResponse[]>([]);

  const { login, registerUser } = React.useContext(AuthContext);

  const [displayedErrMsg, setDisplayedErrMsg] = React.useState('');
  const [highlightTicketCountNotice, setHighlightTicketCountNotice] = React.useState(false);

  const handlePhoneChange = React.useCallback((newPhone: string) => {
    setPhone(newPhone);
    setDisplayedErrMsg('');
    setHighlightTicketCountNotice(false);
  }, []);

  const handleEmailChange = React.useCallback((newEmail: string) => {
    setEmail(newEmail);
    setDisplayedErrMsg('');
    setHighlightTicketCountNotice(false);
  }, []);

  const handleSelectedTicketQuantityChange = React.useCallback((ticketOptionId: string, isAdd: boolean, seatId?: string) => {
    if (!eventTemplate) {
      throw new Error('BUG: eventTemplate missing');
    }
    if (eventTemplate.seatMapId && !seatId) {
      throw new Error('BUG: seatId missing');
    }

    setSelectedTicketOptions((selectedTicketOptions) => {
      const matchingSelectedTicketOptionIdx = selectedTicketOptions.findIndex((selectedTicketOption) => {
        return selectedTicketOption.id === ticketOptionId && selectedTicketOption.seatId === seatId;
      });
      const matchingSelectedTicketOption =
          matchingSelectedTicketOptionIdx >= 0 ? selectedTicketOptions[matchingSelectedTicketOptionIdx] : null;
      const selectedQuantity = matchingSelectedTicketOption ? matchingSelectedTicketOption.quantity : 0;

      const newSelectedTicketOptions = [...selectedTicketOptions];
      if (!matchingSelectedTicketOption) {
        if (!isAdd) {
          throw new Error('BUG: trying to decrement non-existent ticket');
        }

        newSelectedTicketOptions.push({
          id: ticketOptionId,
          quantity: 1,
          seatId: seatId,
          selectedTicketAddOns: {},
        });

        // sort this so that it is in the same order as the ticketOptions
        const ticketOptions = eventTemplate.payload.hostedEventPayload.ticketOptions;
        newSelectedTicketOptions.sort((a, b) => {
          const aIdx = ticketOptions.findIndex((ticketOption) => ticketOption.id === a.id);
          const bIdx = ticketOptions.findIndex((ticketOption) => ticketOption.id === b.id);
          return aIdx - bIdx;
        });
      } else {
        const newQuantity = selectedQuantity + (isAdd ? 1 : -1);
        if (newQuantity === 0) {
          newSelectedTicketOptions.splice(matchingSelectedTicketOptionIdx, 1);
        } else {
          newSelectedTicketOptions[matchingSelectedTicketOptionIdx] = {
            ...matchingSelectedTicketOption,
            quantity: newQuantity,
          };
        }
      }

      return newSelectedTicketOptions;
    });

    setDisplayedErrMsg('');
    setHighlightTicketCountNotice(false);
  }, [eventTemplate]);

  const selectedTicketsCount = selectedTicketOptions.reduce((acc, selectedTicketOption) => {
    return acc + selectedTicketOption.quantity;
  }, 0);

  const handlePhoneTokenChange = React.useCallback(async (token: string) => {
    setPhoneToken(token);
    if (!token) {
      return;
    }

    const loginResult = await login(token);
    switch (loginResult) {
      case LoginResult.SUCCESS:
        // Do nothing. App will automatically call init() from App.tsx
        break;
      case LoginResult.NOT_REGISTERED:
        break;
    }
  }, [login]);

  const validatePrereq = React.useCallback(() => {
    if (!eventTemplate) {
      return false;
    }
    const eventPayload = eventTemplate.payload.hostedEventPayload;
    const selectedTicketOptionsCount = collateSelectedTicketOptions(selectedTicketOptions).length;

    if (
      (eventPayload.minSelectableTickets !== 0 && selectedTicketsCount < eventPayload.minSelectableTickets) ||
      (eventPayload.maxSelectableTickets !== 0 && selectedTicketsCount > eventPayload.maxSelectableTickets) ||
      selectedTicketOptionsCount > eventPayload.maxSelectableTicketOptions
    ) {
      setDisplayedErrMsg(t('registration.invalidTicketCount'));
      setHighlightTicketCountNotice(true); // TODO(hank): do i need this?
      return false;
    }

    if (!(myUserInfo || (phoneToken && email && EMAIL_REGEX.test(email)))) {
      setDisplayedErrMsg(t('registration.invalidPhoneOrEmail'));
      setHighlightTicketCountNotice(false);
      return false;
    }

    return true;
  }, [eventTemplate, selectedTicketOptions, selectedTicketsCount, myUserInfo, phoneToken, email, t]);

  const onConfirm = () => {
    setShowErrorModal(false);
    window.location.reload();
  };
  const handlePaymentPress = React.useCallback(async () => {
    if (!eventTemplate) {
      return;
    }

    // Do validation
    if (!validatePrereq()) {
      return;
    }

    const surveyQuestions = eventTemplate.payload.hostedEventPayload.surveyQuestions;
    if (surveyQuestions.length > 0 && !validateQuestionnaire(surveyQuestions, surveyResponses)) {
      setShowQuestionnaireModal(true);
      return;
    }

    if (!waiverAccepted && eventTemplate.payload.hostedEventPayload.waiver) {
      setShowWaiverModal(true);
      return;
    }

    if (!myUserInfo) {
      notifier.showSpinner({ type: 'loading' });
      const res = await registerUser({ phoneToken, email });
      notifier.hideSpinner();
      if (res !== RegistrationStatus.OK) {
        throw new Error('BUG: Failed to register');
      }
    }

    if (eventTemplate.seatMapId) {
      const seatIds = selectedTicketOptions
        .map((option) => option.seatId) as string[];
      const result = await dispatch(holdSeats({ eventTemplateId, seatIds }));
      if (result.payload != '') {
        setShowErrorModal(true);
        return;
      }
    }

    dispatch(setCart({
      selectedEventTemplateId: eventTemplateId,
      selectedTicketOptions: selectedTicketOptions,
      selectedSurveyResponses: surveyResponses,
    }));
    navigate('/p/cart');
  }, [
    eventTemplate, validatePrereq, surveyResponses, waiverAccepted, myUserInfo, dispatch,
    eventTemplateId, selectedTicketOptions, navigate, notifier, registerUser, phoneToken, email,
  ]);

  if (!eventTemplate || !eventTicketInventory) {
    return null;
  }

  let label = t('registration.register');
  let note = '';
  // iterate through all the selected ticket options and check if any of them have insufficient tickets remaining
  const ticketOptionToTicketsRemaining = eventTicketInventory.ticketOptionToTicketsRemaining;
  const collatedSelectedTicketOptions = collateSelectedTicketOptions(selectedTicketOptions);
  for (const collatedSelectedTicketOption of collatedSelectedTicketOptions) {
    if (ticketOptionToTicketsRemaining[collatedSelectedTicketOption.id] < collatedSelectedTicketOption.quantity) {
      label = t('registration.joinWaitlist');
      note = t('registration.joinWaitlistDescription');
      break;
    }
  }

  const questionnaireModal = (
    <QuestionnaireModal
      visible={showQuestionnaireModal}
      eventTemplate={eventTemplate}
      surveyResponses={surveyResponses}
      onSurveyResponsesChange={setSurveyResponses}
      onClose={()=>setShowQuestionnaireModal(false)}
      onComplete={() => {
        setShowQuestionnaireModal(false);
        handlePaymentPress();
      }}
    />
  );

  const waiverModal = (
    <WaiverModal
      visible={showWaiverModal}
      waiverAccepted={waiverAccepted}
      waiverMsg={eventTemplate.payload.hostedEventPayload.waiver}
      onClose={()=>setShowWaiverModal(false)}
      onPayment={() => {
        setShowWaiverModal(false);
        handlePaymentPress();
      }}
      onToggleWaiverAcceptance={() => setWaiverAccepted((val) => !val)}
    />
  );

  const errorModal = (
    <Modal
      open={showErrorModal}
      onClose={() => onConfirm()}
    >
      <Box
        sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
          width: 450, bgcolor: 'background.paper', boxShadow: 24,
        }}
      >
        <Text variant='italics' color='red' mb={16}>{t('registrationResult.holdSeatsError')}</Text>

        <Box mt={24}>
          <Button
            variant='contained'
            onClick={() => onConfirm()}
            sx={{ mr: 10 }}
          >
            {t('registrationResult.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
  return (
    <Page>
      <AppBar position='fixed' color='transparent'>
        <Box px={DefaultStyleAttrs.mx}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <IconButton onClick={() => navigate(`/e/${eventTemplateId}`)}>
              <KeyboardBackspaceIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 30 }} />
            </IconButton>
            <Text size='subtitle' color='system'>{t('registration.register')}</Text>
            <Box />
          </Stack>
        </Box>
      </AppBar>

      <Box
        mt={50} mb={75} height={windowHeight - 50-75} py={20} overflow='auto'
        bgcolor={Colors.DEFAULT_BACKGROUND}
      >
        <Container>
          <Box mx={DefaultStyleAttrs.responsiveMx}>
            <EventTemplateIntroHeader variant='header' eventTemplate={eventTemplate} />

            <TicketSelectorView
              eventTemplate={eventTemplate}
              eventTicketInventory={eventTicketInventory}
              selectedTicketOptions={selectedTicketOptions}
              highlightTicketCountNotice={highlightTicketCountNotice}
              onSelectedTicketQuantityChange={handleSelectedTicketQuantityChange}
            />

            <ContactInfo
              ready={ready}
              editable={!myUserInfo}
              phone={myUserInfo ? myUserInfo.contact.phone : phone}
              phoneToken={phoneToken}
              email={myUserInfo ? myUserInfo.contact.email : email}
              onPhoneChange={handlePhoneChange}
              onPhoneTokenChange={handlePhoneTokenChange}
              onEmailChange={handleEmailChange}
            />

            <CancellationPolicy eventTemplate={eventTemplate} />
          </Box>
        </Container>
      </Box>

      <AppBar position='fixed' color='transparent' sx={{ top: 'auto', bottom: 0 }}>
        <Box py={20} px={DefaultStyleAttrs.mx} bgcolor={Colors.WHITE}>
          <Box
            display='flex' justifyContent='flex-end' alignItems='center'
          >
            {!!displayedErrMsg && (
              <Text size='note' color={Colors.ERROR} pr={15}>{displayedErrMsg}</Text>
            )}
            <Button
              onClick={handlePaymentPress}
              variant='contained'
              disabled={selectedTicketsCount === 0 || !ready}
            >
              {label}
            </Button>
          </Box>
          {!!note && (
            <Text color='system' size='note' my={10} display='flex' justifyContent='end'>* {note}</Text>
          )}
        </Box>
      </AppBar>
      {questionnaireModal}
      {waiverModal}
      {errorModal}
    </Page>
  );
}

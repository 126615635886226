import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccessTime as AccessTimeIcon, PlaceOutlined as PlaceOutlinedIcon,
} from '@mui/icons-material';
import { Box, CardMedia, Stack } from '@mui/material';

import { Colors, ComponentStyles } from 'common/src/constants';
import { EventTemplate, EventTemplateGroup } from 'common/src/models/event';
import { EVENT_TAGS, getEventDateLabelWithMask } from 'common/src/models/event/eventTemplate';
import { ResourceRef } from 'common/src/models/shared';
import { getHumanReadableDateTimeRange } from 'common/src/utils/time';

import { Text } from 'common/src/components/base';


interface IEventTemplateIntroHeaderProps {
  variant: 'cover' | 'header';
  eventTemplate: EventTemplate;
}
const EventTemplateIntroHeader: React.FC<IEventTemplateIntroHeaderProps> = ({
  variant, eventTemplate,
}) => {
  const { t, i18n } = useTranslation('wbevt.common');

  const effectiveTags = [];
  if (eventTemplate.singlesEvent) {
    effectiveTags.push(t('common.singleEvent'));
  }
  eventTemplate.tags.forEach((tag) => {
    effectiveTags.push(i18n.language === 'zh' ? tag: EVENT_TAGS[tag]);
  });
  return (
    <IntroHeader
      variant={variant}
      name={eventTemplate.name}
      datetime={getEventDateLabelWithMask(eventTemplate, /* showTime */ true)}
      location={eventTemplate.location.summary ?
        eventTemplate.location.summary + ' (' + eventTemplate.location.city + ')':
        eventTemplate.location.city + ' ' + eventTemplate.location.state}
      tags={effectiveTags}
      intro={eventTemplate.intro}
      media={eventTemplate.media}
    />
  );
};


interface IEventTemplateGroupIntroHeaderProps {
  variant: 'cover' | 'header';
  eventTemplateGroup: EventTemplateGroup;
  associatedEventTemplates: EventTemplate[];
}
const EventTemplateGroupIntroHeader: React.FC<IEventTemplateGroupIntroHeaderProps> = ({
  variant, eventTemplateGroup, associatedEventTemplates,
}) => {
  const allLocations = associatedEventTemplates.map((eventTemplate) => eventTemplate.location);
  let effectiveLocation;
  if (allLocations.every((location) => location.summary === allLocations[0].summary && location.city === allLocations[0].city)) {
    effectiveLocation = allLocations[0].summary + ' (' + allLocations[0].city + ')';
  } else if (allLocations.every((location) => location.city === allLocations[0].city && location.state === allLocations[0].state)) {
    effectiveLocation = allLocations[0].city + ' ' + allLocations[0].state;
  } else {
    effectiveLocation = 'Various';
  }

  let effectiveDatetime;
  if (associatedEventTemplates.some((eventTemplate) => Date.now() < eventTemplate.payload.hostedEventPayload.registrationFromTs)) {
    effectiveDatetime = '即将上线';
  } else {
    const allEventFromTs = associatedEventTemplates.map((eventTemplate) => eventTemplate.eventFromTs);
    const allEventToTs = associatedEventTemplates.map((eventTemplate) => eventTemplate.eventToTs);
    const allEventTimeZone = associatedEventTemplates.map((eventTemplate) => eventTemplate.eventTimeZone);
    if (new Set(allEventTimeZone).size !== 1) {
      effectiveDatetime = 'Various';
    } else {
      effectiveDatetime = getHumanReadableDateTimeRange(
        Math.min(...allEventFromTs), Math.max(...allEventToTs), allEventTimeZone[0]);
    }
  }

  let effectiveMedia;
  if (eventTemplateGroup.media.id) {
    effectiveMedia = eventTemplateGroup.media;
  } else {
    const firstEventTemplate = associatedEventTemplates[0];
    effectiveMedia = firstEventTemplate.media;
  }

  return (
    <IntroHeader
      variant={variant}
      name={eventTemplateGroup.name}
      datetime={effectiveDatetime}
      location={effectiveLocation}
      tags={[]}
      intro={eventTemplateGroup.intro}
      media={effectiveMedia}
    />
  );
};

interface IIntroHeaderProps {
   // 'cover' is a large header that covers much of the screen
   // 'header' is a smaller header that provides context for the page
  variant: 'cover' | 'header';
  name: string;
  datetime: string;
  location: string;
  tags: string[];
  intro: string;
  media: ResourceRef;

}
const IntroHeader: React.FC<IIntroHeaderProps> = ({
  variant, name, datetime, location, tags, intro, media,
}) => {
  const timeLocationView = (
    <Box>
      {!!datetime && (
        <Stack direction='row' alignItems='center'>
          <AccessTimeIcon
            color='primary'
            sx={{ fontSize: ComponentStyles.text.title.fontSize, mr: 7 }}
          />
          <Text color='primary' size='subtitle' maxLines={1}>{datetime}</Text>
        </Stack>
      )}
      <Stack direction='row' alignItems='center' mt={4}>
        <PlaceOutlinedIcon
          color='primary'
          sx={{ fontSize: ComponentStyles.text.title.fontSize, mr: 7 }}
        />
        <Text color='primary' size='subtitle' maxLines={1}>{location}</Text>
      </Stack>
    </Box>
  );

  switch (variant) {
    case 'header':
      return (
        <Stack direction={{ sm: 'column', md: 'row' }}>
          <CardMedia
            component='img'
            image={media.downloadUrl}
            alt={media.id}
            sx={{ mr: 30, height: 200, width: { sm: '100%', md: 350 }, borderRadius: 3 }}
          />
          <Box mt={{ sm: 15, md: 0 }}>
            <Text size='title' variant='bold'>{name}</Text>
            <Box mt={10}>
              {timeLocationView}
            </Box>
          </Box>
        </Stack>
      );
    case 'cover':
      return (
        <Box position='relative'>
          <Box
            mt={{ sm: 0, md: 30 }} mb={{ sm: 15, md: 30 }}
            borderRadius={3} border={5} borderColor='white'
            overflow='hidden'
          >
            <CardMedia
              component='img'
              image={media.downloadUrl}
              alt={media.id}
              sx={{ height: { sm: 200, md: 400 }, borderRadius: 3 } }
            />
          </Box>
          <Text size={{ sm: 'title', md: 'banner' }} variant='bold'>{name}</Text>
          <Text size='subtitle' mt={10}>{intro}</Text>
          {tags.length > 0 && (
            <Box mt={10}>
              {tags.map((tag) => (
                <Box display='inline-block' key={tag} bgcolor={Colors.PRIMARY_BACKGROUND} borderRadius={1} px={7} py={3} mb={3} mr={4}>
                  <Text size='note' color='system'>{tag}</Text>
                </Box>
              ))}
            </Box>
          )}
          <Box bgcolor='#E8E7EA' my={20} p={20}>
            {timeLocationView}
          </Box>
        </Box>
      );
  }
};

export { EventTemplateIntroHeader, EventTemplateGroupIntroHeader };

import * as React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import { DefaultStyleAttrs } from '../../../../event/src/constants/styles';

import 'react-horizontal-scrolling-menu/dist/styles.css';
import './style.css';

const ARROW_WIDTH = 40;
const GAP_WIDTH = 12; // Note this is defined in the css .scroll-menu-container (doubled)

interface IProps {
  children: React.ReactElement[];
}

const HorizontalScroll: React.FC<IProps> = ({ children }) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const { windowWidth } = useWindowDimensions();

  return (
    <Box mx={{ sm: DefaultStyleAttrs.responsiveMx.sm, md: DefaultStyleAttrs.responsiveMx.md-ARROW_WIDTH }}>
      <ScrollMenu
        LeftArrow={!isSm ? LeftArrow : undefined}
        RightArrow={!isSm ? RightArrow : undefined}
        scrollContainerClassName='horizontal-scroll-container' itemClassName='horizontal-scroll-item'
      >
        {children
          .map((child, idx) => (
            <Box
              key={idx}
              width={{
                sm: windowWidth - 2*DefaultStyleAttrs.responsiveMx.sm - GAP_WIDTH - (children.length > 1 ? 24 : 0),
                md: (windowWidth - 2*DefaultStyleAttrs.responsiveMx.md)/2 - GAP_WIDTH - (children.length > 2 ? 12 : 0),
                lg: (1024 - 2*DefaultStyleAttrs.responsiveMx.md)/3 - GAP_WIDTH - (children.length > 3 ? 8 : 0),
              }}
              display='flex'
            >
              {child}
            </Box>
          ))}
      </ScrollMenu>
    </Box>
  );
};

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Box
      width={ARROW_WIDTH} display='flex' alignItems='center' justifyContent='center'
      sx={{ cursor: isFirstItemVisible ? undefined : 'pointer' }}
      onClick={() => scrollPrev()}
    >
      <ChevronLeftIcon color='primary' fontSize='large' sx={{ opacity: isFirstItemVisible ? 0 : 1 }} />
    </Box>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Box
      width={ARROW_WIDTH} display='flex' alignItems='center' justifyContent='center'
      sx={{ cursor: isLastItemVisible ? undefined : 'pointer' }}
      onClick={() => scrollNext()}
    >
      <ChevronRightIcon color='primary' fontSize='large' sx={{ opacity: isLastItemVisible ? 0 : 1 }} />
    </Box>
  );
}

export default HorizontalScroll;

import * as React from 'react';
import { Box } from '@mui/material';
import { Pricing, SeatsioSeatingChart, SelectableObject } from '@seatsio/seatsio-react';

import Env from 'common/src/env';
import { EventTemplate } from 'common/src/models/event';

interface IProps {
  eventTemplate: EventTemplate;
  onSeatSelectionChange: (ticketOptionId: string, isAdd: boolean, seatId: string) => void;
}
const SeatMap: React.FC<IProps> = ({ eventTemplate, onSeatSelectionChange }) => {
  const pricing: Pricing = eventTemplate.payload.hostedEventPayload.ticketOptions.map((ticket)=>{
    return { category: ticket.name, price: ticket.genericCostInCents/100 };
  });

  const handleSeatToggle = React.useCallback((selectedObject: SelectableObject, isAdd: boolean) => {
    if (!selectedObject.category) {
      throw new Error('BUG: ticket category missing');
    }
    const ticketOptionId = selectedObject.category.key.toString();

    const selectedSeatId = selectedObject.label;
    onSeatSelectionChange(ticketOptionId, isAdd, selectedSeatId);
  }, [onSeatSelectionChange]);


  return (
    <Box pt={30}>
      <SeatsioSeatingChart
        workspaceKey={Env.getSeatIoWorkspaceKey()}
        event={eventTemplate.seatMapId}
        pricing={pricing}
        region='na'
        onObjectSelected={(object)=>handleSeatToggle(object, /* isAdd */ true)}
        onObjectDeselected={(object)=>handleSeatToggle(object, /* isAdd */ false)}
        priceFormatter={(price) => `$${price.toFixed(2)}`}
      />
    </Box>
  );
};

export default SeatMap;

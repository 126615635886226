import Env from '../env';
import Logger from '../utils/logger';

const INTERNAL_ERROR_MAX_RETRIES = 2;
const INTERNAL_ERROR_RETRY_BACKOFF_MILLIS = 450;
const DEFAULT_HEADERS = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
};

const LOGGER = Logger.getInstance();

export class RequestError extends Error {
  public statusCode: number;

  constructor(statusCode: number, errData: {}) {
    super(JSON.stringify(errData));
    this.statusCode = statusCode;
  }
}

export default class RequestUtils {
  public static async invokeRemoteApi(req: any, headers: any): Promise<any> {
    let internalErrRetries = 0;
    for (;;) {
      try {
        return await RequestUtils.invokeRemoteApiOnce(req, headers);
      } catch (e) {
        const statusCode = 'n/a';
        if (e instanceof RequestError) {
          if (internalErrRetries < INTERNAL_ERROR_MAX_RETRIES && e.statusCode && e.statusCode >= 500 && e.statusCode < 600) {
            LOGGER.info('Encountered error (' + e.statusCode + ') with remote API. Retrying...:', e.message);
            internalErrRetries += 1;
            await new Promise((resolve) => setTimeout(resolve, INTERNAL_ERROR_RETRY_BACKOFF_MILLIS));
            continue;
          }
          LOGGER.error('Remote API encountered an error (code: ' + statusCode + '): ',
            'name: ' + e.name + ' msg: ' + e.message + ': ' + JSON.stringify(e));
        } else {
          LOGGER.error('Remote API encountered an unknown error: ' + JSON.stringify(e));
        }
        throw e;
      }
    }
  }

  private static async invokeRemoteApiOnce(req: any, headers: Record<string, string>): Promise<any> {
    const serializedReq = JSON.stringify(req);
    const invokeTs = Date.now();
    LOGGER.info('Invoking remote API (' + (headers.sessiontoken || 'null') + ') with',
      serializedReq.length > 1000 ? serializedReq.substring(0, 1000) + '...[truncated]' : serializedReq);

    const effectiveHeader = {
      ...DEFAULT_HEADERS,
      ...headers,
      clienttype: 'web',
      platformtype: 'web',
      clientversion: Env.getVersion(),
    };
    const resp = await fetch(Env.getApiEndpoint(), {
      method: 'POST',
      headers: effectiveHeader,
      body: serializedReq,
    });

    const data = await resp.json();
    if (resp.status !== 200) {
      LOGGER.error('Remote API failed with ' + resp.status, JSON.stringify(data));
      throw new RequestError(resp.status, data);
    }

    let dataJson = JSON.stringify(data.content);
    if (dataJson.length > 1000) {
      dataJson = dataJson.substring(0, 1000) + '...[truncated]';
    }
    LOGGER.info('API returned (' + (Date.now() - invokeTs) + 'ms):', dataJson);
    return data.content;
  }
}

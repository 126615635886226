import { UserRole } from '../models/user';
import AsyncStorage from '../system/storage';
import Logger from '../utils/logger';

const SESSION_TOKEN_KEY = '__SYSTEM_SERVICE__SESSION_TOKEN';

// The session must have at least this amount of ttl to be considered valid.
const SESSION_VALIDITY_THRESHOLD_MILLIS = 5 * 60 * 1000; // 5m
// The session must have at least this amount of ttl before we auto refresh it
const AUTO_REFRESH_SESSION_VALIDITY_THRESHOLD_MILLIS = 7 * 60 * 1000; // 7m

const LOGGER = Logger.getInstance();

export type DetailedSessionToken = {
  token: string;
  expirationTs: number;
  userRole: UserRole;
};

function isSessionTokenValid(sessionToken: DetailedSessionToken, tokenValidityMillis: number): boolean {
  // Ensure the token still has the min amount of of ttl remaining
  if (sessionToken.expirationTs - Date.now() < tokenValidityMillis) {
    return false;
  }

  return true;
}

const createSessionAccessor = () => {
  let sessionToken: DetailedSessionToken | null = null;
  let standaloneSessionToken: string = '';

  // Side-effect: if token is not in memory but in cache, will store token into memory
  const loadSessionTokenLocal = async (): Promise<DetailedSessionToken | null> => {
    const tokenValidityMillis = AUTO_REFRESH_SESSION_VALIDITY_THRESHOLD_MILLIS;
    if (sessionToken && isSessionTokenValid(sessionToken, tokenValidityMillis)) {
      return sessionToken;
    }

    const storedSessionTokenRaw = AsyncStorage.getItem(SESSION_TOKEN_KEY);
    if (storedSessionTokenRaw === null) {
      // not in local storage
      return null;
    }

    let storedSessionToken: DetailedSessionToken;
    try {
      storedSessionToken = JSON.parse(storedSessionTokenRaw);
    } catch (e) {
      // Not parsable
      return null;
    }
    if (!isSessionTokenValid(storedSessionToken, tokenValidityMillis)) {
      AsyncStorage.removeItem(SESSION_TOKEN_KEY);
      return null;
    }

    // We can use this token
    sessionToken = storedSessionToken;
    return sessionToken;
  };

  const getSessionToken = async (): Promise<string> => {
    if (standaloneSessionToken) {
      return standaloneSessionToken;
    }

    let resolvePromise: (sessionToken: string) => void;
    const tokenPromise = new Promise<string>((resolve) => {
      resolvePromise = resolve;
    });
    const startTs = Date.now();

    const waitForPromise = () => {
      if (sessionToken && isSessionTokenValid(sessionToken, SESSION_VALIDITY_THRESHOLD_MILLIS)) {
        resolvePromise(sessionToken.token);
      } else if (Date.now() - startTs > 30000) {
        LOGGER.error('Failed to get sessionToken in 30s');
        resolvePromise('');
      } else {
        setTimeout(() => {
          waitForPromise();
        }, 50);
      }
    };
    waitForPromise();

    return tokenPromise;
  };

  const setSessionToken = async (sessionToken_: DetailedSessionToken) => {
    sessionToken = sessionToken_;
    AsyncStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(sessionToken_));
  };

  const getStandaloneSessionToken = () => {
    return standaloneSessionToken;
  };

  const setStandaloneSessionToken = (sessionToken_: string) => {
    standaloneSessionToken = sessionToken_;
  };

  const invalidateSessionToken = async () => {
    sessionToken = null;
    AsyncStorage.removeItem(SESSION_TOKEN_KEY);
  };

  const getUserRole = async (): Promise<UserRole> => {
    if (!sessionToken) {
      return UserRole.REGULAR;
    }
    return sessionToken.userRole;
  };

  return {
    loadSessionTokenLocal,
    getSessionToken,
    setSessionToken,
    getStandaloneSessionToken,
    setStandaloneSessionToken,
    invalidateSessionToken,
    getUserRole,
  };
};

export default createSessionAccessor();

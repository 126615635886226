import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Stack } from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';

import { Colors } from 'common/src/constants';
import useNotifier from 'common/src/hooks/useNotifier';
import { SurveyResponse } from 'common/src/models/event';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { completeTicketOrder } from '../../../redux/slices/event';

interface IProps {
  ticketOrderId: string;
  selectedSurveyResponses: SurveyResponse[];
}
const CheckoutForm: React.FC<IProps> = ({ ticketOrderId, selectedSurveyResponses }) => {
  const stripe = useStripe();
  const elements = useElements();
  const notifier = useNotifier();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        throw new Error('BUG: Failed to retrieve payment intent');
      }

      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);


  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message || '');
      } else {
        setMessage('An unexpected error occurred.');
      }
    } else if (setupIntent && setupIntent.status === 'succeeded') {
      // Complete ticket order
      notifier.showSpinner({ type: 'loading' });
      await dispatch(completeTicketOrder({
        ticketOrderId: ticketOrderId,
        surveyResponses: selectedSurveyResponses,
      }));

      notifier.hideSpinner();
      navigate(`/t/${ticketOrderId}`);
    } else {
      setMessage('Payment failed');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  } as StripePaymentElementOptions;


  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <PaymentElement id='payment-element' options={paymentElementOptions} />
      <Button
        variant='contained'
        disabled={isLoading || !stripe || !elements}
        type='submit'
        sx={{ mt: 10 }}
      >
        <Stack direction='row' alignItems='center'>
          {isLoading && <CircularProgress size={15} sx={{ color: Colors.TEXT_SYSTEM_LIGHT, mr: 5 }} />}
          Pay now
        </Stack>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id='payment-message'>{message}</div>}
    </form>
  );
};

export default CheckoutForm;

import { createTheme } from '@mui/material/styles';

import { Colors } from './styles';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: false;
  }
}

const defaultTheme = createTheme({
  spacing: 1,
  palette: {
    primary: {
      main: Colors.PRIMARY,
      light: Colors.PRIMARY_LIGHT,
    },
    secondary: {
      main: Colors.ACTION,
    },
    background: {
      default: Colors.DEFAULT_BACKGROUND,
      paper: Colors.PRIMARY_BACKGROUND,
    },
    error: {
      main: Colors.ERROR,
    },
  },
  breakpoints: {
    values: {
      sm: 0,
      md: 640,
      lg: 1024,
    },
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          '&[role="menu"]': {
            backgroundColor: Colors.DEFAULT_BACKGROUND,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
});
// primary.main
// secondary.main
// error.main
// warning.main
// info.main
// success.main
// text.primary
// text.secondary
// text.disabled

export default defaultTheme;

import { configureStore } from '@reduxjs/toolkit';

import cartSlice from './slices/cart';
import eventSlice from './slices/event';
import initSlice from './slices/init';
import settingSlice from './slices/setting';
import userSlice from './slices/user';

const PERSISTED_STATE_KEY = '__STORE__PERSISTED_STATE';

function saveStateToLocalStorage(state: RootState) {
  try {
    const serializedState = JSON.stringify({
      setting: state.setting,
      cart: state.cart,
    });
    localStorage.setItem(PERSISTED_STATE_KEY, serializedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadStateFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(PERSISTED_STATE_KEY);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

const store = configureStore({
  reducer: {
    cart: cartSlice,
    event: eventSlice,
    init: initSlice,
    user: userSlice,
    setting: settingSlice,
  },
  // Use loadFromLocalStorage to overwrite any values that we already have saved
  preloadedState: loadStateFromLocalStorage(),
});

// listen for store changes and use saveToLocalStorage to save them to localStorage
store.subscribe(() => saveStateToLocalStorage(store.getState()));

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

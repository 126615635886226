export interface RegistrationData {
  phoneToken: string;
  email: string;
}

export enum RegistrationStatus {
  OK = 'ok',
  INVALID = 'invalid',
}

export enum GetPersistentTokenErrCode {
  UNASSOCIATED_PRINCIPAL = 'unassociated_principal'
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box, Button, Card, IconButton, InputAdornment, Link, Modal, Stack, TextField,
} from '@mui/material';

import { Colors } from 'common/src/constants';
import { getPhoneTokenRemote, verifyPhoneRemote } from 'common/src/system/network/identity';
import { DefaultStyleAttrs } from '../../../constants/styles';

import { Loading, Text } from 'common/src/components/base';

interface IProps {
  ready: boolean;
  editable: boolean;
  phone: string;
  phoneToken: string;
  email: string;
  onPhoneChange: (phone: string) => void;
  onPhoneTokenChange: (phoneToken: string) => void;
  onEmailChange: (email: string) => void;
}
const ContactInfo: React.FC<IProps> = ({
  ready, editable, phone, phoneToken, email, onPhoneChange, onPhoneTokenChange, onEmailChange,
}) => {
  const { t } = useTranslation('wbevt.events');

  const navigate = useNavigate();

  const [phoneFieldFocused, setPhoneFieldFocused] = React.useState(false);
  const [showSmsModal, setShowSmsModal] = React.useState(false);
  const [smsErrMsg, setSmsErrMsg] = React.useState('');

  const smsModal = showSmsModal && (
    <Modal open>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Card>
          <Box
            pt={10} bgcolor={Colors.DEFAULT_BACKGROUND}
            display='flex' flexDirection='column'
            alignItems='center'
          >
            <Stack direction='row'
              justifyContent='space-between' alignItems='center' width='100%'
              px={20} pb={20}
            >
              <IconButton disabled sx={{ opacity: 0 }}>
                <HighlightOffIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 30 }} />
              </IconButton>
              <Text>{t('registration.enterPhoneToken')}</Text>
              <IconButton onClick={() => setShowSmsModal(false)}>
                <HighlightOffIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 30 }} />
              </IconButton>
            </Stack>
            <Box px={DefaultStyleAttrs.mx} pb={20}>
              <VerificationInput
                placeholder='' validChars='0-9'
                onComplete={async (smsCode) => {
                  const getPhoneTokenResp = await getPhoneTokenRemote(phone, smsCode);
                  if (getPhoneTokenResp.errCode === 'invalid_principal_or_secret') {
                    setSmsErrMsg(t('registration.invalidPhoneToken'));
                    return;
                  }

                  // Received correct phone token
                  setSmsErrMsg('');
                  onPhoneTokenChange(getPhoneTokenResp.token);
                  setShowSmsModal(false);
                }}
                autoFocus
                classNames={{
                  container: 'sms-code-container',
                  character: 'sms-code-character',
                  characterInactive: 'sms-code-character--inactive',
                  characterSelected: 'sms-code-character--selected',
                }}
              />
              <Text centered size='note' color='system' mt={10} mb={5}>Sent to +1 {formatPhone(phone)}</Text>
            </Box>

            {!!smsErrMsg && (
              <Box mt={-20} pb={20}>
                <Text size='paragraph' color='error'>{smsErrMsg}</Text>
              </Box>
            )}
          </Box>
        </Card>

      </Box>
    </Modal>
  );

  return (
    <Box pt={30}>
      <Text size='subtitle' variant='bold'>{t('registration.contact')}</Text>
      <Text size='paragraph'>
        {t('registration.contactPrompt')}
        {!editable && (
          <Link onClick={() => navigate('/profile')} underline='hover'>
            {t('registration.changeContact')}
          </Link>
        )}
      </Text>
      {
        !ready ? (
          <Stack direction='column' alignItems='center'>
            <Loading size={100} />
            <Text size='subtitle' color='system' mt={-20}>{t('registration.loadingContact')}</Text>
          </Stack>
        ) : (
          <Box>
            <TextField
              margin='normal'
              required
              fullWidth
              label={t('registration.phone')}
              autoComplete='phone'
              value={formatPhone(phone)}
              disabled={!editable}
              onFocus={() => setPhoneFieldFocused(true)}
              onBlur={() => setPhoneFieldFocused(false)}
              onChange={(event) => {
                const formattedNumber = normalizePhone(event.target.value);
                if (phone !== formattedNumber) {
                  onPhoneChange(formattedNumber);
                  onPhoneTokenChange('');
                }
              }}
              placeholder='000-000-0000'
              InputProps={{
                startAdornment: (phoneFieldFocused || phone) ? <InputAdornment position='start'>+1</InputAdornment> : null,
                endAdornment: !editable ? null : phoneToken ? (
                  <CheckCircleOutlineIcon fontSize='medium' sx={{ color: Colors.PRIMARY }} />
                ): (
                  <Button
                    variant='contained' sx={{ borderRadius: DefaultStyleAttrs.borderRadius, minWidth: 125 }}
                    onClick={async () => {
                      verifyPhoneRemote(phone);
                      setShowSmsModal(true);
                    }}
                    disabled={phone.length !== 10}
                  >
                    {t('registration.sendPhoneToken')}
                  </Button>
                ),
              }}
            />

            <TextField
              margin='normal'
              required
              fullWidth
              label={t('registration.email')}
              autoComplete='email'
              value={email}
              disabled={!editable}
              onChange={(event) => {
                const newEmail = event.target.value;
                onEmailChange(newEmail);
              }}
              placeholder='taro@gmail.com'
            />
          </Box>
        )
      }

      {smsModal}
    </Box>
  );
};


const normalizePhone = (phone: string) => {
  // Remove non-numerics and ensure size<=10
  if (phone.startsWith('+1')) {
    phone = phone.substring(2);
  }
  return phone.replace(/\D/g, '').substring(0, 10);
};
const formatPhone = (phone: string) => {
  const normalizedPhone = normalizePhone(phone);
  let formattedPhone = '';

  if (normalizedPhone.length > 0) {
    formattedPhone += normalizedPhone.substring(0, 3);
  }
  if (normalizedPhone.length > 3) {
    formattedPhone += '-' + normalizedPhone.substring(3, 6);
  }
  if (normalizedPhone.length > 6) {
    formattedPhone += '-' + normalizedPhone.substring(6, 10);
  }

  return formattedPhone;
};

export default ContactInfo;

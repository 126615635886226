import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectedTicketOption, SurveyResponse } from 'common/src/models/event';

import { RootState } from '../store';


// ### State ###

export type Cart = {
  selectedEventTemplateId: string;
  selectedTicketOptions: SelectedTicketOption[];
  selectedSurveyResponses: SurveyResponse[];
}

interface CartState {
  cart: Cart;
  seatMapHoldToken: string;
  seatMapHoldTokenExpireTs: number;
}

const initialState: CartState = {
  cart: {
    selectedEventTemplateId: '',
    selectedTicketOptions: [],
    selectedSurveyResponses: [],
  },
  seatMapHoldToken: '',
  seatMapHoldTokenExpireTs: 0,
};


// ### Slice ###

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action: PayloadAction<Cart>) => {
      state.cart = action.payload;
    },
    setSeatMapHoldToken: (state, action: PayloadAction<string>) => {
      state.seatMapHoldToken = action.payload;
    },
    setSeatMapHoldTokenExpireTs: (state, action: PayloadAction<number>) => {
      state.seatMapHoldTokenExpireTs = action.payload;
    },
    clearCart: (state) => {
      state.cart = {
        selectedEventTemplateId: '',
        selectedTicketOptions: [],
        selectedSurveyResponses: [],
      };
    },
  },
});


// ### Selectors ### //

export const selectCart = (state: RootState): Cart => {
  return state.cart.cart;
};
export const selectSeatMapHoldToken = (state: RootState): string => {
  return state.cart.seatMapHoldToken;
};
export const selectSeatMapHoldTokenExpireTs = (state: RootState): number => {
  return state.cart.seatMapHoldTokenExpireTs;
};


// ### Exports ### //

export const { setCart, setSeatMapHoldToken, setSeatMapHoldTokenExpireTs, clearCart } = cartSlice.actions;
export default cartSlice.reducer;

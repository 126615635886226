import { Box, CardMedia } from '@mui/material';

import { Text } from 'common/src/components/base';


interface IProps {
    taroId: string
}

const TaroPassCard: React.FC<IProps> = ({ taroId }) => {
  const IMG_WIDTH = Math.min(window.innerWidth * 0.95, 400);
  const IMG_HEIGHT = IMG_WIDTH * 438 / 674;

  return (
    <Box mt={10} justifyItems='center' alignItems='center' justifyContent='center' display='flex'>
      <Box position='relative'
        height={IMG_HEIGHT} width={IMG_WIDTH}>
        <CardMedia
          component='img'
          image={require('../assets/images/taroPass.png')}
          alt='taroPass'
          sx={{ height: IMG_HEIGHT, width: IMG_WIDTH }}
        />
        <Box>
          <Text
            color='primary'
            size='subtitle'
            position='absolute'
            variant='bold'
            left={IMG_WIDTH * 0.14}
            top={IMG_HEIGHT * 0.10}
          >
                Taro ID: {taroId}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default TaroPassCard;

// React native re-render based on reference, this means an element will be re-rendered if the
// reference changes even if the content is the same. This function will retain the reference of
// the element if the content is the same.
export function retainRef(originalArr: any[], newArr: any[]): any[] {
  const map: Record<string, any> = {};
  for (let i = 0; i < originalArr.length; i++) {
    map[JSON.stringify(originalArr[i])] = originalArr[i];
  }

  let hasDiff = false;
  const res: any[] = [];
  for (let i = 0; i < newArr.length; i++) {
    const originalContent = map[JSON.stringify(newArr[i])];
    if (originalContent !== undefined) {
      res.push(originalContent);
    } else {
      res.push(newArr[i]);
      hasDiff = true;
    }
  }

  if (!hasDiff && newArr.length === originalArr.length) {
    return originalArr;
  } else {
    return newArr;
  }
}

import * as React from 'react';

import Env from '../env';
import { GetPersistentTokenErrCode, RegistrationData, RegistrationStatus } from '../models/registration';
import { UserRole } from '../models/user';
import { getPersistentTokenRemote, getSessionRemote, registerUserRemote } from '../system/network/identity';
import SessionAccessor from '../system/session';
import AsyncStorage from '../system/storage';
import Logger from '../utils/logger';

const PERSISTENT_TOKEN_KEY = '__SYSTEM_SERVICE__PERSISTENT_TOKEN';

const LOGGER = Logger.getInstance();

export enum LoginResult {
  SUCCESS,
  NOT_REGISTERED,
}

// Auth transitions through 3 states:
// - init is a transient state, to figure out if we're logged in or not.
// - loggedIn means there is a persistentToken (and we will auto refresh sessions)
// - loggedOut means there isn't a persistentToken
export type AuthStatus = 'init' | 'loggedIn' | 'loggedOut';

interface IAuthContext {
  authStatus: AuthStatus;
  userRole: UserRole;
  login: (phoneToken: string) => Promise<LoginResult>;
  logout: () => Promise<void>;
  registerUser: (registrationData: RegistrationData) => Promise<RegistrationStatus>;
}

const AuthContext = React.createContext<IAuthContext>({
  authStatus: 'init',
  userRole: UserRole.REGULAR,
  login: async () => LoginResult.NOT_REGISTERED,
  logout: async () => { },
  registerUser: async () => RegistrationStatus.INVALID,
});

export { AuthContext };

interface IProps {
  children: React.ReactNode;
}
const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [authStatus, setAuthStatus] = React.useState<AuthStatus>('init');
  const [userRole, setUserRole] = React.useState(UserRole.REGULAR);
  const refreshLock = React.useRef<boolean>(false);

  // Returns whether we have a valid session token
  const refreshSessionToken = React.useCallback(async (persistentToken: string): Promise<boolean> => {
    // This is a very primitive lock, but it's okay since we only call refreshSessionToken in a loop
    // few seconds apart.
    if (refreshLock.current) {
      return false;
    }
    refreshLock.current = true;

    try {
      // Check if we still have a valid session token
      const sessionToken = await SessionAccessor.loadSessionTokenLocal();
      if (sessionToken) {
        // No need to refresh, since the current sessionToken is still good
        return true;
      }

      // Get a new session token
      const resp = await getSessionRemote(persistentToken, Env.getDeviceId());
      if (!resp.userRegistered) {
        throw new Error('User not registered with persistentToken: ' + persistentToken);
      }

      await SessionAccessor.setSessionToken({
        expirationTs: resp.ttlTs,
        token: resp.sessionToken,
        userRole: resp.userRole,
      });
      return true;
    } catch (e) {
      LOGGER.error('Encountered error in refreshSessionToken', e);
      return false;
    } finally {
      refreshLock.current = false;
    }
  }, []);

  const syncStatus = React.useCallback(async (): Promise<void> => {
    const persistentToken = AsyncStorage.getItem(PERSISTENT_TOKEN_KEY);
    if (persistentToken) {
      const hasSessionToken = await refreshSessionToken(persistentToken);
      if (hasSessionToken) {
        setUserRole(await SessionAccessor.getUserRole());
        setAuthStatus('loggedIn');
      }
    } else {
      setUserRole(UserRole.REGULAR);
      setAuthStatus('loggedOut');
      await SessionAccessor.invalidateSessionToken();
    }
  }, [refreshSessionToken]);

  React.useEffect(() => {
    const intervalId = setInterval(() => syncStatus(), 1000);

    return () => clearInterval(intervalId);
  }, [syncStatus]);

  // login acquires the persistentToken. refreshSessionToken will automatically get the sessionToken.
  const login = React.useCallback(async (phoneToken: string): Promise<LoginResult> => {
    const resp = await getPersistentTokenRemote(phoneToken);

    if (resp.errCode) {
      if (resp.errCode === GetPersistentTokenErrCode.UNASSOCIATED_PRINCIPAL) {
        return LoginResult.NOT_REGISTERED;
      }
      throw new Error('Unable to login: ' + resp.errCode);
    }
    AsyncStorage.setItem(PERSISTENT_TOKEN_KEY, resp.persistentToken);

    LOGGER.info('logging in...');
    await syncStatus();
    return LoginResult.SUCCESS;
  }, [syncStatus]);

  const logout = React.useCallback(async () => {
    AsyncStorage.removeItem(PERSISTENT_TOKEN_KEY);

    LOGGER.info('logging out...');
    await syncStatus();
    window.location.reload();
  }, [syncStatus]);


  // Side-effect: Will store the retrieved tokens
  const registerUser = React.useCallback(async (registrationData: RegistrationData): Promise<RegistrationStatus> => {
    const registrationResp = await registerUserRemote(registrationData, Env.getDeviceId());

    if (registrationResp.status !== RegistrationStatus.OK) {
      return registrationResp.status;
    }

    await AsyncStorage.setItem(PERSISTENT_TOKEN_KEY, registrationResp.persistentToken);
    await SessionAccessor.setSessionToken({
      expirationTs: registrationResp.ttlTs,
      token: registrationResp.sessionToken,
      userRole: registrationResp.userRole,
    });

    await syncStatus();
    return RegistrationStatus.OK;
  }, [syncStatus]);

  return (
    <AuthContext.Provider value={{ authStatus, userRole, login, logout, registerUser }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;

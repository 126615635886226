import * as React from 'react';
import { SxProps, Theme, Typography, TypographyTypeMap } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';

import { Colors, ComponentStyles } from '../../constants';

export type IProps = Omit<TypographyTypeMap['props'], 'variant'> & {
  children: React.ReactNode;
  size?: ResponsiveStyleValue<'subnote' | 'note' | 'paragraph' | 'subtitle' | 'title' | 'banner'>;
  color?: 'default' | 'primary' | 'primary-light' | 'action' | 'action-accent' | 'action-dark' |
  'system' | 'system-light' | 'error' | 'white' | string;
  variant?: 'bold' | 'italics' | 'underline';
  maxLines?: number;
  centered?: boolean;
  sx?: SxProps<Theme>;
}

const Text: React.FC<IProps> = ({ children, size, color, variant, maxLines, centered, sx, ...otherProps }) => {
  const effectiveStyle: TypographyTypeMap['props'] = {};
  if (color) {
    switch (color) {
      case 'primary':
        effectiveStyle.color = Colors.PRIMARY;
        break;
      case 'primary-light':
        effectiveStyle.color = Colors.PRIMARY_LIGHT;
        break;
      case 'action':
        effectiveStyle.color = Colors.ACTION;
        break;
      case 'action-accent':
        effectiveStyle.color = Colors.ACTION_ACCENT;
        break;
      case 'action-dark':
        effectiveStyle.color = Colors.ACTION_DARK;
        break;
      case 'system':
        effectiveStyle.color = Colors.TEXT_SYSTEM;
        break;
      case 'system-light':
        effectiveStyle.color = Colors.TEXT_SYSTEM_LIGHT;
        break;
      case 'error':
        effectiveStyle.color = Colors.ERROR;
        break;
      case 'white':
        effectiveStyle.color = Colors.WHITE;
        break;
      default:
        effectiveStyle.color = color;
        break;
    }
  }

  let fontSize: ResponsiveStyleValue<number> = {};
  if (size) {
    if (typeof size === 'object' && !Array.isArray(size)) {
      const fontSizeRecord: Record<string, number> = {};
      Object.entries(size).forEach(([breakpoint, value]) => {
        if (value) {
          fontSizeRecord[breakpoint] = ComponentStyles.text[value].fontSize;
        }
      });
      fontSize = fontSizeRecord;
    } else if (Array.isArray(size)) {
      const fontSizeArr = size.map((value) => {
        if (value) {
          return ComponentStyles.text[value].fontSize;
        } else {
          return value;
        }
      });
      fontSize = fontSizeArr;
    } else {
      fontSize = ComponentStyles.text[size].fontSize;
    }
  }

  let effectiveSx = sx;
  switch (variant) {
    case 'bold':
      effectiveStyle.fontWeight = 'bold';
      break;
    case 'italics':
      effectiveStyle.fontStyle = 'italic';
      break;
    case 'underline':
      effectiveSx = {
        ...effectiveSx,
        textDecorationLine: 'underline',
      };
      break;
  }

  if (maxLines) {
    effectiveSx = {
      ...effectiveSx,
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: maxLines,
    };
  }

  if (centered) {
    effectiveStyle.textAlign = 'center';
  }

  return <Typography whiteSpace = 'pre-line' fontSize={fontSize} {...effectiveStyle} sx={effectiveSx} {...otherProps}>{children}</Typography>;
};

export default Text;

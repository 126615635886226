import * as React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';

import { EventTemplate, TicketOrder, TicketOrderStatus } from 'common/src/models/event';

import { Text } from 'common/src/components/base';
import CustomCancellationPolicy from './CustomCancellationPolicy';
import GeneralCancellationPolicy from './GeneralCancellationPolicy';

interface IProps {
  eventTemplate: EventTemplate;
  ticketOrder?: TicketOrder;
  variant?: 'block' | 'inline';
}
const CancellationPolicy: React.FC<IProps> = ({ eventTemplate, ticketOrder, variant }) => {
  const { t } = useTranslation('wbevt.events');

  const eventPayload = eventTemplate.payload.hostedEventPayload;
  const pastCancellationLimit = eventTemplate.eventFromTs - eventPayload.cancellationHoursBeforeEvent * 60 * 60 * 1000 < Date.now();

  let policyView;
  if (eventPayload.cancellationHoursAfterEventConfirmation === 0) {
    policyView = (
      <Text size='paragraph'>{t('cancellation.noCancellationPolicyContent')}</Text>
    );
  } else if (!ticketOrder || ticketOrder.confirmationTs <= 0) {
    if (pastCancellationLimit) {
      policyView = (
        <Stack direction='row' alignItems='center'>
          <Text color='system' size='paragraph'>{t('cancellation.noCancellationPolicyContent')}</Text>
          <Tooltip title={t('cancellation.pastCancellationLimitTooltip')}>
            <IconButton size='small' sx={{ p: 0, ml: 3 }}>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Stack>
      );
    } else {
      policyView = <GeneralCancellationPolicy eventTemplate={eventTemplate} />;
    }
  } else {
    switch (ticketOrder.status) {
      case TicketOrderStatus.CANCELLED:
        return null;
      case TicketOrderStatus.CONFIRMED:
        policyView = <CustomCancellationPolicy eventTemplate={eventTemplate} ticketOrderConfirmationTs={ticketOrder.confirmationTs} />;
        break;
      case TicketOrderStatus.PENDING:
      case TicketOrderStatus.CONFIRMING:
      case TicketOrderStatus.ERROR:
      case TicketOrderStatus.INIT:
      case TicketOrderStatus.WAITLIST:
        policyView = <GeneralCancellationPolicy eventTemplate={eventTemplate} />;
        break;
    }
  }

  switch (variant) {
    case 'inline':
      return (
        <Box display='flex' flexWrap='wrap'>
          <Text size='paragraph' variant='bold' mr={5}>{t('cancellation.policyTitle')}:</Text>
          {policyView}
        </Box>
      );
    case 'block':
    default:
      return (
        <Box bgcolor='#E8E7EA' my={30} p={20}>
          <Text size='subtitle' variant='bold' mb={5}>{t('cancellation.policyTitle')}</Text>
          {policyView}
        </Box>
      );
  }
};

export default CancellationPolicy;

import { Constants, Endpoints } from './constants';

export enum Stack {
  PROD, STAGING
}

class EnvWrapper {
  private readonly prod = true; // This overrides all test parameters below

  // Test parameters
  private readonly stack = Stack.STAGING; // NOTE: ensure this is "STAGING" before checkin
  private readonly showWIPFeatures = true; // NOTE: ensure this is "true" before checkin

  private showWIPFeaturesOverride = false;

  private readonly deviceId = Math.random().toString(36).substring(7);
  private readonly version = '0.0.1'; // This is set automatically on publish. Do not modify.

  public isProd(): boolean {
    return this.prod;
  }

  public getStack(): Stack {
    return (!this.prod && this.stack === Stack.STAGING) ? Stack.STAGING : Stack.PROD;
  }

  public shouldShowWIPFeatures(ignoreOverride?: boolean): boolean {
    if (!ignoreOverride && this.showWIPFeaturesOverride) {
      return true;
    }
    return !this.prod && this.showWIPFeatures;
  }

  getApiEndpoint(): string {
    if (this.getStack() === Stack.STAGING) {
      return Endpoints.STAGING_API_ENDPOINT;
    } else {
      return Endpoints.PROD_API_ENDPOINT;
    }
  }

  getStripePublishableKey(): string {
    if (this.getStack() === Stack.STAGING) {
      return Constants.STRIPE_DEV_PUBLISHABLE_KEY;
    } else {
      return Constants.STRIPE_PROD_PUBLISHABLE_KEY;
    }
  }

  getSeatIoWorkspaceKey(): string {
    if (this.getStack() === Stack.STAGING) {
      return Constants.SEATIO_STAGING_WORKSPACE_KEY;
    } else {
      return Constants.SEATIO_PROD_WORKSPACE_KEY;
    }
  }

  getDeviceId(): string {
    return this.deviceId;
  }

  getVersion(): string {
    return this.version;
  }
}

export default new EnvWrapper();

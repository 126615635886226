import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationCommonEN from '../assets/locales/en/wbevt/common.json';
import translationEventsEN from '../assets/locales/en/wbevt/events.json';
import translationHomeEN from '../assets/locales/en/wbevt/home.json';
import translationMiscEN from '../assets/locales/en/wbevt/misc.json';
import translationProfileEN from '../assets/locales/en/wbevt/profile.json';
import translationCommonZH from '../assets/locales/zh/wbevt/common.json';
import translationEventsZH from '../assets/locales/zh/wbevt/events.json';
import translationHomeZH from '../assets/locales/zh/wbevt/home.json';
import translationMiscZH from '../assets/locales/zh/wbevt/misc.json';
import translationProfileZH from '../assets/locales/zh/wbevt/profile.json';

// Creating object with the variables of imported translation files
const resources = {
  en: {
    'wbevt.home': translationHomeEN,
    'wbevt.events': translationEventsEN,
    'wbevt.common': translationCommonEN,
    'wbevt.profile': translationProfileEN,
    'wbevt.misc': translationMiscEN,
  },
  zh: {
    'wbevt.home': translationHomeZH,
    'wbevt.events': translationEventsZH,
    'wbevt.common': translationCommonZH,
    'wbevt.profile': translationProfileZH,
    'wbevt.misc': translationMiscZH,
  },
};

// i18N Initialization

const options = {
  // see https://github.com/i18next/i18next-browser-languageDetector#adding-own-detection-functionality
  order: ['localStorage', 'querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: en, // remove this for language detector to work
    detection: options,
    fallbackLng: 'zh',
    supportedLngs: ['en', 'zh'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EventContextFilter, EventTemplate, EventTemplateGroup, EventTicketInventory, PaymentSetupIntent,
  SelectedTicketOption, SurveyResponse, TaroPassRecord, TicketOrder, Vendor,
} from 'common/src/models/event';
import {
  cancelTicketsRemote, completeTicketOrderRemote, getEventTemplateGroupsRemote,
  getEventTemplateRemote, getMyTicketOrdersRemote, holdSeatsRemote, initiateTicketOrderRemote,
  listEventTemplatesRemote, listTaroPassesRemote, listVendorsRemote, queryEventTicketInventoryRemote,
} from 'common/src/system/network/event';
import { retainRef } from 'common/src/utils/comparison';

import { AppDispatch, RootState } from '../store';
import { clearCart, setSeatMapHoldToken, setSeatMapHoldTokenExpireTs } from './cart';


// ### State ###

interface EventState {
  eventTemplates: Record<string, EventTemplate>;
  eventTemplateLoadStatus: 'partial' | 'current' | 'full';

  eventTemplateGroups: EventTemplateGroup[] | null;
  taroPasses: TaroPassRecord[] | null;
  vendors: Vendor[] | null;

  ticketOrderById: Record<string, TicketOrder> | null;
  ticketOrdersByEventTemplateId: Record<string, TicketOrder[]> | null;

  eventTicketInventoryByEventTemplateId: Record<string, EventTicketInventory>;
  isEventTicketInventoryFullyLoaded: boolean;

  utm: string;
}

const initialState: EventState = {
  eventTemplates: {},
  eventTemplateLoadStatus: 'partial',

  eventTemplateGroups: null,
  taroPasses: null,
  vendors: null,

  ticketOrderById: null,
  ticketOrdersByEventTemplateId: null,

  eventTicketInventoryByEventTemplateId: {},
  isEventTicketInventoryFullyLoaded: false,

  utm: '',
};


// ### Actions ### //

export const refreshEventTemplate = createAsyncThunk<
  void,
  { eventTemplateId: string, onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplate', async ({ eventTemplateId, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  if (onlyIfUncached) {
    if (state.event.eventTemplates[eventTemplateId]) {
      return;
    }
  }

  const eventTemplate = await getEventTemplateRemote(eventTemplateId);
  dispatch(setEventTemplates({
    eventTemplates: [eventTemplate],
    eventTemplateLoadStatus: 'partial',
  }));
});

export const refreshSelectEventTemplates = createAsyncThunk<
  void,
  { eventTemplateIds: string[], onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshSelectEventTemplates', async ({ eventTemplateIds, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  const eventTemplateIdsRequireRefreshing = [];
  if (onlyIfUncached) {
    for (const eventTemplateId of eventTemplateIds) {
      if (!state.event.eventTemplates[eventTemplateId]) {
        eventTemplateIdsRequireRefreshing.push(eventTemplateId);
      }
    }
  }

  if (eventTemplateIdsRequireRefreshing.length === 0) {
    return;
  }

  const eventTemplates = await Promise.all(eventTemplateIdsRequireRefreshing.map((eventTemplateId) =>
    getEventTemplateRemote(eventTemplateId),
  ));
  dispatch(setEventTemplates({
    eventTemplates: eventTemplates,
    eventTemplateLoadStatus: 'partial',
  }));
});

export const refreshEventTemplates = createAsyncThunk<
  void,
  { includePastEvents: boolean, onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplates', async ({ includePastEvents, onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();

  if (onlyIfUncached) {
    if (includePastEvents) {
      if (state.event.eventTemplateLoadStatus === 'full') {
        return;
      }
    } else {
      if (state.event.eventTemplateLoadStatus !== 'partial') {
        return;
      }
    }
  }

  const eventTemplates = await listEventTemplatesRemote(includePastEvents, EventContextFilter.WEB_ONLY);
  dispatch(setEventTemplates({
    eventTemplates: eventTemplates,
    eventTemplateLoadStatus: includePastEvents ? 'full' : 'current',
  }));
});

export const refreshEventTemplateGroups = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTemplateGroups', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && !!state.event.eventTemplateGroups) {
    return;
  }

  const eventTemplateGroups = await getEventTemplateGroupsRemote();
  dispatch(setEventTemplateGroups(eventTemplateGroups));
});

export const refreshTaroPasses = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshTaroPasses', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && !!state.event.taroPasses) {
    return;
  }

  const taroPasses = await listTaroPassesRemote();
  dispatch(setTaroPasses(taroPasses));
});


export const refreshVendors = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshVendors', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && !!state.event.vendors) {
    return;
  }

  const vendors = await listVendorsRemote();
  dispatch(setVendors(vendors));
});

export const refreshTicketOrders = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshTicketOrders', async ({ onlyIfUncached }, { getState, dispatch }) => {
  const state = getState();
  if (onlyIfUncached && !!state.event.ticketOrderById) {
    return;
  }

  const ticketOrders = await getMyTicketOrdersRemote();
  dispatch(setTicketOrders(ticketOrders));
});

export const refreshEventTicketInventory = createAsyncThunk<
  void,
  { onlyIfUncached?: boolean, optEventTemplateId?: string },
  { state: RootState; dispatch: AppDispatch }
>('event/refreshEventTicketInventory', async ({ onlyIfUncached, optEventTemplateId }, { getState, dispatch }) => {
  const state = getState();

  if (onlyIfUncached) {
    if (optEventTemplateId) {
      if (state.event.eventTicketInventoryByEventTemplateId[optEventTemplateId]) {
        return;
      }
    } else {
      if (state.event.isEventTicketInventoryFullyLoaded) {
        return;
      }
    }
  }

  const eventTicketInventoryList = await queryEventTicketInventoryRemote(optEventTemplateId);
  dispatch(setEventTicketInventoryList({
    eventTicketInventoryList: eventTicketInventoryList,
    isFullReload: !optEventTemplateId,
  }));
});

export const initiateTicketOrder = createAsyncThunk<
  { ticketOrderId: string, paymentSetupIntent: PaymentSetupIntent },
  { eventTemplateId: string, selectedTicketOptions: SelectedTicketOption[], couponCodes: string[]},
  { state: RootState; dispatch: AppDispatch }
>('event/initiateTicketOrder', async ({ eventTemplateId, selectedTicketOptions, couponCodes }, { getState, dispatch }) => {
  const state = getState();
  const utm = state.event.utm;

  const resp = await initiateTicketOrderRemote(eventTemplateId, selectedTicketOptions, couponCodes, utm);

  const refreshTicketOrdersPromise = dispatch(refreshTicketOrders({
    onlyIfUncached: false,
  }));
  const refreshEventTicketInventoryPromise = dispatch(refreshEventTicketInventory({
    onlyIfUncached: false, optEventTemplateId: eventTemplateId,
  }));
  await Promise.all([refreshTicketOrdersPromise, refreshEventTicketInventoryPromise]);

  return resp;
});

export const holdSeats = createAsyncThunk<
  string,
  {eventTemplateId: string, seatIds: string[]},
  { dispatch: AppDispatch }
>('event/holdSeats', async ({ eventTemplateId, seatIds }, { dispatch }) => {
  const resp = await holdSeatsRemote(eventTemplateId, seatIds);
  // in order to avoid conflict, we extend 16 mins in backend, but show 15 mins here
  dispatch(setSeatMapHoldTokenExpireTs(resp.holdTokenExpireTs- 60 * 1000));
  dispatch(setSeatMapHoldToken(resp.holdToken));
  return resp.errCode;
});

export const completeTicketOrder = createAsyncThunk<
  void,
  { ticketOrderId: string, surveyResponses: SurveyResponse[] },
  { state: RootState; dispatch: AppDispatch }
>('event/completeTicketOrder', async ({ ticketOrderId, surveyResponses }, { getState, dispatch }) => {
  const state = getState();
  const holdToken = state.cart.seatMapHoldToken;
  const resultTicketOrder = await completeTicketOrderRemote(ticketOrderId, surveyResponses, holdToken);

  const refreshTicketOrdersPromise = dispatch(refreshTicketOrders({
    onlyIfUncached: false,
  }));
  const refreshEventTicketInventoryPromise = dispatch(refreshEventTicketInventory({
    onlyIfUncached: false, optEventTemplateId: resultTicketOrder.eventTemplateId,
  }));
  await Promise.all([refreshTicketOrdersPromise, refreshEventTicketInventoryPromise]);

  dispatch(clearCart());
});

export const cancelTickets = createAsyncThunk<
  void,
  { ticketOrderId: string, ticketIds: string[] },
  { dispatch: AppDispatch }
>('event/cancelTickets', async ({ ticketOrderId, ticketIds }, { dispatch }) => {
  const resultTicketOrder = await cancelTicketsRemote(ticketOrderId, ticketIds);

  const refreshTicketOrdersPromise = dispatch(refreshTicketOrders({
    onlyIfUncached: false,
  }));
  const refreshEventTicketInventoryPromise = dispatch(refreshEventTicketInventory({
    onlyIfUncached: false, optEventTemplateId: resultTicketOrder.eventTemplateId,
  }));
  await Promise.all([refreshTicketOrdersPromise, refreshEventTicketInventoryPromise]);
});


// ### Slice ###

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEventTemplates: (state, action: PayloadAction<{
      eventTemplates: EventTemplate[],
      eventTemplateLoadStatus: 'partial' | 'current' | 'full',
    }>) => {
      const originalEventTemplatesList: EventTemplate[] = Object.values(state.eventTemplates);
      const effectiveEventTemplatesList: EventTemplate[] =
        retainRef(originalEventTemplatesList, action.payload.eventTemplates);

      const eventTemplates: Record<string, EventTemplate> = {};
      // Retain old entries
      originalEventTemplatesList.forEach((eventTemplate) => {
        eventTemplates[eventTemplate.id] = eventTemplate;
      });
      // And overwrite with new (for incremental refreshes)
      effectiveEventTemplatesList.forEach((eventTemplate) => {
        eventTemplates[eventTemplate.id] = eventTemplate;
      });

      state.eventTemplates = eventTemplates;
      if (action.payload.eventTemplateLoadStatus === 'full') {
        state.eventTemplateLoadStatus = 'full';
      } else if (action.payload.eventTemplateLoadStatus === 'current') {
        if (state.eventTemplateLoadStatus !== 'full') {
          state.eventTemplateLoadStatus = 'current';
        }
      }
    },
    setEventTemplateGroups: (state, action: PayloadAction<EventTemplateGroup[]>) => {
      state.eventTemplateGroups = action.payload;
    },
    setTaroPasses: (state, action: PayloadAction<TaroPassRecord[]>) => {
      state.taroPasses = action.payload;
    },
    setVendors: (state, action: PayloadAction<Vendor[]>) => {
      state.vendors = action.payload;
    },
    setTicketOrders: (state, action: PayloadAction<TicketOrder[]>) => {
      const originalTicketOrders = state.ticketOrderById ? Object.values(state.ticketOrderById) : [];
      const effectiveTicketOrders = retainRef(originalTicketOrders, action.payload);

      const ticketOrderById: Record<string, TicketOrder> = {};
      effectiveTicketOrders.forEach((ticketOrder) => {
        ticketOrderById[ticketOrder.id] = ticketOrder;
      });

      const ticketOrdersByEventTemplateId: Record<string, TicketOrder[]> = {};
      effectiveTicketOrders.forEach((ticketOrder) => {
        if (!ticketOrdersByEventTemplateId[ticketOrder.eventTemplateId]) {
          ticketOrdersByEventTemplateId[ticketOrder.eventTemplateId] = [];
        }
        ticketOrdersByEventTemplateId[ticketOrder.eventTemplateId].push(ticketOrder);
      });

      // dedup arrays such that we reuse the old array if the content is the same. This is to avoid
      // unnecessary re-rendering of components.
      Object.keys(ticketOrdersByEventTemplateId).forEach((eventTemplateId) => {
        const newTicketOrders = ticketOrdersByEventTemplateId[eventTemplateId];
        const oldTicketOrders =
          (state.ticketOrdersByEventTemplateId &&
          state.ticketOrdersByEventTemplateId[eventTemplateId]) || [];

        const newArrJson = newTicketOrders.map((item) => JSON.stringify(item)).sort().join(',');
        const oldArrJson = oldTicketOrders.map((item) => JSON.stringify(item)).sort().join(',');
        const isEquivalent = newArrJson === oldArrJson;

        if (isEquivalent) {
          ticketOrdersByEventTemplateId[eventTemplateId] = oldTicketOrders;
        }
      });

      state.ticketOrderById = ticketOrderById;
      state.ticketOrdersByEventTemplateId = ticketOrdersByEventTemplateId;
    },
    setEventTicketInventoryList: (state, action: PayloadAction<{
      eventTicketInventoryList: EventTicketInventory[],
      isFullReload: boolean,
    }>) => {
      const originalEventTicketInventoryList = Object.values(state.eventTicketInventoryByEventTemplateId);
      const effectiveEventTicketInventoryList =
        retainRef(originalEventTicketInventoryList, action.payload.eventTicketInventoryList);

      const eventTicketInventoryByEventTemplateId: Record<string, EventTicketInventory> = {};
      // Retain old entries
      originalEventTicketInventoryList.forEach((eventTicketInventory) => {
        eventTicketInventoryByEventTemplateId[eventTicketInventory.eventTemplateId] = eventTicketInventory;
      });
      // And overwrite with new (for incremental refreshes)
      effectiveEventTicketInventoryList.forEach((eventTicketInventory) => {
        eventTicketInventoryByEventTemplateId[eventTicketInventory.eventTemplateId] = eventTicketInventory;
      });

      state.eventTicketInventoryByEventTemplateId = eventTicketInventoryByEventTemplateId;
      state.isEventTicketInventoryFullyLoaded = state.isEventTicketInventoryFullyLoaded || action.payload.isFullReload;
    },
    setUtm: (state, action: PayloadAction<string>) => {
      state.utm = action.payload.slice(0, 10);
    },
  },
});


// ### Selectors ### //

export const selectAllEventTemplates = (state: RootState): Record<string, EventTemplate> => {
  return state.event.eventTemplates;
};

export const selectOptEventTemplate = (
  state: RootState, eventTemplateId: string,
): EventTemplate | null => {
  return state.event.eventTemplates[eventTemplateId] || null;
};

export const selectEventTemplateLoadStatus = (state: RootState): 'partial' | 'current' | 'full' => {
  return state.event.eventTemplateLoadStatus;
};

export const selectAllEventTemplateGroups = (state: RootState): EventTemplateGroup[] | null => {
  return state.event.eventTemplateGroups;
};

export const selectOptEventTemplateGroup = (
  state: RootState, eventTemplateGroupId: string,
): EventTemplateGroup | null => {
  if (!state.event.eventTemplateGroups) {
    return null;
  }

  return state.event.eventTemplateGroups
    .find((eventTemplateGroup_) => eventTemplateGroup_.id === eventTemplateGroupId) || null;
};

export const selectAllTaroPasses = (state: RootState): TaroPassRecord[] | null => {
  // Make a copy to prevent change
  return state.event.taroPasses === null ?
    null :
    [...state.event.taroPasses.filter((taroPass) =>
      state.setting.stateFilter === null ||
    taroPass.location.state.toLowerCase() === state.setting.stateFilter)];
};

export const selectTaroPass = (
  state: RootState, taroPassId: string,
): TaroPassRecord | null => {
  if (!state.event.taroPasses) {
    return null;
  }

  return state.event.taroPasses
    .find((taroPass_) => taroPass_.id === taroPassId) || null;
};

export const selectAllVendors = (state: RootState): Vendor[] | null => {
  return state.event.vendors;
};

export const selectOptVendor = (
  state: RootState, vendorId: string,
): Vendor | null => {
  if (!state.event.vendors) {
    return null;
  }

  return state.event.vendors
    .find((vendor_) => vendor_.id === vendorId) || null;
};

export const selectAllTicketOrdersByTemplate = (
  state: RootState,
): Record<string, TicketOrder[]> | null => {
  return state.event.ticketOrdersByEventTemplateId;
};

export const selectTicketOrdersByTemplate = (
  state: RootState, eventTemplateId: string,
): TicketOrder[] | null => {
  if (!state.event.ticketOrdersByEventTemplateId) {
    return null;
  }

  return state.event.ticketOrdersByEventTemplateId[eventTemplateId] || null;
};

export const selectTicketOrders = (
  state: RootState,
): TicketOrder[] | null => {
  if (!state.event.ticketOrderById) {
    return null;
  }

  return Object.values(state.event.ticketOrderById);
};

export const selectOptTicketOrder = (
  state: RootState, ticketOrderId: string,
): TicketOrder | null => {
  if (!state.event.ticketOrderById) {
    return null;
  }

  return state.event.ticketOrderById[ticketOrderId] || null;
};

export const selectAllEventTicketInventoriesByTemplate = (
  state: RootState,
): Record<string, EventTicketInventory>=> {
  return state.event.eventTicketInventoryByEventTemplateId;
};

export const selectOptEventTicketInventoryByTemplate = (
  state: RootState, eventTemplateId: string,
): EventTicketInventory | null => {
  if (!state.event.eventTicketInventoryByEventTemplateId) {
    return null;
  }

  return state.event.eventTicketInventoryByEventTemplateId[eventTemplateId] || null;
};


// ### Exports ### //

export const {
  setEventTemplates, setEventTemplateGroups, setTicketOrders, setEventTicketInventoryList,
  setTaroPasses, setVendors, setUtm,
} = eventSlice.actions;
export default eventSlice.reducer;

import { Currency } from './common';

export type PaymentSetupIntent = {
  setupIntentSecret: string;
  ephemeralKey: string;
  stripeCustomerId: string;
}

export function deserializePaymentSetupIntent(raw: any) {
  return {
    setupIntentSecret: raw.setupIntentSecret,
    ephemeralKey: raw.ephemeralKey,
    stripeCustomerId: raw.stripeCustomerId,
  } as PaymentSetupIntent;
}

export type PaymentInfo = {
  id: string;
  userId: string;
  currency: Currency;
  amountInCents: number;
  last4Digits: string;
  updatedTs: number;
  createdTs: number;
}

export function deserializePaymentInfo(raw: any) {
  return {
    id: raw.id,
    userId: raw.userId,
    currency: raw.currency as Currency,
    amountInCents: raw.amountInCents,
    last4Digits: raw.last4Digits,
    updatedTs: +raw.updatedTs,
    createdTs: +raw.createdTs,
  } as PaymentInfo;
}

export type PaymentAuthorityRecord = {
  ticketOrderId: string;
  paymentId: string;
  authorityPaymentStatus: string;
  intendedAmountInCents: number;
  feeInCents: number;
  refundInCents: number;
  adjustedAmountInCents: number;
  capturedAmountInCents: number;
}

export function deserializePaymentAuthorityRecord(raw: any) {
  return {
    ticketOrderId: raw.ticketOrderId,
    paymentId: raw.paymentId,
    authorityPaymentStatus: raw.authorityPaymentStatus,
    intendedAmountInCents: raw.intendedAmountInCents,
    feeInCents: raw.feeInCents,
    refundInCents: raw.refundInCents,
    adjustedAmountInCents: raw.adjustedAmountInCents,
    capturedAmountInCents: raw.capturedAmountInCents,
  } as PaymentAuthorityRecord;
}

import * as React from 'react';
import { Box, Container } from '@mui/material';

import useWindowDimensions from 'common/src/hooks/useWindowDimensions';

import BgWaveSvg from 'common/src/assets/svgs/BgWaveSvg';
import { HeartsLeftSvg, HeartsRightSvg } from 'common/src/assets/svgs/HeartsSvg';

interface IProps {}
const MainBackground: React.FC<IProps> = () => {
  const { windowWidth } = useWindowDimensions();

  return (
    <Box position='absolute' top={0} left={0} right={0}>
      {
        // This is important for smaller screens to fill up the top part of the screen
        windowWidth < 1200 && (
          <Box position='absolute' width={windowWidth} height={200} top={0} left={0} bgcolor='#E9E9FF' />
        )
      }
      <Container sx={{ position: 'relative' }}>
        <Box position='absolute' top={100} left={-60}>
          <HeartsLeftSvg />
        </Box>
        <Box
          position='absolute' top={60}
          right={Math.max(Math.min(-(windowWidth - 1150)/2, 0), -50)} // This is important to prevent overflow scroll on right
        >
          <HeartsRightSvg />
        </Box>
      </Container>
      <BgWaveSvg />
    </Box>
  );
};

export default MainBackground;

import React from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Stack } from '@mui/material';

import { Text } from 'common/src/components/base';


interface ICountDownProps {
  expiryTs: number;
  onExpiration: () => void;
}

const CountDown: React.FC<ICountDownProps> = ({ expiryTs, onExpiration }) => {
  const [isOpen, setIsOpen] =React.useState<boolean>(false);
  const { t } = useTranslation('wbevt.events');
  const onConfirm = () => {
    setIsOpen(false);
    onExpiration();
  };

  const modalView = <Modal
    open={isOpen}
    onClose={() => onConfirm()}
  >
    <Box
      sx={{
        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', p: 32,
        width: 450, bgcolor: 'background.paper', boxShadow: 24,
      }}
    >
      <Text variant='italics' color='red' mb={16}>{t('registrationResult.holdTokenExpiredPrompt')}</Text>

      <Box mt={24}>
        <Button
          variant='contained'
          onClick={() => onConfirm()}
          sx={{ mr: 10 }}
        >
          {t('registrationResult.confirm')}
        </Button>
      </Box>
    </Box>
  </Modal>;
  const renderer = ({ minutes, seconds, completed }: { minutes: number; seconds: number; completed: boolean }) => {
    if (completed) {
      setIsOpen(true);
    } else {
      return <span>{minutes}:{seconds.toString().padStart(2, '0')}</span>;
    }
  };
  return (
    <>
      {modalView}
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Text pr={5}>{t('registrationResult.timeRemaining')}  </Text>
        <Countdown date={Number(expiryTs)} renderer={renderer}/>
      </Stack>
    </>
  );
};

export default CountDown;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User, UserProfile } from 'common/src/models/user';
import {
  getMyUserRemote, getUserContactRemote, updateProfileRemote, updateUserEmailRemote,
} from 'common/src/system/network/user';

import { AppDispatch, RootState } from '../store';


// ### State ###

interface UserState {
  myUser: User | null;
  vipExpiryTs: number;
  contact: {
    phone: string;
    email: string;
  }
}
const initialState: UserState = {
  myUser: null,
  vipExpiryTs: 0,
  contact: {
    phone: '',
    email: '',
  },
};


// ### Actions ###

export const fetchMyUser = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>('user/fetchMyUser', async (unused_, { dispatch }) => {
  const resp = await getMyUserRemote();
  dispatch(setMyUser(resp.user));
  dispatch(setVipExpiryTs(resp.vipExpiryTs));
});

export const fetchUserContact = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>('user/fetchUserContact', async (unused_, { dispatch }) => {
  const resp = await getUserContactRemote();
  dispatch(setUserContact({
    phone: resp.phone,
    email: resp.email,
  }));
});

export const updateProfile = createAsyncThunk<
  void,
  UserProfile,
  { dispatch: AppDispatch }
>('user/updateProfile', async (userProfile, { dispatch }) => {
  await updateProfileRemote(userProfile);
  await dispatch(fetchMyUser());
});

export const updateEmail = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch }
>('user/updateEmail', async (updatedEmail, { dispatch }) => {
  await updateUserEmailRemote(updatedEmail);
  await dispatch(fetchUserContact());
});


// ### Slice ###

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMyUser: (state, action: PayloadAction<User>) => {
      state.myUser = action.payload;
    },
    setVipExpiryTs: (state, action: PayloadAction<number>) => {
      state.vipExpiryTs = action.payload;
    },
    setUserContact: (state, action: PayloadAction<{ phone: string; email: string }>) => {
      state.contact = action.payload;
    },
  },
});


// ### Selectors ###

export const selectMyUserInfo = (state: RootState) => {
  return state.user.myUser ?
    {
      user: state.user.myUser,
      contact: state.user.contact,
    } :
    null;
};

export const selectIsVip = (state: RootState) => state.user.vipExpiryTs > Date.now();


// ### Exports ### //

export const {
  setMyUser, setVipExpiryTs, setUserContact,
} = userSlice.actions;
export default userSlice.reducer;

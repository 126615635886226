import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../store';
import { refreshTicketOrders } from './event';
import { fetchMyUser, fetchUserContact } from './user';


// ### State ###

interface InitState {
  status: 'uninitialized' | 'initialized' | 'error',
}

const initialState: InitState = {
  status: 'uninitialized',
};


// ### Actions ###

export const init = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch }
>('init/init', async (_unused, { dispatch }) => {
  const myUserPromise = dispatch(fetchMyUser());
  const userContactPromise = dispatch(fetchUserContact());

  // Non-blocking
  dispatch(refreshTicketOrders({ onlyIfUncached: true }));

  await Promise.all([myUserPromise, userContactPromise]);
});


// ### Slice ###

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    invalidateInitialization: (state) => {
      state.status = 'uninitialized';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(init.fulfilled, (state) => {
      state.status = 'initialized';
    });
    builder.addCase(init.rejected, (state) => {
      state.status = 'error';
    });
  },
});


export const { invalidateInitialization } = initSlice.actions;
export default initSlice.reducer;

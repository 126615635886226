import * as React from 'react';

interface ISpinner {
  type?: 'saving' | 'loading' | 'modifying' | 'uploading';
  msg?: string;
}

interface INotifierContext {
  // Spinner
  spinnerMsg: string;
  setSpinner: (spinner: ISpinner) => void;
  clearSpinner: () => void;
}

const NotifierContext = React.createContext<INotifierContext>({
  spinnerMsg: '',
  setSpinner: () => { },
  clearSpinner: () => { },
});

export { NotifierContext };

interface IProps {
  children: React.ReactNode;
}
const NotifierProvider: React.FC<IProps> = ({ children }) => {
  const [spinnerMsg, setSpinnerMsg] = React.useState<string>('');

  const setSpinner = React.useCallback((spinner: ISpinner) => {
    let msg = spinner.msg;
    if (!msg) {
      switch (spinner.type) {
        case 'loading':
          msg = 'Loading';
          break;
        case 'saving':
          msg = 'Saving';
          break;
        case 'modifying':
          msg = 'Modifying';
          break;
        case 'uploading':
          msg = 'Uploading';
          break;
      }
    }

    setSpinnerMsg(msg || '');
  }, []);

  const clearSpinner = React.useCallback(() => {
    setSpinnerMsg('');
  }, []);

  return (
    <NotifierContext.Provider value={{
      spinnerMsg, setSpinner, clearSpinner,
    }}>
      {children}
    </NotifierContext.Provider>
  );
};
export default NotifierProvider;

import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { EventTemplate } from 'common/src/models/event';

import { Text } from 'common/src/components/base';

interface IProps {
  eventTemplate: EventTemplate;
}
const GeneralCancellationPolicy: React.FC<IProps> = ({ eventTemplate }) => {
  const { t } = useTranslation('wbevt.events');
  const { t: tcommon } = useTranslation('wbevt.common');

  const payload = eventTemplate.payload.hostedEventPayload;

  const cancellationHoursAfterEventConfirmation = payload.cancellationHoursAfterEventConfirmation;
  let cancellationAfterEventConfirmation;
  if (cancellationHoursAfterEventConfirmation < 24) {
    cancellationAfterEventConfirmation = `${cancellationHoursAfterEventConfirmation}${tcommon('common.hours')}`;
  } else {
    cancellationAfterEventConfirmation = `${Math.floor(cancellationHoursAfterEventConfirmation/24)}${tcommon('common.days')}`;
  }

  const registerableHours = (eventTemplate.eventFromTs - payload.registrationFromTs) / 1000 / 60 / 60;

  const cancellationHoursBeforeEvent = payload.cancellationHoursBeforeEvent;
  let cancellationBeforeEvent;
  if (cancellationHoursBeforeEvent < 24) {
    cancellationBeforeEvent = `${cancellationHoursBeforeEvent}${tcommon('common.hours')}`;
  } else {
    cancellationBeforeEvent = `${Math.floor(cancellationHoursBeforeEvent/24)}${tcommon('common.days')}`;
  }

  let msg;
  if (registerableHours < cancellationHoursAfterEventConfirmation) {
    if (cancellationHoursBeforeEvent === 0) {
      msg = t('cancellation.generalPolicyContentAnytimeBeforeEvent');
    } else {
      msg = t('cancellation.generalPolicyContentWithinEventStart', {
        cancellationBeforeEvent: cancellationBeforeEvent,
      });
    }
  } else {
    if (cancellationHoursBeforeEvent === 0) {
      msg = t('cancellation.generalPolicyContentWithinConfirmationHours', {
        cancellationAfterEventConfirmation: cancellationAfterEventConfirmation,
      });
    } else {
      msg = t('cancellation.generalPolicyContent', {
        cancellationAfterEventConfirmation: cancellationAfterEventConfirmation,
        cancellationBeforeEvent: cancellationBeforeEvent,
      });
    }
  }

  return (
    <Text size='paragraph'>{msg}</Text>
  );
};

export default GeneralCancellationPolicy;

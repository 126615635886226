import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { EventTemplate, EventTicketInventory, SelectedTicketOption, TicketOption } from 'common/src/models/event';
import { TicketOptionVisibilitySetting } from 'common/src/models/event/ticket';

import { Text } from 'common/src/components/base';
import SeatMap from './SeatMap';

interface IProps {
  eventTemplate: EventTemplate;
  eventTicketInventory: EventTicketInventory;
  selectedTicketOptions: SelectedTicketOption[];
  highlightTicketCountNotice: boolean;
  onSelectedTicketQuantityChange: (ticketOptionId: string, isAdd: boolean, seatId?: string) => void;
}
const TicketSelectorView: React.FC<IProps> = ({
  eventTemplate, eventTicketInventory, selectedTicketOptions, highlightTicketCountNotice,
  onSelectedTicketQuantityChange,
}) => {
  const { t } = useTranslation('wbevt.events');

  const eventPayload = eventTemplate.payload.hostedEventPayload;
  const ticketOptions = eventPayload.ticketOptions;
  const singleSelectableTicket = ticketOptions.length === 1 &&
    ticketOptions[0].maxQuantityPerUser === 1;

  const getTicketOptionStatus = React.useCallback((
    ticketOption: TicketOption,
    selectedTicketOptions: SelectedTicketOption[],
    eventTicketInventory: EventTicketInventory,
  ) => {
    const ticketOptionToTicketsRemaining = eventTicketInventory.ticketOptionToTicketsRemaining;
    const ticketsRemaining = ticketOptionToTicketsRemaining[ticketOption.id];
    const selectedQuantity = selectedTicketOptions
      .filter((selectedTicketOption) => selectedTicketOption.id === ticketOption.id)
      .reduce((acc, selectedTicketOption) => {
        return acc + selectedTicketOption.quantity;
      }, 0);

    const now = Date.now();

    let ticketStatus = '';
    let isWithinRegistrationWindow = true;
    if (ticketOption.registrationFromTs !== 0 && ticketOption.registrationFromTs > now) {
      ticketStatus = t('registration.registrationNotOpen');
      isWithinRegistrationWindow = false;
    } else if (ticketOption.registrationToTs !== 0 && ticketOption.registrationToTs < now) {
      ticketStatus = t('registration.registrationClosed');
      isWithinRegistrationWindow = false;
    } else {
      if (ticketsRemaining <= 0) {
        ticketStatus = t('registration.soldOut');
      } else if (ticketsRemaining <= 5) {
        ticketStatus = t('registration.ticketsRemaining', {
          ticketsRemaining: ticketsRemaining,
        });
      }
    }

    const canAddMoreTickets = isWithinRegistrationWindow &&
        selectedQuantity < ticketOption.maxQuantityPerUser &&
        (ticketOption.allowWaitlist || selectedQuantity < ticketsRemaining);

    return {
      canAddMoreTickets: canAddMoreTickets,
      isWithinRegistrationWindow: isWithinRegistrationWindow,
      ticketStatus: ticketStatus,
      selectedQuantity: selectedQuantity,
    };
  }, [t]);

  const ticketSelectionView = ticketOptions.map((ticketOption) => {
    const { canAddMoreTickets, isWithinRegistrationWindow, ticketStatus, selectedQuantity } = getTicketOptionStatus(
      ticketOption, selectedTicketOptions, eventTicketInventory,
    );

    if (ticketOption.visibilitySetting === TicketOptionVisibilitySetting.NEVER ||
      ticketOption.visibilitySetting === TicketOptionVisibilitySetting.WITHIN_REGISTRATION_WINDOW &&
      !isWithinRegistrationWindow) {
      return null;
    }

    const costInCents = ticketOption.genericCostInCents;
    const seatMapMode = !!eventTemplate.seatMapId;

    return (
      <Box
        key={ticketOption.id}
        mt={20}
        border={1} borderRadius={2} borderColor={Colors.TEXT_SYSTEM_LIGHT}
      >
        <Stack direction={{ sm: 'column', md: 'row' }} justifyContent='space-between' alignItems='center' py={20} px={30}>
          <Stack alignItems={{ sm: 'center', md: 'flex-start' }}>
            <Stack direction='row' alignItems='center'>
              <Text size='subtitle' variant='bold'>{ticketOption.name}</Text>
              {ticketStatus && (
                <Box ml={5} px={5} border={1} borderRadius={2}>
                  <Text size='note'>{ticketStatus}</Text>
                </Box>
              )}
            </Stack>
            <Text size='note' variant='italics'>{ticketOption.description}</Text>
            <Text size='paragraph' mt={10}>${(costInCents/100).toFixed(2)}</Text>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <IconButton
              onClick={() => onSelectedTicketQuantityChange(
                ticketOption.id, /* isAdd */ false, /* seatId */ undefined,
              )}
              disabled={selectedQuantity === 0}
              sx={{ display: seatMapMode ? 'none' : 'block' }}
            >
              <RemoveIcon sx={{
                borderRadius: '50%', fontSize: 25, p: 3,
                color: selectedQuantity === 0 ? Colors.TEXT_SYSTEM_LIGHT : Colors.WHITE,
                bgcolor: selectedQuantity === 0 ? Colors.GREY100 : Colors.PRIMARY_LIGHT,
              }} />
            </IconButton>
            {seatMapMode ? (
              <Text size='paragraph' variant='bold' centered>{t('registration.seatSelectionSummary')}: {selectedQuantity}</Text>
            ) : (
              <Text size='subtitle' variant='bold' width={20} centered>{selectedQuantity}</Text>
            )}
            <IconButton
              onClick={() => onSelectedTicketQuantityChange(
                ticketOption.id, /* isAdd */ true, /* seatId */ undefined,
              )}
              disabled={!canAddMoreTickets}
              sx={{ display: seatMapMode ? 'none' : 'block' }}
            >
              <AddIcon sx={{
                borderRadius: '50%', fontSize: 25, p: 3,
                color: canAddMoreTickets ? Colors.WHITE : Colors.TEXT_SYSTEM_LIGHT,
                bgcolor: canAddMoreTickets ? Colors.PRIMARY_LIGHT : Colors.GREY100,
              }} />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
    );
  });

  return (
    <Box>
      <Box pt={30}>
        <Text size='subtitle' variant='bold'>{
          eventTemplate.seatMapId ? t('registration.seatSelection') :t('registration.ticketOptions')
        }</Text>
        {!singleSelectableTicket && (eventPayload.minSelectableTickets !== 0 || eventPayload.maxSelectableTickets !== 0) && (
          <Text size='paragraph' centered mt={10} color={highlightTicketCountNotice ? 'error' : 'system'}>{
            t('registration.ticketLimitationRules', {
              ticketLimit: eventPayload.minSelectableTickets === eventPayload.maxSelectableTickets ?
                `${eventPayload.minSelectableTickets}` : eventPayload.maxSelectableTickets === 0 ?
                  `${eventPayload.minSelectableTickets}+` :
                  `${eventPayload.minSelectableTickets}-${eventPayload.maxSelectableTickets}`,
            })
          }</Text>
        )}
      </Box>

      {eventTemplate.seatMapId &&
        <SeatMap eventTemplate={eventTemplate} onSeatSelectionChange={onSelectedTicketQuantityChange} />
      }
      {ticketSelectionView}
    </Box>
  );
};

export default TicketSelectorView;

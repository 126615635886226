import * as React from 'react';
import { Box, Button, Checkbox } from '@mui/material';

import useWindowDimensions from 'common/src/hooks/useWindowDimensions';

import { Text } from 'common/src/components/base';
import ActionModal from '../../../components/ActionModal';

interface IProps {
  visible: boolean;
  waiverAccepted: boolean;
  waiverMsg: string;
  onClose: () => void;
  onPayment: () => void;
  onToggleWaiverAcceptance: () => void;
}
const WaiverModal: React.FC<IProps> = ({
  visible, waiverAccepted, waiverMsg, onClose, onPayment, onToggleWaiverAcceptance,
}) => {
  const { windowHeight } = useWindowDimensions();
  return (
    <ActionModal
      visible={visible}
      title='Waiver'
      primaryBtnLabel='Confirm'
      onPrimaryBtnPress={waiverAccepted ? onPayment : undefined}
      onClose={onClose}
      onOverlayPress={onClose}
    >
      <Box>
        <Box maxHeight={windowHeight*0.5} overflow='scroll'>
          <Text textAlign='justify' color='system' size='paragraph'>{waiverMsg.trim()}</Text>
        </Box>
        <Box pt={15}>
          <Button onClick={onToggleWaiverAcceptance} sx={{ pl: 0 }}>
            <Checkbox checked={waiverAccepted} sx={{ pl: 3 }} />I agree to this waiver
          </Button>
        </Box>
      </Box>
    </ActionModal>
  );
};

export default WaiverModal;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, FormControl, InputLabel, Link, MenuItem, Select, TextField, useMediaQuery, useTheme,
} from '@mui/material';

import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import { EventTemplate, QuestionType, SurveyResponse } from 'common/src/models/event';
import { questionnaireInputSchema, validateQuestionnaire } from '../../../utils/validation';

import { UserInput } from 'common/src/components/base';
import ActionModal from '../../../components/ActionModal';

interface IProps {
  visible: boolean;
  eventTemplate: EventTemplate;
  surveyResponses: SurveyResponse[];
  onSurveyResponsesChange: (surveyResponses: SurveyResponse[]) => void;
  onClose: () => void;
  onComplete: () => void;
}
const QuestionnaireModal: React.FC<IProps> = ({
  visible, eventTemplate, surveyResponses, onSurveyResponsesChange, onClose,
  onComplete,
}) => {
  const { t } = useTranslation('wbevt.events');
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));

  const { windowHeight } = useWindowDimensions();

  const [showQuestionaireValidationErr, setShowQuestionaireValidationErr] = React.useState(false);

  const handleQuestionResponseChange = (questionId: string, newValue: string) => {
    const matchingIdx = surveyResponses.findIndex((res) => res.id == questionId);
    if (matchingIdx >= 0) {
      const surveyResponsesCopy = [...surveyResponses];
      surveyResponsesCopy.splice(matchingIdx, 1, { id: questionId, response: newValue });
      onSurveyResponsesChange(surveyResponsesCopy);
    } else {
      onSurveyResponsesChange([...surveyResponses, { id: questionId, response: newValue }]);
    }
  };

  React.useEffect(() => {
    const isValid = validateQuestionnaire(eventTemplate.payload.hostedEventPayload.surveyQuestions, surveyResponses);
    if (isValid) {
      setShowQuestionaireValidationErr(false);
    }
  }, [eventTemplate, surveyResponses]);

  const handleValidateSurveyResponse = React.useCallback(() => {
    const isValid = validateQuestionnaire(eventTemplate.payload.hostedEventPayload.surveyQuestions, surveyResponses);
    if (!isValid) {
      setShowQuestionaireValidationErr(true);
      return false;
    }

    return true;
  }, [eventTemplate, surveyResponses]);

  const handleComplete = React.useCallback(() => {
    if (!handleValidateSurveyResponse()) {
      return;
    }
    onComplete();
  }, [handleValidateSurveyResponse, onComplete]);

  const surveyHasLink = eventTemplate.payload.hostedEventPayload.surveyQuestions.some((survey) => {
    return survey.question.includes('https://');
  });
  const questionView = eventTemplate.payload.hostedEventPayload.surveyQuestions.map((survey) => {
    const surveyResp = surveyResponses.find((res) => res.id == survey.id);

    let label: string | React.ReactNode = survey.question;
    const linkIdx = survey.question.indexOf(' https://');
    if (linkIdx >= 0) {
      let endIdx = survey.question.indexOf(' ', linkIdx+1);
      endIdx = endIdx >= 0 ? endIdx : survey.question.length;
      label = (
        <>
          {survey.question.substring(0, linkIdx+1)}
          <Link href={survey.question.substring(linkIdx+1, endIdx)} target='_blank' rel='noopener noreferrer'>
            {survey.question.substring(linkIdx+1, endIdx)}
          </Link>
          {survey.question.substring(endIdx)}
        </>
      );
    }

    let answerInput;
    switch (survey.type) {
      case QuestionType.SELECT: {
        answerInput = (
          <FormControl variant='standard' fullWidth>
            <InputLabel shrink={surveyHasLink || undefined}>{label}</InputLabel>
            <Select
              value={surveyResp ? surveyResp.response : ''}
              label={survey.question}
              onChange={(event) => {
                handleQuestionResponseChange(survey.id, event.target.value as string);
              }}
            >
              {
                survey.choices.map((choice) => (
                  <MenuItem key={choice.id} value={choice.id}>{choice.text}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        );
        break;
      }
      case QuestionType.FREEFORM:
        answerInput = (
          <FormControl variant='standard' fullWidth>
            {surveyHasLink && <InputLabel shrink>{label}</InputLabel>}
            <TextField
              margin='dense'
              fullWidth
              variant='standard'
              label={surveyHasLink ? ' ' : label}
              value={surveyResp ? surveyResp.response : ''}
              onChange={(event) => {
                handleQuestionResponseChange(survey.id, event.target.value);
              }}
            />
          </FormControl>

        );
        break;
      default:
        throw new Error('BUG: Unexpected survey type');
    }

    return (
      <Box key={survey.id} mb={20}>
        <UserInput validationFn={showQuestionaireValidationErr ?
          () => questionnaireInputSchema.validate(surveyResp?.response) : undefined}
        >
          {answerInput}
        </UserInput>
      </Box>
    );
  });

  return (
    <ActionModal
      visible={visible}
      title={t('registration.questionnaireTitle')}
      errMsg={showQuestionaireValidationErr ? t('registration.missingQuestionnaireError') : undefined}
      primaryBtnLabel='Confirm'
      onPrimaryBtnPress={handleComplete}
      onClose={onClose}
      onOverlayPress={onClose}
    >
      <Box className='alwasy-show-scroll-bar' >
        <Box maxHeight={isSm ? windowHeight - 170 : windowHeight * 0.5} p={20} overflow='scroll'>
          {questionView}
        </Box>
      </Box>
    </ActionModal>
  );
};

export default QuestionnaireModal;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AccessTime as AccessTimeIcon, PlaceOutlined as PlaceOutlinedIcon,
} from '@mui/icons-material';
import { Box, Card, CardActionArea, CardContent, CardMedia, Stack } from '@mui/material';

import { Colors, ComponentStyles } from 'common/src/constants';
import {
  getEventDateLabelWithMask, getSelectedTicketOptions, resolveTicketOption,
} from 'common/src/models/event';
import {
  collateSelectedTicketOptions, getTicketOrderStatusLabel,
} from 'common/src/models/event/ticket';
import { useEventTemplate, useTicketOrder } from '../../../hooks/useResource';

import { Text } from 'common/src/components/base';

const CARD_HEIGHT = 165;

interface IProps {
  ticketOrderId: string
  eventTemplateId: string
}
const TicketOrderCard: React.FC<IProps> = ({ ticketOrderId, eventTemplateId }) => {
  const { t } = useTranslation('wbevt.events');

  const navigate = useNavigate();
  const eventTemplate = useEventTemplate(eventTemplateId);
  const ticketOrder = useTicketOrder(ticketOrderId);

  const handlePressCard = React.useCallback(() => {
    navigate(`/t/${ticketOrderId}`);
  }, [navigate, ticketOrderId]);

  if (!eventTemplate || !ticketOrder) {
    return null;
  }

  const statusLabel = getTicketOrderStatusLabel(ticketOrder);

  const ticketOptionStr = collateSelectedTicketOptions(getSelectedTicketOptions(ticketOrder)) //
    .map((selectedTicketOption) => {
      return `${resolveTicketOption(eventTemplate, selectedTicketOption.id).name} x${selectedTicketOption.quantity}`;
    }).join('，');

  const dateLabel = getEventDateLabelWithMask(eventTemplate, /* showTime */ false);
  return (
    <Card sx={{ borderRadius: 3, my: 20 }}>
      <CardActionArea onClick={handlePressCard} dir='row'>
        <Stack direction={{ sm: 'column', md: 'row' }} sx={{ height: { md: CARD_HEIGHT } }}>
          <Box>
            <Box
              position='absolute'
              py={5} px={15}
              borderRadius={1}
              bgcolor={statusLabel.statusLabelColor}
            >
              <Text color='system' size='note'>{t(statusLabel.statusLabelTextKey)}</Text>
            </Box>
            {
              eventTemplate.media?.downloadUrl ? (
                <CardMedia
                  component='img'
                  image={eventTemplate.media.downloadUrl}
                  alt={eventTemplate.media.id}
                  loading='lazy'
                  sx={{ width: { md: 250 }, height: CARD_HEIGHT }}
                />
              ) : (
                <Box bgcolor='#E2E2E2' height={CARD_HEIGHT}>
                  <Text size='subtitle' color='primary' centered pt={85}>IMG Placeholder</Text>
                </Box>
              )
            }
          </Box>
          <CardContent sx={{ bgcolor: Colors.DEFAULT_BACKGROUND }} >
            <Box px={5} width={{ md: 300 }}>
              <Text size='subtitle' variant='bold' mb={5} maxLines={1}>{eventTemplate.name}</Text>
              <Text size='paragraph' variant='italics' mb={10} maxLines={1}>{ticketOptionStr}</Text>

              {!!dateLabel && (
                <Stack direction='row' alignItems='center'>
                  <AccessTimeIcon
                    color='primary'
                    sx={{ fontSize: ComponentStyles.text.subtitle.fontSize, mr: 7 }}
                  />
                  <Text color='primary' size='paragraph' maxLines={1}>
                    {dateLabel}
                  </Text>
                </Stack>
              )}

              <Stack direction='row' alignItems='center'>
                <PlaceOutlinedIcon
                  color='primary'
                  sx={{ fontSize: ComponentStyles.text.subtitle.fontSize, mr: 7 }}
                />
                <Text color='primary' size='paragraph' maxLines={1}>
                  {eventTemplate.location.city}, {eventTemplate.location.state}
                </Text>
              </Stack>
            </Box>
          </CardContent>
        </Stack>

      </CardActionArea>
    </Card>
  );
};

export default TicketOrderCard;

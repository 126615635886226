import * as React from 'react';
import { Box } from '@mui/material';
import { ValidationError } from 'yup';

import { Colors } from '../../constants';

import Text from './Text';

interface IProps {
  children: React.ReactNode;
  validationFn?: (() => Promise<any>) | null;
  borderType?: 'rounded' | 'underlined' | 'none';
  customMsgFn?: (errMsg: string) => string;
}
// UserInput is a wrapper around any user input to add an error message
const UserInput: React.FC<IProps> = ({
  children, validationFn, borderType, customMsgFn,
}) => {
  const [msg, setMsg] = React.useState('');
  const [isError, setIsError] = React.useState(false);

  const handleMsgChange = React.useCallback((errMsg: string) => {
    const effectiveMsg = customMsgFn ? customMsgFn(errMsg) : errMsg;
    setMsg(effectiveMsg);
  }, [customMsgFn]);

  React.useEffect(() => {
    // We need to make sure we're using the latest result on fast updates.
    let isValid = true;

    if (!validationFn) {
      handleMsgChange('');
      setIsError(false);
    } else {
      validationFn().then(() => {
        if (isValid) {
          handleMsgChange('');
          setIsError(false);
        }
      }).catch((validationErr) => {
        if (isValid) {
          if (validationErr instanceof ValidationError && validationErr.errors.length) {
            handleMsgChange(validationErr.errors[0]);
          } else {
            handleMsgChange('错误'); // This means there's a bug in the code.
          }
          setIsError(true);
        }
      });
    }

    return () => {
      isValid = false;
    };
  }, [validationFn, handleMsgChange]);

  let effectiveStyle = {};
  switch (borderType) {
    case 'underlined':
      effectiveStyle = {
        borderBottomWidth: 1,
        borderBottomColor: isError ? Colors.ERROR : Colors.PRIMARY_BACKGROUND,
      };
      break;
    case 'none':
      effectiveStyle = {
        mb: 8,
      };
      break;
    default:
      effectiveStyle = {
        borderColor: isError ? Colors.ERROR : Colors.PRIMARY_BACKGROUND,
        borderRadius: 20,
        borderWidth: 1,
      };
      break;
  }
  const msgView = !!msg && (
    <Text size='note' color={isError ? 'error' : 'system-light' } mt={2}>
      {msg}
    </Text>
  );
  return (
    <Box>
      <Box sx={effectiveStyle}>
        {children}
      </Box>
      {msgView}
    </Box>
  );
};

export default UserInput;

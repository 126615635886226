import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar, Box, CardMedia, Container, Grid, Link, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import Env from 'common/src/env';
import { EventTemplate } from 'common/src/models/event';
import { GroupedEventTemplate } from 'common/src/models/event/eventTemplateGroup';
import { DefaultStyleAttrs } from '../../constants/styles';
import { useEventTemplateGroup, useEventTemplates, useEventTicketInventories, useSelectEventTemplates, useVendors } from '../../hooks/useResource';

import { HorizontalScoll, Loading, Page, Text } from 'common/src/components/base';
import ActionModal from '../../components/ActionModal';
import EventListingCard from '../../components/EventListingCard';
import { EventTemplateGroupIntroHeader } from '../../components/IntroHeader';
import MainBackground from '../../components/MainBackground';
import TopMenu from '../../components/TopMenu';

export default function EventGroupScreen() {
  const { eventTemplateGroupId } = useParams();
  const { t } = useTranslation('wbevt.events');
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const eventTicketInventories = useEventTicketInventories();

  if (!eventTemplateGroupId) {
    throw new Error('BUG: eventTemplateGroupId missing for EventGroupScreen');
  }

  const [galleryIdx, setGalleryIdx] = React.useState(-1);

  const navigate = useNavigate();

  const eventTemplateGroup = useEventTemplateGroup(eventTemplateGroupId);
  const currentEventTemplates = useEventTemplates(/* includePastEvents */ false);
  const vendors = useVendors();

  const associatedEventTemplateIds = eventTemplateGroup ?
    eventTemplateGroup.groupedEventTemplates.map((groupedEventTemplate) => groupedEventTemplate.eventTemplateId) :
    [];
  const associatedEventTemplates = useSelectEventTemplates(associatedEventTemplateIds);

  React.useEffect(() => {
    document.title = eventTemplateGroup ? `Taro - ${eventTemplateGroup.name}` : 'Taro';
  }, [eventTemplateGroup]);

  const handleSeeAllEvents = React.useCallback(() => navigate('/'), [navigate]);

  if (!eventTemplateGroup ||
      !vendors ||
      Object.values(associatedEventTemplates).some((eventTemplate) => !eventTemplate)
  ) {
    return <Container><Loading /></Container>;
  }

  const associatedDetailedEventTemplates: {eventTemplate: EventTemplate, groupedEventTemplate: GroupedEventTemplate}[] = [];
  for (const groupedEventTemplate of eventTemplateGroup.groupedEventTemplates) {
    const eventTemplate = associatedEventTemplates[groupedEventTemplate.eventTemplateId];
    if (eventTemplate) {
      associatedDetailedEventTemplates.push({
        eventTemplate: eventTemplate,
        groupedEventTemplate: groupedEventTemplate,
      });
    }
  }

  let effectiveVendorId: undefined | null | string = undefined;
  for (const associatedDetailedEventTemplate of associatedDetailedEventTemplates) {
    const vendorId = associatedDetailedEventTemplate.eventTemplate.payload.hostedEventPayload.vendorId;
    if (vendorId) {
      if (effectiveVendorId === undefined) {
        effectiveVendorId = vendorId;
      } else if (effectiveVendorId !== vendorId) {
        effectiveVendorId = null;
        break;
      }
    }
  }
  const vendor = effectiveVendorId && vendors.find((vendor) => vendor.id === effectiveVendorId) || null;

  let effectiveState: undefined | null | string = undefined;
  for (const associatedDetailedEventTemplate of associatedDetailedEventTemplates) {
    const state = associatedDetailedEventTemplate.eventTemplate.location.state.trim().toLowerCase();
    if (state) {
      if (effectiveState === undefined) {
        effectiveState = state;
      } else if (effectiveState !== state) {
        effectiveState = null;
        break;
      }
    }
  }

  const relatedEventTemplates = Object.values(currentEventTemplates)
    .filter((eventTemplate_) =>
      !eventTemplate_.archived && (Env.shouldShowWIPFeatures() || eventTemplate_.published),
    )
    .filter((eventTemplate_) =>
      !effectiveState || eventTemplate_.location.state.trim().toLowerCase() === effectiveState,
    )
    .filter((eventTemplate_) =>
      // Future event
      eventTemplate_.payload.hostedEventPayload.registrationToTs > Date.now(),
    )
    .filter((eventTemplate_) => !associatedDetailedEventTemplates
      .some((associatedDetailedEventTemplate) => associatedDetailedEventTemplate.eventTemplate.id === eventTemplate_.id))
    .filter((eventTemplate_) => eventTemplate_.visible)
    .sort((eventTemplate1, eventTemplate2) => {
      // We prioritize showing events that are still open for registration
      // Of those, we prioritize showing events that are closing soon
      const now = Date.now();
      const ts1 = eventTemplate1.payload.hostedEventPayload.registrationToTs;
      const ts2 = eventTemplate2.payload.hostedEventPayload.registrationToTs;
      if (ts1 < now) {
        return 1;
      }
      if (ts2 < now) {
        return -1;
      }
      return ts1 - ts2;
    })
    .slice(0, 10);

  const imgGalleryModal = galleryIdx > -1 && (
    <ActionModal onClose={() => setGalleryIdx(-1)} onOverlayPress={() => setGalleryIdx(-1)}>
      <ImageGallery
        showPlayButton={false}
        startIndex={galleryIdx}
        items={eventTemplateGroup.extraMedia.map((media) => ({
          original: media.downloadUrl,
          thumbnail: media.downloadUrl,
        }))}
      />
    </ActionModal>
  );

  return (
    <Page>
      {imgGalleryModal}
      <Box>
        <Container>
          <Box mx={DefaultStyleAttrs.responsiveMx}>
            <MainBackground />
            <TopMenu />
            <EventTemplateGroupIntroHeader
              variant='cover'
              eventTemplateGroup={eventTemplateGroup}
              associatedEventTemplates={associatedDetailedEventTemplates
                .map((associatedDetailedEventTemplate) => associatedDetailedEventTemplate.eventTemplate)}
            />

            <Box>
              <Text size='subtitle' variant='bold' mt={30}>{t('details.eventIntro')}</Text>
              <Text size='paragraph' mt={15}>{eventTemplateGroup.description}</Text>
            </Box>

            <Box mt={15} mb={30}>
              <Text size='subtitle' variant='bold' mt={30} mb={15}>{t('details.eventChoices')}</Text>
              <Grid container columnSpacing={20} rowSpacing={20}>
                {associatedDetailedEventTemplates.map((associatedDetailedEventTemplate) => (
                  <Grid item key={associatedDetailedEventTemplate.eventTemplate.id} sm={12} md={6} lg={4} display='flex'>
                    <EventListingCard
                      eventTemplateId={associatedDetailedEventTemplate.groupedEventTemplate.eventTemplateId}
                      nameOverride={associatedDetailedEventTemplate.groupedEventTemplate.name}
                      hideImg={isSm}
                      eventTicketInventory={eventTicketInventories[associatedDetailedEventTemplate.eventTemplate.id] || null}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>


          {eventTemplateGroup.extraMedia.length > 0 && (
            <Box>
              <Box mx={DefaultStyleAttrs.responsiveMx}>
                <Text size='subtitle' variant='bold' mt={30} mb={15}>{t('details.eventPreview')}</Text>
              </Box>
              <HorizontalScoll>
                {eventTemplateGroup.extraMedia.map((media, idx) => (
                  <CardMedia
                    key={media.id}
                    component='img'
                    image={media.downloadUrl}
                    alt={media.id}
                    loading='lazy'
                    onClick={() => setGalleryIdx(idx)}
                    sx={{ height: 185, objectFit: 'cover', cursor: 'pointer' }}
                  />
                ))}
              </HorizontalScoll>
            </Box>
          )}

          <Box mx={DefaultStyleAttrs.responsiveMx}>
            {vendor && (
              <Box sx={{ flexDirection: 'row', display: 'flex',
                width: '100%' }} bgcolor='#E8E7EA' my={20} p={20} >
                <Box borderRadius={100} mr={15}>
                  <Avatar
                    src={vendor.refMedia.downloadUrl} alt=''
                    variant='rounded'
                    sx={{ width: 100, height: 75 }}
                  />
                </Box>
                <Box>
                  <Text size='subtitle' >{vendor.name}</Text>
                  <Text size='paragraph' mt={10}>{vendor.description}</Text>
                </Box>
              </Box>
            )}
          </Box>
        </Container>

        <Box bgcolor='#E8E7EA' mt={50} py={70}>
          <Container>
            <Stack direction='row' justifyContent='space-between' mb={15} mx={DefaultStyleAttrs.responsiveMx}>
              <Text size='subtitle' variant='bold'>{t('details.followOtherEvents')}</Text>
              <Link onClick={handleSeeAllEvents} underline='hover'>
                {t('details.checkAllEvents')}
              </Link>
            </Stack>
            <HorizontalScoll>
              {relatedEventTemplates
                .map((eventTemplate) => (
                  <EventListingCard
                    key={eventTemplate.id}
                    eventTemplateId={eventTemplate.id}
                    eventTicketInventory={eventTicketInventories[eventTemplate.id] || null}
                  />
                ))}
            </HorizontalScoll>
          </Container>
        </Box>
      </Box>
    </Page>
  );
}

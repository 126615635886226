import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AppBar, Box, Button, Container, Divider, IconButton, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import useNotifier from 'common/src/hooks/useNotifier';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import {
  collateSelectedTicketOptions, CostAdjustor, getCostAggregation, resolveTicketOption,
  TicketCostPreview,
} from 'common/src/models/event';
import { previewTicketOrderCostRemote } from 'common/src/system/network/event';
import { DefaultStyleAttrs } from '../../../constants/styles';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { useEventTemplate, useEventTicketInventory } from '../../../hooks/useResource';
import { selectCart, selectSeatMapHoldTokenExpireTs } from '../../../redux/slices/cart';
import { completeTicketOrder, initiateTicketOrder } from '../../../redux/slices/event';

import { Loading, Page, Text } from 'common/src/components/base';
import CancellationPolicy from '../../../components/CancellationPolicy';
import CountDown from '../../../components/CountDown';
import CouponCodeView from './CouponCodeView';

export default function OrderConfirmationScreen() {
  const { t } = useTranslation('wbevt.events');

  const { windowHeight } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notifier = useNotifier();
  const holdTokenExpireTs = useAppSelector(selectSeatMapHoldTokenExpireTs);

  const cart = useAppSelector(selectCart);
  const {
    selectedEventTemplateId: eventTemplateId, selectedTicketOptions, selectedSurveyResponses,
  } = cart;

  const eventTicketInventory = useEventTicketInventory(eventTemplateId);

  const [ticketCostPreviews, setTicketCostPreviews] = React.useState<TicketCostPreview[]|null>(null);
  const costAggregation = React.useMemo(() =>
    ticketCostPreviews ?
      getCostAggregation(ticketCostPreviews.map((ticketCostPreview) => ticketCostPreview.ticketCost)) :
      null,
  [ticketCostPreviews]);

  React.useEffect(() => {
    if (ticketCostPreviews) {
      return;
    }

    (async () => {
      const resp = await previewTicketOrderCostRemote(
        eventTemplateId, selectedTicketOptions, []);
      if (resp.errCode) {
        throw new Error('Failed to preview ticket order cost');
      }
      setTicketCostPreviews(resp.ticketCostPreviews);
    })();
  }, [ticketCostPreviews, eventTemplateId, selectedTicketOptions]);

  const onExpiration = ()=>{
    navigate(`/e/${eventTemplateId}/r`);
  };

  const handleApplyCouponCode = React.useCallback(async (couponCode: string) => {
    notifier.showSpinner({ type: 'loading' });
    const resp = await previewTicketOrderCostRemote(eventTemplateId, selectedTicketOptions, [couponCode]);
    if (resp.errCode) {
      notifier.hideSpinner();
      return resp.errCode;
    }

    setTicketCostPreviews(resp.ticketCostPreviews);

    notifier.hideSpinner();
    return '';
  }, [eventTemplateId, selectedTicketOptions, notifier]);

  const eventTemplate = useEventTemplate(eventTemplateId);

  const isFree = !!costAggregation && costAggregation.finalCostInCents === 0;

  const handlePaymentPress = React.useCallback(async () => {
    if (!eventTemplate) {
      return;
    }

    notifier.showSpinner({ type: 'loading' });

    const initiateTicketOrderResp = await dispatch(initiateTicketOrder({
      eventTemplateId: eventTemplateId,
      selectedTicketOptions: selectedTicketOptions,
      couponCodes: costAggregation ? costAggregation.costAdjustors.map((ca: CostAdjustor) => ca.adjustorId) : [],
    }));

    if (!initiateTicketOrder.fulfilled.match(initiateTicketOrderResp)) {
      notifier.hideSpinner();
      throw new Error('Failed to initiate ticket order');
    }
    const { ticketOrderId, paymentSetupIntent } = initiateTicketOrderResp.payload;

    if (isFree) {
      // Free event, we bypass payment screen and directly complete the ticket order
      await dispatch(completeTicketOrder({
        ticketOrderId: ticketOrderId,
        surveyResponses: selectedSurveyResponses,
      }));

      notifier.hideSpinner();
      navigate(`/t/${ticketOrderId}`);
    } else {
      // Paid event, we proceed to payment screen
      notifier.hideSpinner();
      navigate(`/p/payment?toid=${ticketOrderId}&sis=${paymentSetupIntent.setupIntentSecret}`);
    }
  }, [
    eventTemplate, notifier, dispatch, eventTemplateId, selectedTicketOptions, costAggregation,
    isFree, selectedSurveyResponses, navigate,
  ]);

  if (!eventTemplateId) {
    navigate('/');
    return null;
  }

  if (!eventTemplate || !eventTicketInventory || !costAggregation || !ticketCostPreviews) {
    return <Container><Loading /></Container>;
  }

  let label = t('registration.register');
  let note = '';
  // iterate through all the selected ticket options and check if any of them have insufficient tickets remaining
  const ticketOptionToTicketsRemaining = eventTicketInventory.ticketOptionToTicketsRemaining;
  const collatedSelectedTicketOptions = collateSelectedTicketOptions(selectedTicketOptions);
  for (const collatedSelectedTicketOption of collatedSelectedTicketOptions) {
    if (ticketOptionToTicketsRemaining[collatedSelectedTicketOption.id] < collatedSelectedTicketOption.quantity) {
      label = t('registration.joinWaitlist');
      note = t('registration.joinWaitlistDescription');
      break;
    }
  }

  return (
    <Page>
      <AppBar position='fixed' color='transparent'>
        <Box px={DefaultStyleAttrs.mx}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <IconButton onClick={() => navigate(`/e/${eventTemplateId}/r`)}>
              <KeyboardBackspaceIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 30 }} />
            </IconButton>
            <Text size='subtitle' color='system'>{t('registrationConfirmation.title')}</Text>
            <Box />
          </Stack>
        </Box>
      </AppBar>

      <Box
        mt={50} mb={75} height={windowHeight - 50-75} py={20} overflow='auto'
        bgcolor={Colors.DEFAULT_BACKGROUND}
      >
        {eventTemplate.seatMapId && holdTokenExpireTs &&
        <CountDown expiryTs={holdTokenExpireTs} onExpiration={onExpiration} />
        }
        <Container>
          <Box mx={DefaultStyleAttrs.responsiveMx}>
            <Box border={1} borderColor={Colors.DISABLED} p={10}>
              {ticketCostPreviews ? (
                <Box>
                  {
                    selectedTicketOptions.map((selectedTicketOption, idx) => {
                      if (selectedTicketOption.quantity === 0) {
                        return null;
                      }
                      let costInCents = 0;
                      for (const ticketCostPreview of ticketCostPreviews) {
                        if (ticketCostPreview.selectedTicketOptionIdx === idx) {
                          costInCents += ticketCostPreview.ticketCost.subtotalInCents;
                        }
                      }
                      const ticketOption = resolveTicketOption(eventTemplate, selectedTicketOption.id);
                      return (
                        <Stack direction='row' key={'o-' + idx} justifyContent='space-between' py={1}>
                          <Text>
                            {ticketOption.name} x{selectedTicketOption.quantity}{selectedTicketOption.seatId ? ` (Seat: ${selectedTicketOption.seatId})` : ''}:
                          </Text>
                          <Text>${(costInCents/100).toFixed(2)}</Text>
                        </Stack>
                      );
                    })
                  }

                  <Divider sx={{ my: 5 }} />

                  <Stack direction='row' justifyContent='space-between' py={1}>
                    <Text>{t('registrationResult.subtotal')}:</Text>
                    <Text>${(costAggregation.subtotalInCents/100).toFixed(2)}</Text>
                  </Stack>

                  {costAggregation.costAdjustors.map((costAdjustor, idx) => {
                    return (
                      <Stack key={'d-' + idx} direction='row' justifyContent='space-between' py={1}>
                        <Text>{t('registrationResult.discount')} ({costAdjustor.adjustorName}):</Text>
                        <Text>-${(-costAdjustor.adjustmentInCents/100).toFixed(2)}</Text>
                      </Stack>
                    );
                  })}

                  {costAggregation.taxInCents > 0 && (
                    <Stack direction='row' justifyContent='space-between' py={1}>
                      <Text>{t('registrationResult.tax')}:</Text>
                      <Text>${(costAggregation.taxInCents/100).toFixed(2)}</Text>
                    </Stack>
                  )}

                  <Stack direction='row' justifyContent='space-between' py={1}>
                    <Text>{t('registrationResult.fee')}:</Text>
                    <Text>${(costAggregation.feeInCents/100).toFixed(2)}</Text>
                  </Stack>

                  <Stack direction='row' justifyContent='space-between' py={1}>
                    <Text>{t('registrationResult.total')}:</Text>
                    <Text>${(costAggregation.finalCostInCents/100).toFixed(2)}</Text>
                  </Stack>

                </Box>
              ) :
                <Container><Loading /></Container>
              }
            </Box>

            <CouponCodeView onApplyCouponCode={handleApplyCouponCode} />

            <CancellationPolicy eventTemplate={eventTemplate} />
          </Box>
        </Container>
      </Box>

      <AppBar position='fixed' color='transparent' sx={{ top: 'auto', bottom: 0 }}>
        <Box py={20} px={DefaultStyleAttrs.mx} bgcolor={Colors.WHITE}>
          <Box
            display='flex' justifyContent='flex-end' alignItems='center'
          >
            <Button
              onClick={handlePaymentPress}
              variant='contained'
              disabled={!costAggregation}
            >
              {label + (costAggregation ? ` - $${(costAggregation.finalCostInCents/100).toFixed(2)}` : '')}
            </Button>
          </Box>
          {!!note && (
            <Text color='system' size='note' my={10} display='flex' justifyContent='end'>* {note}</Text>
          )}
        </Box>
      </AppBar>
    </Page>
  );
}

import { deserializeResourceRef, ResourceRef } from '../shared';
import { deserializeEventLocation, EventContact, EventLocation } from './common';

export type TaroPassRecord = {
  id: string;
  name: string;
  published: boolean;

  category: TaroPassCategoryType;
  intro: string;
  detail: string;
  location: EventLocation;
  refMedia: ResourceRef;
  createdTs: number;
  expiryTs: number;
  contact: EventContact;
}

export enum TaroPassCategoryType {
  UNDEFINED = '',
  ENTERTAINMENT = '娱乐',
  FOOD = '美食',
  LIFE = '生活',
  DRINK = '茶饮',
  EDUCATION = '学习',
}

export function deserializeTaroPassRecord(raw: any) {
  return {
    id: raw.id,
    name: raw.name,
    published: raw.published,

    category: raw.category as TaroPassCategoryType,
    intro: raw.intro,
    detail: raw.detail,
    location: deserializeEventLocation(raw.location),
    refMedia: raw.refMedia ? deserializeResourceRef(raw.refMedia) : null,
    thumbnailRefMedia: raw.thumbnailRefMedia ? deserializeResourceRef(raw.thumbnailRefMedia) : null,
    createdTs: +raw.createdTs,
    expiryTs: +raw.expiryTs,
    contact: raw.contact,
  } as TaroPassRecord;
}

import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { DefaultStyleAttrs } from '../../constants/styles';

import { Page, Text } from 'common/src/components/base';
import MainBackground from '../../components/MainBackground';
import TopMenu from '../../components/TopMenu';
import MainContent from './MainContent';

export default function HomeScreen() {
  const { t, i18n } = useTranslation('wbevt.home');

  return (
    <Page centered>
      <MainBackground />
      <TopMenu addMarginX />
      <Stack
        position='relative' mx={DefaultStyleAttrs.responsiveMx}
        direction='row' mb={32} alignItems='center' justifyContent='space-between'
      >
        <Box mt={{ sm: 10, md: 40, lg: 80 }} mx={30}>
          <Text
            fontSize={i18n.language === 'zh' ? 40 : 25} mb={32} zIndex={100}
            sx={{ fontFamily: i18n.language === 'zh' ? 'ZhiMangXing' : 'Quicksand' }}
          >
            {t('landing.title')}
          </Text>
        </Box>
        <Box
          component='img'
          src={require('../../assets/images/couple-picnic.png')}
          sx={{
            width: { sm: 0, md: 150, lg: 200 },
            height: { sm: 0, md: 150, lg: 200 },
          }}
          alt='Logo'
        />
      </Stack>

      <Box mx={DefaultStyleAttrs.responsiveMx}>
        <MainContent />
      </Box>

      <Box
        bgcolor={Colors.PRIMARY_BACKGROUND}
        display='flex' flexDirection='column' alignItems='center'
        py={70} mt={70}
      >
        <Text fontSize={30} color='primary' variant='bold' mb={20}>{t('landing.taroName')}</Text>
        <Text size='paragraph' color='primary'>{t('landing.taroSlogan')}</Text>
      </Box>

      <Box display='flex' flexDirection='column' alignItems='center' my={30}>
        <Text size='paragraph' color='primary' mb={15}>support@hellotaro.com</Text>
        <Text size='paragraph' color='primary'>Copyright Taro LLC</Text>
      </Box>
    </Page>
  );
}

export interface IProps {
  size: number
}
export default function DefaultLogoSvg({ size }: IProps) {
  return (
    <svg width={size} height={size} viewBox='0 0 374 360' fill='none'>
      <path d='M231.019 124.159C239.464 120.952 289.297 123.256 302.905 123.23C367.06 123.142 363.945 101.477 368.649 45.1614C371.146 15.2516 369.355 20.4469 322.928 20.4469H250.628C235.705 20.4469 189.834 18.6947 178.998 21.3668C160.598 25.9049 149.806 42.7697 144.052 58.5043C136.913 78.006 136.763 99.3564 135.316 121.495C132.359 166.938 130.665 212.153 127.338 257.228C126.385 270.098 122.105 314.498 125.194 324.039C135.713 356.463 177.109 351.469 206.186 351.355C212.637 351.329 216.132 350.689 217.756 344.627C219.185 339.291 225.707 209.595 226.889 191.688C228.151 172.545 227.869 141.076 231.019 124.159Z' fill='#928AC1' />
      <path d='M87.1506 103.509L106.706 231.629C110.262 226.109 115.319 110.824 116.237 93.6531C117.402 71.8647 122.714 51.627 132.068 35.5682C143.743 15.5495 150.785 11.6071 168.126 3.23171L172.344 0.559626C143.752 -0.640618 112.645 0.463258 83.7708 0.463258C54.6406 0.445735 27.3106 -2.85713 14.4442 20.4819C7.64036 32.7998 7.86981 41.0701 6.17547 56.2089C5.55774 61.7371 2.88387 93.4779 4.52526 97.394C8.1522 106.111 31.1052 103.281 42.6125 103.281C56.9614 103.281 73.0135 102.616 87.1506 103.509Z' fill='#5C509D' />
    </svg>
  );
}

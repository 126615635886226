import { deserializeResourceRef, ResourceRef, resourceRefEquivalent } from './shared';

// ### User ### //

export type User = {
  id: string;
  userProfile: UserProfile;
  matchPreference: MatchPreference;
  userMedia: UserMedia;
  accountPreference: AccountPreference;
};

export function deserializeUser(raw: any) {
  return {
    id: raw.id,
    userProfile: deserializeUserProfile(raw.userProfile),
    matchPreference: deserializeMatchPreference(raw.matchPreference),
    userMedia: deserializeUserMedia(raw.userMedia),
    accountPreference: deserializeAccountPreference(raw.accountPreference),
  } as User;
}

export type UserContact = {
  email: string;
  phone: string;
};

// ### User Profile ### //

export type UserProfile = {
  coreUserInfo: CoreUserInfo;

  weight: number;
  religion: string;
  degree: string;
  school: string;
  occupation: string;
  employer: string;
  hometown: string;

  mbti: MBTI | null;

  profileStories: ProfileStory[];
};

function deserializeUserProfile(raw: any) {
  const profileStories = raw.profileStories ?
    raw.profileStories.map((profileStory: any) =>
      deserializeProfileStory(profileStory),
    ) :
    [];
  return {
    coreUserInfo: deserializeCoreUserInfo(raw.coreUserInfo),

    weight: raw.weight || 0,
    religion: raw.religion || '',
    degree: raw.degree || '',
    school: raw.school || '',
    occupation: raw.occupation || '',
    employer: raw.employer || '',
    hometown: raw.hometown || '',

    mbti: deserializeMBTI(raw.mbti),

    profileStories: profileStories,
  } as UserProfile;
}

export type CoreUserInfo = {
  name: string;
  gender: Gender;
  dob: string;
  location: Location;
  height: number;
  ethnicity: string;
};

function deserializeCoreUserInfo(raw: any) {
  return {
    name: raw.name,
    gender: raw.gender,
    dob: raw.dob,
    location: deserializeLocation(raw.location),
    height: raw.height,
    ethnicity: raw.ethnicity,
  } as CoreUserInfo;
}

export type MBTI = {
  e: number;
  s: number;
  t: number;
  j: number;
};

function deserializeMBTI(raw: any): MBTI | null {
  if (!raw || !raw.isFilled) {
    return null;
  }

  return {
    e: +raw.e,
    s: +raw.s,
    t: +raw.t,
    j: +raw.j,
  } as MBTI;
}

export function getZodiac(dob: string): { text: string; icon: string } {
  const d = new Date(dob);
  const month = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
    return { text: '摩羯座', icon: 'zodiac-capricorn' };
  } else if ((month === 1 && day >= 20) || (month === 2 && day <= 18)) {
    return { text: '水瓶座', icon: 'zodiac-aquarius' };
  } else if ((month === 2 && day >= 19) || (month === 3 && day <= 20)) {
    return { text: '双鱼座', icon: 'zodiac-pisces' };
  } else if ((month === 3 && day >= 21) || (month === 4 && day <= 20)) {
    return { text: '白羊座', icon: 'zodiac-aries' };
  } else if ((month === 4 && day >= 21) || (month === 5 && day <= 20)) {
    return { text: '金牛座', icon: 'zodiac-taurus' };
  } else if ((month === 5 && day >= 21) || (month === 6 && day <= 21)) {
    return { text: '双子座', icon: 'zodiac-gemini' };
  } else if ((month === 6 && day >= 22) || (month === 7 && day <= 22)) {
    return { text: '巨蟹座', icon: 'zodiac-cancer' };
  } else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
    return { text: '狮子座', icon: 'zodiac-leo' };
  } else if ((month === 8 && day >= 23) || (month === 9 && day <= 22)) {
    return { text: '处女座', icon: 'zodiac-virgo' };
  } else if ((month === 9 && day >= 23) || (month === 10 && day <= 22)) {
    return { text: '天秤座', icon: 'zodiac-libra' };
  } else if ((month === 10 && day >= 23) || (month === 11 && day <= 21)) {
    return { text: '天蝎座', icon: 'zodiac-scorpio' };
  } else {
    return { text: '射手座', icon: 'zodiac-sagittarius' };
  }
}

export type Location = {
  longlat: string; // comma separated (e.g. "49.283764, 122.793205")
  latlng: string; // comma separated (e.g. "49.283764, 122.793205")
  zipcode: string;
  address: string;
  city: string;
  state: string;
};

export function deserializeLocation(raw: any) {
  return {
    longlat: raw.longlat || '',
    latlng: raw.latlng || '',
    zipcode: raw.zipcode || '',
    address: raw.address || '',
    city: raw.city || '',
    state: raw.state || '',
  } as Location;
}

export type ProfileStory = {
  type: ProfileStoryType;
  title: string;
  tags: string[];
  text: string;
  images: UserResource[];
};

function deserializeProfileStory(raw: any) {
  return {
    type: raw.type as ProfileStoryType,
    title: raw.title || '',
    tags: raw.tags || [],
    text: raw.text || '',
    images: raw.images || [],
  } as ProfileStory;
}

export enum ProfileStoryType {
  PREDEFINED = 'predefined',
  CUSTOM = 'custom',
}

export enum PredefinedProfileStoryTitle {
  INTRO = 'intro',
  INTERESTS = 'interests',
  IDEAL_MATCH = 'ideal_match',
}


// ### Match Preference ### //

export type MatchPreference = {
  ageMin: number;
  ageMax: number;
  gender: Gender;
  location: LocationPreference;
};

function deserializeMatchPreference(raw: any) {
  return {
    ageMin: raw.ageMin || 0,
    ageMax: raw.ageMax || 0,
    gender: raw.gender,
    location: raw.location ? raw.location as Location : LocationPreference.UNRESTRCITED,
  } as MatchPreference;
}


// ### User Media ### //

export type UserMedia = {
  profileImages: UserResource[];
  selfImages: UserResource[];
  verificationImageStatus: VerificationImageStatus;
};

function deserializeUserMedia(raw: any) {
  const profileImages = raw.profileImages ?
    raw.profileImages.map((userResource: any) =>
      deserializeUserResource(userResource),
    ) :
    [];
  const selfImages = raw.selfImages ?
    raw.selfImages.map((userResource: any) =>
      deserializeUserResource(userResource),
    ) :
    [];
  return {
    profileImages: profileImages,
    selfImages: selfImages,
    verificationImageStatus: raw.verificationImageStatus as VerificationImageStatus,
  } as UserMedia;
}

export type UserResource = {
  ref: ResourceRef;
  publicRef: ResourceRef | undefined;
  text: string;
  verificationStatus: VerificationImageStatus;
};

function deserializeUserResource(raw: any) {
  const publicRef = raw.publicRef ?
    deserializeResourceRef(raw.publicRef) :
    undefined;
  return {
    ref: deserializeResourceRef(raw.ref),
    publicRef: publicRef,
    text: raw.text || '',
    verificationStatus: raw.verificationStatus as VerificationImageStatus,
  } as UserResource;
}

// Checks if the two user resources are equivalent (not necessarily equal)
export function userResourceEquivalent(res1: UserResource, res2: UserResource): boolean {
  const publicRefEqual = !res1.publicRef && !res2.publicRef ||
    (res1.publicRef !== undefined && res2.publicRef !== undefined &&
      resourceRefEquivalent(res1.publicRef, res2.publicRef));
  return resourceRefEquivalent(res1.ref, res2.ref) &&
    publicRefEqual &&
    res1.text === res2.text &&
    res1.verificationStatus === res2.verificationStatus;
}

export function userResourcesEquivalent(
  userResources1: UserResource[], userResources2: UserResource[],
): boolean {
  // Compare profileImages
  if (userResources1.length !== userResources2.length) {
    return false;
  }
  for (let i = 0; i < userResources1.length; i++) {
    if (!userResourceEquivalent(userResources1[i], userResources2[i])) {
      return false;
    }
  }

  return true;
}

export enum VerificationImageStatus {
  PENDING = 'pending',
  NA = 'na',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
}

export type ImageMeta = {
  id: string;
  url: string;
  text: string;
  verificationStatus: VerificationImageStatus;
};

export function extractImageMeta(res: UserResource, showPrivateView: boolean) {
  const ref = showPrivateView || !res.publicRef ? res.ref : res.publicRef;
  return {
    id: ref.id,
    url: ref.downloadUrl,
    text: res.text,
    verificationStatus: res.verificationStatus,
  };
}

// ### Account Preference ### //

export type AccountPreference = {
  language: string;
  inactive: boolean;
};

function deserializeAccountPreference(raw: any) {
  return {
    language: raw.language,
    inactive: raw.inactive,
  } as AccountPreference;
}

// ### VerificationChallenge ### //

export type VerificationChallenge = {
  id: string;
  text: string;
  ref: ResourceRef;
  publicRef?: ResourceRef;
};

export function deserializeVerificationChallenge(raw: any) {
  const publicRef = raw.publicRef ?
    deserializeResourceRef(raw.publicRef) :
    undefined;
  return {
    id: raw.id,
    text: raw.text,
    ref: deserializeResourceRef(raw.ref),
    publicRef: publicRef,
  } as VerificationChallenge;
}

// ### NotificationSettings ### //

export type NotificationSettings = {
  notificationsEnabled: boolean;
  dailySuggestionNotificationsEnabled: boolean;
  newMatchNotificationsEnabled: boolean;
  missedInteractionsNotificationsEnabled: boolean;
};

export function deserializeNotificationSettings(raw: any) {
  return {
    notificationsEnabled: !!raw.notificationsEnabled,
    dailySuggestionNotificationsEnabled: !!raw.dailySuggestionNotificationsEnabled,
    newMatchNotificationsEnabled: !!raw.newMatchNotificationsEnabled,
    missedInteractionsNotificationsEnabled: !!raw.missedInteractionsNotificationsEnabled,
  } as NotificationSettings;
}


// ### Shared ### //

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
}

export enum MediaType {
  PROFILE = 'profile',
  SELF = 'self',
}

export enum FilePurpose {
  PROFILE = 'profile',
  INTERACTION = 'interaction',
}

export enum UpdateUserMediaErrCode {
  RECLASSIFIED_SELFIE = 'reclassified_selfie',
  RECLASSIFIED_GIF = 'reclassified_gif',
}

export enum LocationPreference {
  WITHIN_STATE = 'within_state',
  UNRESTRCITED = 'unrestricted'
}

export enum UserRole {
  REGULAR = 'regular',
  LOGISTICS_MANAGER = 'logistics_manager',
  ADMIN = 'admin',
}

import * as React from 'react';
import { Box, Container, Grid } from '@mui/material';

import { TaroPassCategoryType } from 'common/src/models/event/taroPass';
import useAppSelector from '../../hooks/useAppSelector';
import { useTaroPasses } from '../../hooks/useResource';
import { selectStateFilterWithDefault } from '../../redux/slices/setting';
import { selectMyUserInfo } from '../../redux/slices/user';

import { Loading, Text } from 'common/src/components/base';
import GroupButton from '../../components/GroupButton';
import TaroPassCard from '../../components/TaroPassCard';
import TaroPassRecordCard from '../../components/TaroPassRecordCard';

interface IProps { }
const TaroPassTab: React.FC<IProps> = () => {
  const taroPassRecords = useTaroPasses();
  const myUserInfo = useAppSelector(selectMyUserInfo);

  const stateFilter = useAppSelector(selectStateFilterWithDefault);

  const effectiveTaroPassRecords = (taroPassRecords || [])
    .filter((record) => !record.expiryTs || record.expiryTs > Date.now())
    .filter((record) => record.published)
    .filter((record) => record.location.state.trim().toLowerCase() === stateFilter);

  const [selectedCategory, setSelectedCategory] = React.useState(TaroPassCategoryType.UNDEFINED);
  const categories = React.useMemo(() => {
    return Array.from(new Set((effectiveTaroPassRecords || []).map((record) => record.category))).sort();
  }, [effectiveTaroPassRecords]);

  React.useEffect(() => {
    if (!categories.includes(selectedCategory) && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [selectedCategory, categories]);

  const displayedTaroPassRecords = effectiveTaroPassRecords.filter((record) => record.category === selectedCategory);


  return (
    <Box>
      {myUserInfo && <TaroPassCard taroId={myUserInfo.user.id} />}
      <Box display='flex' >
        <Text color='primary' size='title' variant='bold' mt={20} mb={10} zIndex={300} >合作商家列表</Text>
      </Box>

      <GroupButton
        items={categories.map((category) => ({
          title: category,
          key: category,
        }))}
        selected={selectedCategory}
        onChange={setSelectedCategory}
      />

      <Grid container columns={1} rowSpacing={20} mt={20}>
        {displayedTaroPassRecords
          .map((taroPass) => (
            <Grid item key={taroPass.id} sm={12} md={6} lg={4} display='flex'>
              <TaroPassRecordCard TaroPassId={taroPass.id} />
            </Grid>
          ))
        }
        {!taroPassRecords && <Container><Loading /></Container>}
      </Grid>
    </Box>
  );
};

export default TaroPassTab;

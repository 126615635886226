import { getEventDateLabel, getHumanReadableDateHourTimeWithWeek, getHumanReadableDateTimeRange } from '../../utils/time';

import { deserializeResourceRef, ResourceRef } from '../shared';
import { Gender } from '../user';
import { Currency, deserializeEventContact, deserializeEventFeePolicy, deserializeEventLocation, EventContact, EventFeePolicy, EventLocation } from './common';
import { deserializeSurveyQuestion, SurveyQuestion } from './survey';
import { deserializeTicketOption, resolveTicketOption, TicketOption } from './ticket';


// ### Main: EventTemplate, EventPayload ### //

export type EventTemplate = {
  id: string;
  published: boolean;
  archived: boolean;
  visible: boolean;

  singlesEvent: boolean;
  category: EventTaskCategory;
  name: string;
  intro: string;
  description: string;
  media: ResourceRef;
  thumbnailMedia: ResourceRef;
  extraMedia: ResourceRef[];
  extraThumbnailMedia: ResourceRef[];
  tags: string[];

  eventFromTs: number;
  eventToTs: number;
  eventTimeZone: string;
  location: EventLocation;
  additionalAdvertisedStates: string[];
  seatMapId: string;

  // TODO(lily): implement task-card payload
  payload: HostedEventPayload;

  createdTs: number;
}

// Hosted event payload

export type HostedEventPayload = {
  hostedEventPayload: {
    screeningType: ScreenType;

    ticketOptions: TicketOption[];
    maxSelectableTicketOptions: number;
    minSelectableTickets: number;
    maxSelectableTickets: number;
    allowMultipleTicketOrders: boolean;
    currency: Currency;

    surveyQuestions: SurveyQuestion[];
    detailsUrl: string;
    feePolicy: EventFeePolicy;
    contact: EventContact;

    registrationFromTs: number;
    registrationToTs: number;
    cancellationHoursBeforeEvent : number;
    cancellationHoursAfterEventConfirmation: number;
    waiver: string;
    vendorId: string;
    visibilitySetting: EventVisibilitySetting;
    discounts: EventDiscount[];
  }
}

export const EVENT_TAGS: Record<string, string> = {
  'Taro原创': 'Taro Original',
  'Taro组队': 'Taro Team Up',
  '第三方活动': '3rd Party',
  '户外': 'Outdoors',
  '运动': 'Sports',
  '轻社交': 'Social',
  '音乐': 'Music',
  '演出': 'Show',
  '美食': 'Food',
  '艺术': 'Art',
  '手作': 'Handcraft',
};

export enum GeoStateCode {
  WA = 'wa',
  CA = 'ca',

  AL = 'al',
  AK = 'ak',
  AZ = 'az',
  AR = 'ar',
  BC = 'bc',
  CO = 'co',
  CT = 'ct',
  DE = 'de',
  FL = 'fl',
  GA = 'ga',
  HI = 'hi',
  ID = 'id',
  IL = 'il',
  IN = 'in',
  IA = 'ia',
  KS = 'ks',
  KY = 'ky',
  LA = 'la',
  ME = 'me',
  MD = 'md',
  MA = 'ma',
  MI = 'mi',
  MN = 'mn',
  MS = 'ms',
  MO = 'mo',
  MT = 'mt',
  NE = 'ne',
  NV = 'nv',
  NH = 'nh',
  NJ = 'nj',
  NM = 'nm',
  NY = 'ny',
  NC = 'nc',
  ND = 'nd',
  OH = 'oh',
  OK = 'ok',
  OR = 'or',
  PA = 'pa',
  RI = 'ri',
  SC = 'sc',
  SD = 'sd',
  TN = 'tn',
  TX = 'tx',
  UT = 'ut',
  VT = 'vt',
  VA = 'va',
  WV = 'wv',
  WI = 'wi',
  WY = 'wy',
}

export const GeoStateName: { [key in GeoStateCode]: string } = {
  [GeoStateCode.WA]: 'Washington',
  [GeoStateCode.CA]: 'California',

  [GeoStateCode.AL]: 'Alabama',
  [GeoStateCode.AK]: 'Alaska',
  [GeoStateCode.AZ]: 'Arizona',
  [GeoStateCode.AR]: 'Arkansas',
  [GeoStateCode.BC]: 'British Columbia',
  [GeoStateCode.CO]: 'Colorado',
  [GeoStateCode.CT]: 'Connecticut',
  [GeoStateCode.DE]: 'Delaware',
  [GeoStateCode.FL]: 'Florida',
  [GeoStateCode.GA]: 'Georgia',
  [GeoStateCode.HI]: 'Hawaii',
  [GeoStateCode.ID]: 'Idaho',
  [GeoStateCode.IL]: 'Illinois',
  [GeoStateCode.IN]: 'Indiana',
  [GeoStateCode.IA]: 'Iowa',
  [GeoStateCode.KS]: 'Kansas',
  [GeoStateCode.KY]: 'Kentucky',
  [GeoStateCode.LA]: 'Louisiana',
  [GeoStateCode.ME]: 'Maine',
  [GeoStateCode.MD]: 'Maryland',
  [GeoStateCode.MA]: 'Massachusetts',
  [GeoStateCode.MI]: 'Michigan',
  [GeoStateCode.MN]: 'Minnesota',
  [GeoStateCode.MS]: 'Mississippi',
  [GeoStateCode.MO]: 'Missouri',
  [GeoStateCode.MT]: 'Montana',
  [GeoStateCode.NE]: 'Nebraska',
  [GeoStateCode.NV]: 'Nevada',
  [GeoStateCode.NH]: 'New Hampshire',
  [GeoStateCode.NJ]: 'New Jersey',
  [GeoStateCode.NM]: 'New Mexico',
  [GeoStateCode.NY]: 'New York',
  [GeoStateCode.NC]: 'North Carolina',
  [GeoStateCode.ND]: 'North Dakota',
  [GeoStateCode.OH]: 'Ohio',
  [GeoStateCode.OK]: 'Oklahoma',
  [GeoStateCode.OR]: 'Oregon',
  [GeoStateCode.PA]: 'Pennsylvania',
  [GeoStateCode.RI]: 'Rhode Island',
  [GeoStateCode.SC]: 'South Carolina',
  [GeoStateCode.SD]: 'South Dakota',
  [GeoStateCode.TN]: 'Tennessee',
  [GeoStateCode.TX]: 'Texas',
  [GeoStateCode.UT]: 'Utah',
  [GeoStateCode.VT]: 'Vermont',
  [GeoStateCode.VA]: 'Virginia',
  [GeoStateCode.WV]: 'West Virginia',
  [GeoStateCode.WI]: 'Wisconsin',
  [GeoStateCode.WY]: 'Wyoming',
};

export const WILDCARD_STATE = '*';

export const ADDITIONAL_ADVERTISED_STATES = [
  WILDCARD_STATE,
  ...Object.values(GeoStateCode),
];

export enum EventTaskCategory {
  HOSTED_EVENT = 'hosted_event',
  TASK_CARD = 'task_card'
}

export enum ScreenType {
  FIRST_COME_FIRST_SERVE = 'first_come_first_serve',
  BY_SELECTION = 'by_selection'
}

export enum EventContextFilter {
  APP_ONLY = 'app_only',
  WEB_ONLY = 'web_only',
  ALL = 'all',
}

export type EventVisibilitySetting = {
  visibleInApp : boolean;
  visibleOnWeb : boolean;
}

export type EventDiscount = {
  couponCode: string;
  automatic: boolean;
  discountQualifier: EventDiscountQualifier;
  discountValue: EventDiscountValue;
}

export type EventDiscountQualifier = {
  ticketOptionIds: string[];
  clientTypes: string[];
  minTicketsInOrder: number;
}

export type EventDiscountValue = {
  unitAdjustmentInCents: number;
  percentAdjustment: number;
  adjustedUnitCostInCents: number;
}

export function deserializeEventDiscount(raw: any) {
  return {
    couponCode: raw.couponCode,
    automatic: !!raw.automatic,
    discountQualifier: {
      ticketOptionIds: raw.discountQualifier.ticketOptionIds,
      clientTypes: raw.discountQualifier.clientTypes,
      minTicketsInOrder: +raw.discountQualifier.minTicketsInOrder,
    },
    discountValue: {
      unitAdjustmentInCents: +raw.discountValue.unitAdjustmentInCents,
      percentAdjustment: +raw.discountValue.percentAdjustment,
      adjustedUnitCostInCents: +raw.discountValue.adjustedUnitCostInCents,
    },
  } as EventDiscount;
}

export function deserializeEventTemplate(raw: any) {
  return {
    id: raw.id,
    published: raw.published,
    archived: raw.archived,
    visible: raw.visible,

    singlesEvent: raw.singlesEvent,
    category: raw.category as EventTaskCategory,
    name: raw.name,
    intro: raw.intro,
    description: raw.description,
    media: deserializeResourceRef(raw.media),
    thumbnailMedia: deserializeResourceRef(raw.thumbnailMedia),
    extraMedia: raw.extraMedia.map(deserializeResourceRef),
    extraThumbnailMedia: raw.extraThumbnailMedia.map(deserializeResourceRef),
    tags: raw.tags,

    location: deserializeEventLocation(raw.location),
    additionalAdvertisedStates: raw.additionalAdvertisedStates,
    seatMapId: raw.seatMapId,
    createdTs: raw.createdTs,
    eventFromTs: +raw.eventFromTs,
    eventToTs: +raw.eventToTs,
    eventTimeZone: raw.eventTimeZone,
    payload: deserializeEventPayLoad(raw.payload.hostedEventPayload),
  } as EventTemplate;
}

export function deserializeEventPayLoad(raw: any) {
  return {
    hostedEventPayload: {
      screeningType: raw.screeningType as ScreenType,

      ticketOptions: raw.ticketOptions.map((ticketOption: any) => deserializeTicketOption(ticketOption)),
      maxSelectableTicketOptions: +raw.maxSelectableTicketOptions,
      minSelectableTickets: +raw.minSelectableTickets,
      maxSelectableTickets: +raw.maxSelectableTickets,
      allowMultipleTicketOrders: !!raw.allowMultipleTicketOrders,
      currency: raw.currency as Currency,

      surveyQuestions: raw.surveyQuestions.map((question: any) => deserializeSurveyQuestion(question)),
      detailsUrl: raw.detailsUrl,
      feePolicy: deserializeEventFeePolicy(raw.feePolicy),
      contact: deserializeEventContact(raw.contact),

      registrationFromTs: +raw.registrationFromTs,
      registrationToTs: +raw.registrationToTs,
      cancellationHoursBeforeEvent: +raw.cancellationHoursBeforeEvent,
      cancellationHoursAfterEventConfirmation: +raw.cancellationHoursAfterEventConfirmation,
      waiver: raw.waiver,
      vendorId: raw.vendorId ? raw.vendorId : '',
      visibilitySetting: raw.visibilitySetting,

      discounts: raw.discounts.map(deserializeEventDiscount),
    },
  } as HostedEventPayload;
}

export function getCostInCents(eventTemplate: EventTemplate, ticketOptionId: string, gender: Gender) {
  const ticketOption = resolveTicketOption(eventTemplate, ticketOptionId);

  if (eventTemplate.singlesEvent) {
    switch (gender) {
      case Gender.MALE:
        return ticketOption.maleCostInCents;
      case Gender.FEMALE:
        return ticketOption.femaleCostInCents;
      default:
        throw new Error('BUG: unexpected gender for single event: ' + gender);
    }
  } else {
    return ticketOption.genericCostInCents;
  }
}

export function getFullRefundDate(eventTemplate: EventTemplate, eventConfirmationTs: number) {
  const cancellationHoursAfterEventConfirmation = eventTemplate.payload.hostedEventPayload.cancellationHoursAfterEventConfirmation;
  const cancellationHoursBeforeEvent = eventTemplate.payload.hostedEventPayload.cancellationHoursBeforeEvent;

  const tsAfterEventConfirmation = eventConfirmationTs + cancellationHoursAfterEventConfirmation * 3600000;
  const tsBeforeEvent = eventTemplate.eventFromTs - cancellationHoursBeforeEvent * 3600000;
  const effectiveTs = Math.min(tsAfterEventConfirmation, tsBeforeEvent);
  return getHumanReadableDateHourTimeWithWeek(effectiveTs, eventTemplate.eventTimeZone, true);
}

export function shouldHideEventDate(eventTemplate: EventTemplate): boolean {
  return ['2024_ca_workshop_2', '2024_ca_makeover_wa'].includes(eventTemplate.id) || !!eventTemplate.id.match(/\d{4}_.{2}_perm_.*/);
}

export function getEventDateLabelWithMask(eventTemplate: EventTemplate, showTime: boolean): string {
  if (shouldHideEventDate(eventTemplate)) {
    return '';
  }

  return Date.now() > eventTemplate.payload.hostedEventPayload.registrationFromTs ?
    (showTime ?
      getHumanReadableDateTimeRange(eventTemplate.eventFromTs, eventTemplate.eventToTs, eventTemplate.eventTimeZone) :
      getEventDateLabel(eventTemplate.eventFromTs, eventTemplate.eventToTs, eventTemplate.eventTimeZone)):
    '即将上线';
}

// ### SurveyQuestion ### //

export type SurveyQuestion = {
  id: string;
  question: string;
  type: QuestionType;
  choices: SurveyQuestionChoice[]
}

export type SurveyQuestionChoice = {
  id: string;
  text: string;
}

export enum QuestionType {
  FREEFORM = 'freeform',
  SELECT = 'select'
}

export function deserializeSurveyQuestion(raw: any) {
  return {
    id: raw.id,
    question: raw.question,
    type: raw.type as QuestionType,
    choices: raw.choices.map((choice: any) => deserializeSurveyQuestionChoice(choice)),
  } as SurveyQuestion;
}

export function deserializeSurveyQuestionChoice(raw: any) {
  return {
    id: raw.id,
    text: raw.text,
  } as SurveyQuestionChoice;
}


// ### SurveyResponse ### //

export type SurveyResponse = {
  id: string;
  response: string;
}

export function deserializeSurveyResponse(raw: any) {
  return {
    id: raw.id,
    response: raw.response,
  } as SurveyResponse;
}

import React from 'react';

import { EventTemplate } from 'common/src/models/event';
import {
  refreshEventTemplate, refreshEventTemplateGroups, refreshEventTemplates,
  refreshEventTicketInventory, refreshSelectEventTemplates, refreshTaroPasses, refreshTicketOrders, refreshVendors,
  selectAllEventTemplates, selectAllEventTicketInventoriesByTemplate, selectAllTaroPasses,
  selectAllVendors, selectOptEventTemplate, selectOptEventTemplateGroup,
  selectOptEventTicketInventoryByTemplate, selectOptTicketOrder, selectTaroPass, selectTicketOrders,
} from '../redux/slices/event';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

const useEventTemplate = (eventTemplateId: string, forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const eventTemplate = useAppSelector((store) => selectOptEventTemplate(store, eventTemplateId));
  React.useEffect(() => {
    dispatch(refreshEventTemplate({ eventTemplateId: eventTemplateId, onlyIfUncached: !forceRefresh }));
  }, [dispatch, eventTemplateId, forceRefresh]);

  return eventTemplate;
};

const useSelectEventTemplates = (eventTemplateIds: string[], forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const eventTemplates = useAppSelector((store) => {
    const eventTemplatesMap: Record<string, EventTemplate|null> = {};
    for (const eventTemplateId of eventTemplateIds) {
      eventTemplatesMap[eventTemplateId] = selectOptEventTemplate(store, eventTemplateId);
    }
    return eventTemplatesMap;
  });
  React.useEffect(() => {
    dispatch(refreshSelectEventTemplates({ eventTemplateIds: eventTemplateIds, onlyIfUncached: !forceRefresh }));
  }, [dispatch, eventTemplateIds, forceRefresh]);

  return eventTemplates;
};


const useEventTemplates = (includePastEvents: boolean, forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const eventTemplates = useAppSelector(selectAllEventTemplates);
  React.useEffect(() => {
    dispatch(refreshEventTemplates({ includePastEvents: includePastEvents, onlyIfUncached: !forceRefresh }));
  }, [dispatch, includePastEvents, forceRefresh]);

  return eventTemplates;
};

const useEventTemplateGroup = (eventTemplateGroupId: string, forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const eventTemplateGroup = useAppSelector((store) => selectOptEventTemplateGroup(store, eventTemplateGroupId));
  React.useEffect(() => {
    dispatch(refreshEventTemplateGroups({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return eventTemplateGroup;
};

const useTaroPass = (taroPassId: string, forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const taroPass = useAppSelector((store) => selectTaroPass(store, taroPassId));
  React.useEffect(() => {
    dispatch(refreshTaroPasses({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return taroPass;
};


const useTaroPasses = (forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const taroPasses = useAppSelector(selectAllTaroPasses);
  React.useEffect(() => {
    dispatch(refreshTaroPasses({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return taroPasses;
};

const useVendors = (forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const vendors = useAppSelector(selectAllVendors);
  React.useEffect(() => {
    dispatch(refreshVendors({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return vendors;
};

const useTicketOrder = (ticketOrderId: string, forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const ticketOrder = useAppSelector((state) => selectOptTicketOrder(state, ticketOrderId));
  React.useEffect(() => {
    dispatch(refreshTicketOrders({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return ticketOrder;
};

const useTicketOrders = (forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const ticketOrders = useAppSelector(selectTicketOrders);
  React.useEffect(() => {
    dispatch(refreshTicketOrders({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return ticketOrders;
};

const useEventTicketInventory = (eventTemplateId: string, forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const eventTicketInventory = useAppSelector(
    (store) => selectOptEventTicketInventoryByTemplate(store, eventTemplateId));
  React.useEffect(() => {
    dispatch(refreshEventTicketInventory({
      onlyIfUncached: !forceRefresh, optEventTemplateId: eventTemplateId,
    }));
  }, [dispatch, forceRefresh, eventTemplateId]);

  return eventTicketInventory;
};

const useEventTicketInventories = (forceRefresh?: boolean) => {
  const dispatch = useAppDispatch();

  const eventTicketInventories = useAppSelector(
    (store) => selectAllEventTicketInventoriesByTemplate(store));
  React.useEffect(() => {
    dispatch(refreshEventTicketInventory({ onlyIfUncached: !forceRefresh }));
  }, [dispatch, forceRefresh]);

  return eventTicketInventories;
};

export {
  useEventTemplate, useSelectEventTemplates, useEventTemplates, useEventTemplateGroup, useEventTicketInventories,
  useEventTicketInventory, useTicketOrder, useTicketOrders, useTaroPass, useTaroPasses, useVendors,
};

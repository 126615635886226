import { EventTemplate } from './eventTemplate';
import { EventTicketInventory, TicketOrder, TicketOrderStatus } from './ticket';

const NEW_EVENT_THRESHOLD_MILLIS = 3 * 24 * 60 * 60 * 1000;

export function canCancelTicketOrder(ticketOrder: TicketOrder, eventTemplate: EventTemplate) {
  const cancellationHoursBeforeEvent = eventTemplate.payload.hostedEventPayload.cancellationHoursBeforeEvent;
  const cancellationHoursAfterEventConfirmation = eventTemplate.payload.hostedEventPayload.cancellationHoursAfterEventConfirmation;
  switch (ticketOrder.status) {
    case TicketOrderStatus.PENDING:
    case TicketOrderStatus.WAITLIST:
    case TicketOrderStatus.CONFIRMING:
    case TicketOrderStatus.ERROR:
      return true;
    case TicketOrderStatus.CONFIRMED: {
      const now = Date.now();
      if (eventTemplate.eventFromTs - cancellationHoursBeforeEvent * 60 * 60 * 1000 < now) {
        return false;
      }
      if (ticketOrder.confirmationTs + cancellationHoursAfterEventConfirmation * 60 * 60 * 1000 < now) {
        return false;
      }
      return true;
    }
    default:
      return false;
  }
}

export function extractEventTemplateMetadata(
  eventTemplate: EventTemplate,
  eventTicketInventory: EventTicketInventory | null,
): {
    badgeKey: string, // can be ''
    isCurrent: boolean,
    isNew: boolean
  } {
  const now = Date.now();
  const payload = eventTemplate.payload.hostedEventPayload;

  let badgeKey = '';
  const isRegisterStart = now >= payload.registrationFromTs;
  // Do not show event badge for singles events as it's not accurate without knowing the user's gender
  if (!eventTemplate.singlesEvent && isRegisterStart && eventTicketInventory && !eventTemplate.singlesEvent) {
    const ticketQuantity = Object.values(eventTicketInventory.ticketOptionToTicketsRemaining);

    const lowQuantity = ticketQuantity.every((ticketRemaining) => ticketRemaining <= 3) ||
    ticketQuantity.some((ticketRemaining) => ticketRemaining <= 0);

    if (ticketQuantity.every((ticketRemaining) => ticketRemaining <= 0)) {
      badgeKey = 'details.eventFull';
    } else if (lowQuantity) {
      badgeKey = 'details.limitedTicketsRemaining';
    }
  }

  const isCurrent = now <= payload.registrationToTs;
  const registrationFromTs = payload.registrationFromTs;
  const isNew = registrationFromTs <= now && now <= registrationFromTs + NEW_EVENT_THRESHOLD_MILLIS;
  return { badgeKey, isCurrent, isNew };
}

import * as React from 'react';
import { Box } from '@mui/material';

import { NotifierContext } from '../../context/notifier';

import Spinner from './Spinner';

interface IProps {
  children: React.ReactNode;
}
const Notifier: React.FC<IProps> = ({ children }) => {
  const { spinnerMsg } = React.useContext(NotifierContext);

  const spinnerView = spinnerMsg ? <Spinner message={spinnerMsg} /> : null;

  return (
    <Box>
      {children}
      {spinnerView}
    </Box>
  );
};

export default Notifier;

import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Card, CardActionArea, CardContent, CardMedia, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { EventLocation } from 'common/src/models/event';
import { useTaroPass } from '../hooks/useResource';

import { Text } from 'common/src/components/base';

interface IProps {
  TaroPassId: string
}

const TaroPassRecordCard: React.FC<IProps> = ({ TaroPassId }) => {
  const taroPass = useTaroPass(TaroPassId);
  const [expanded, setExpanded] = React.useState(false);

  if (!taroPass) {
    return null;
  }

  const getLocationString = (location: EventLocation) => {
    let addressStr = '';
    if (location.address) {
      addressStr += (location.address + ', ');
    }
    if (location.city) {
      addressStr += (location.city + ' ');
    }
    if (location.state) {
      addressStr += (location.state + ', ');
    }
    if (location.zipcode) {
      addressStr += (location.zipcode + ' ');
    }
    if (location.addressNotes) {
      if (addressStr) {
        addressStr += ('(' + location.addressNotes + ')');
      } else {
        addressStr = location.addressNotes;
      }
    }
    return addressStr;
  };

  const imgDim = expanded ? 125 : 85;

  return (
    <Card sx={{
      display: 'flex', flexDirection: 'column', /* ensure the card is stretched vertically */
      flexGrow: 1, /* ensure the card is stretched horizontally */
    }}>
      <CardActionArea onClick={() => setExpanded(!expanded)} sx={{
        bgcolor: Colors.DEFAULT_BACKGROUND,
        display: 'flex', flexDirection: 'column', flexGrow: 1,
        justifyContent: 'flex-start', alignItems: 'flex-start',
      }}>

        <CardContent>
          <Stack direction='row' spacing={10} >
            {
              taroPass.refMedia.downloadUrl ? (
                <CardMedia
                  component='img'
                  image={taroPass.refMedia.downloadUrl}
                  alt={taroPass.refMedia.id}
                  loading='lazy'
                  sx={{ height: imgDim, width: imgDim, marginRight: 10, borderRadius: 5 }}
                />
              ) : (
                <Text size='subtitle' color='primary' centered pt={25}>IMG Placeholder</Text>
              )
            }
            <Box flex={1} pt={5} mr={20} >
              <Text
                color='primary'
                maxLines={1}
                size={expanded ? 'title' : 'subtitle'}
                variant={expanded ? 'bold' : undefined}
              >
                {taroPass.name}
              </Text>
              <Text
                color={Colors.ACTION_DARK}
                size={expanded ? 'subtitle' : 'paragraph'}
                maxLines={expanded ? 3 : 2}
                py={expanded ? 15 : 5}
              >
                {taroPass.detail}
              </Text>
            </Box>
            <Box sx={{
              top: 10,
              right: 10,
              position: 'absolute',
            }}>
              {!expanded && <KeyboardArrowDownIcon color='primary' />}
              {expanded && <KeyboardArrowUpIcon color='primary' />}
            </Box>
          </Stack>
          <Stack>
            {expanded &&
              <Box mx={5} mt={10}>
                <Box>
                  <Text color='system' size='paragraph' variant='bold' mb={5}>介绍：</Text>
                  <Text color='system' size='paragraph'>{taroPass.intro}</Text>
                </Box>
                <Box mt={15}>
                  <Text color='system' size='paragraph' variant='bold' mb={5}>地址：</Text>
                  <Text color='system' size='paragraph'>{getLocationString(taroPass.location)}</Text>
                </Box>
              </Box>
            }

          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TaroPassRecordCard;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  AppBar, Box, Button, ButtonBase, CardMedia, Container, IconButton, Link, ListItem, Stack,
} from '@mui/material';
import QRCode from 'qrcode.react';

import { Colors } from 'common/src/constants';
import useNotifier from 'common/src/hooks/useNotifier';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import {
  EventContact, EventContactType, getSelectedTicketOptions, getTicketOrderStatusLabel,
  PaymentInfo, resolveTicketOption, TicketOrderStatus,
} from 'common/src/models/event';
import { shouldHideEventDate } from 'common/src/models/event/eventTemplate';
import { getCostAggregation } from 'common/src/models/event/ticket';
import { canCancelTicketOrder } from 'common/src/models/event/util';
import { getPaymentInfoRemote } from 'common/src/system/network/event';
import { getHumanReadableDateTimeRange } from 'common/src/utils/time';
import { DefaultStyleAttrs } from '../../constants/styles';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { useEventTemplate, useTicketOrder } from '../../hooks/useResource';
import { cancelTickets } from '../../redux/slices/event';
import { selectMyUserInfo } from '../../redux/slices/user';

import { Page, Text } from 'common/src/components/base';
import ActionModal from '../../components/ActionModal';
import CancellationPolicy from '../../components/CancellationPolicy';

export default function ViewOrderDetailsScreen() {
  const { ticketOrderId } = useParams();
  const { t } = useTranslation('wbevt.events');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const notifier = useNotifier();
  const { windowHeight } = useWindowDimensions();

  if (!ticketOrderId) {
    throw new Error('BUG: ticketOrderId missing for ViewOrderDetailsScreen');
  }

  const ticketOrder = useTicketOrder(ticketOrderId);
  const eventTemplateId = ticketOrder ? ticketOrder.eventTemplateId : '';
  const eventTemplate = useEventTemplate(eventTemplateId);
  const myUserInfo = useAppSelector(selectMyUserInfo);

  const [paymentInfo, setPaymentInfo] = React.useState<PaymentInfo|null>(null);
  const [showCancellationModal, setShowCancellationModal] = React.useState(false);
  const [saveQR, setSaveQR] = React.useState(false);
  React.useEffect(() => {
    if (!paymentInfo && ticketOrder && ticketOrder.costDetails.finalCostInCents !== 0) {
      getPaymentInfoRemote(ticketOrder.id).then(setPaymentInfo);
    }
  }, [paymentInfo, ticketOrder]);

  const closeCancellationModal = React.useCallback(() => setShowCancellationModal(false), []);

  if (!eventTemplate || !ticketOrder || !myUserInfo) {
    return null;
  }

  const statusLabel = getTicketOrderStatusLabel(ticketOrder);
  const canCancel = canCancelTicketOrder(ticketOrder, eventTemplate);

  const isConfirmed = ticketOrder.status === TicketOrderStatus.CONFIRMED || ticketOrder.status === TicketOrderStatus.CONFIRMING;
  const eventContact = eventTemplate.payload.hostedEventPayload.contact;

  const cancellationModal = (
    <ActionModal
      visible={showCancellationModal}
      title={t('registrationResult.cancelRegistration')}
      primaryBtnLabel={canCancel ? t('registrationResult.confirmCancellation') : t('registrationResult.ack')}
      onPrimaryBtnPress={canCancel ? async () => {
        notifier.showSpinner({ type: 'loading' });
        setShowCancellationModal(false);
        await dispatch(cancelTickets({
          ticketOrderId: ticketOrderId,
          ticketIds: ticketOrder.tickets.map((ticket) => ticket.id),
        }));
        notifier.hideSpinner();
      } : closeCancellationModal}
      secondaryBtnLabel={canCancel ? t('registrationResult.doNotCancel') : undefined }
      onSecondaryBtnPress={closeCancellationModal}
      onClose={()=>setShowCancellationModal(false)}
      onOverlayPress={()=>setShowCancellationModal(false)}
    >
      <Box>
        <Box mt={30} mb={15}>
          <CancellationPolicy eventTemplate={eventTemplate} ticketOrder={ticketOrder} variant='inline' />
        </Box>
        <Text size='paragraph' mb={30}>
          {canCancel ? t('registrationResult.confirmCancellationPrompt') : t('registrationResult.cancellationWindowExpiredPrompt')}
        </Text>
      </Box>
    </ActionModal>
  );

  const downloadModal = saveQR && (
    <ActionModal
      title={t('registrationResult.joinWechatGroupTitle')}
      description={t('registrationResult.joinWechatGroupDescription')}
      primaryBtnLabel={t('registrationResult.ack')}
      onPrimaryBtnPress={() => {
        setSaveQR(false);
      }}
    >
      <Box display='flex' justifyContent='center' mt={20}>
        <img src={eventContact.refMedia.downloadUrl} width='400' />
      </Box>
    </ActionModal>
  );

  const formatContact = (eventContact: EventContact) => {
    let result = eventContact.name + ' (';
    switch (eventContact.type) {
      case EventContactType.WECHAT:
        result = result+ t('registrationResult.wechat') + ': ';
        break;
      case EventContactType.PHONE:
        result = result+ t('registrationResult.phone') + ': ';
        break;
    }
    return result + eventContact.principal + ')';
  };

  const refMedia = eventTemplate.payload.hostedEventPayload.contact.refMedia;

  const loc = eventTemplate.location;
  const locationLabel = (
    <Text color='system' fontSize={15} lineHeight={1.3}>
      {
        (loc.summary ? loc.summary + '\n' : '') +
        loc.address + ', ' + loc.city + ', ' + loc.state + ' ' + loc.zipcode
      }
    </Text>
  );

  const costAggregation = getCostAggregation(Object.values(ticketOrder.costDetails.ticketIdToCost));
  const showDate = !shouldHideEventDate(eventTemplate);
  return (
    <Page>
      <Container>
        <AppBar position='fixed' color='transparent'>
          <Box px={DefaultStyleAttrs.mx}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <IconButton onClick={() => navigate('/')}>
                <KeyboardBackspaceIcon sx={{ color: Colors.TEXT_SYSTEM, fontSize: 30 }} />
              </IconButton>
              <Text size='subtitle' color='system'>{statusLabel.statusLabelIcon} {t(statusLabel.statusLabelTextKey)}</Text>
              <Box />
            </Stack>
          </Box>
        </AppBar>

        <Box
          mt={50} height={windowHeight-50} py={20} overflow='auto'
          bgcolor={Colors.DEFAULT_BACKGROUND}
        >
          <Container>
            <Box mx={DefaultStyleAttrs.responsiveMx}>
              <Stack direction='row'>
                <Box borderRadius={3} width={280}>
                  <CardMedia
                    component='img'
                    height={120}
                    width={280}
                    image={eventTemplate.media.downloadUrl}
                    alt={eventTemplate.media.id}
                  />
                </Box>
                <Box ml={15}>
                  <Text size='title' variant='bold'>{eventTemplate.name}</Text>
                  <Text size='subtitle' variant='italics' mb={5} maxLines={1}>{eventTemplate.intro}</Text>
                </Box>
              </Stack>

              <Box bgcolor='#E8E7EA' mt={30} p={20} pt={40}>
                <Box>
                  <Stack direction='row' alignItems='center'>
                    <Text size='subtitle' color='primary' mr={7}>{t('registrationResult.orderId')}:</Text>
                    <Text fontSize={15} color='system'>{ticketOrderId}</Text>
                  </Stack>

                  <Stack direction='row' mt={20} alignItems='center'>
                    <Text size='subtitle' color='primary'>{t('registrationResult.registrationStatus')}: </Text>
                    <Box ml={10} bgcolor={statusLabel.statusLabelColor} px={13} py={4} borderRadius={5}>
                      <Text size='paragraph'>{t(statusLabel.statusLabelTextKey)}</Text>
                    </Box>
                  </Stack>
                  <Text size='note' variant='italics' color='system'>{t(statusLabel.statusDescriptionKey)}</Text>

                  <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.eventLocation')}: </Text>
                  {eventTemplate.location.latlng ? (
                    <Link href={'https://www.google.com/maps/search/?api=1&query=' + loc.latlng}>
                      {locationLabel}
                    </Link>
                  ) : locationLabel}

                  {showDate && (
                    <Box>
                      <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.eventTime')}: </Text>
                      <Text fontSize={15} color='system'>
                        {getHumanReadableDateTimeRange(
                          eventTemplate.eventFromTs, eventTemplate.eventToTs, eventTemplate.eventTimeZone)}
                      </Text>
                    </Box>
                  )}

                  <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.ticketSummary')}: </Text>
                  {getSelectedTicketOptions(ticketOrder).map((selectedTicketOption, idx) => {
                    if (!selectedTicketOption.quantity) {
                      return null;
                    }
                    const ticketOption = resolveTicketOption(eventTemplate, selectedTicketOption.id);
                    return (
                      <ListItem key={'o-' + idx} sx={{ display: 'list-item', py: 0 }}>
                        <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                          {ticketOption.name} x{selectedTicketOption.quantity} {selectedTicketOption.seatId ? `(${t('registrationResult.seatLabel')}: ${selectedTicketOption.seatId})` : ''}
                        </Text>
                      </ListItem>
                    );
                  })}

                  <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.cost')}: </Text>
                  <ListItem sx={{ display: 'list-item', py: 0, lineHeight: 1 }}>
                    <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                        Subtotal: ${(costAggregation.subtotalInCents/100).toFixed(2)}
                    </Text>
                  </ListItem>

                  {costAggregation.costAdjustors.map((costAdjustor, idx) => {
                    return (
                      <ListItem key={'d-' + idx} sx={{ display: 'list-item', py: 0, lineHeight: 1 }}>
                        <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                          {t('registrationResult.discount')} ({costAdjustor.adjustorName}): -${(-costAdjustor.adjustmentInCents/100).toFixed(2)}
                        </Text>
                      </ListItem>
                    );
                  })}

                  {costAggregation.taxInCents > 0 && (
                    <ListItem sx={{ display: 'list-item', py: 0, lineHeight: 1 }}>
                      <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                        {t('registrationResult.tax')}: ${(costAggregation.taxInCents/100).toFixed(2)}
                      </Text>
                    </ListItem>
                  )}

                  <ListItem sx={{ display: 'list-item', py: 0, lineHeight: 1 }}>
                    <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                      {t('registrationResult.fee')}: ${(costAggregation.feeInCents/100).toFixed(2)}
                    </Text>
                  </ListItem>

                  <ListItem sx={{ display: 'list-item', py: 0 }}>
                    <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                        Total: ${(costAggregation.finalCostInCents/100).toFixed(2)}
                    </Text>
                  </ListItem>

                  {paymentInfo && paymentInfo.last4Digits && (
                    <Box>
                      <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.paymentInfo')}: </Text>
                      <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                        {t('registrationResult.paymentCreditCard')} ****{paymentInfo.last4Digits}
                      </Text>
                    </Box>
                  )}

                  <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.contact')}:</Text>
                  <Text fontSize={15} color='system' sx={{ display: 'inline-block' }}>
                    {formatContact(eventTemplate.payload.hostedEventPayload.contact)}
                  </Text>

                  {refMedia && refMedia.downloadUrl !== '' && isConfirmed && (
                    <Box>
                      <Text size='subtitle' color='primary' mt={20}>{t('registrationResult.wechatGroup')}: </Text>
                      <ButtonBase onClick={() => setSaveQR(true)}>
                        <img src={eventContact.refMedia.downloadUrl} width='250' />
                      </ButtonBase>
                    </Box>
                  )}
                </Box>
              </Box>

              {
                isConfirmed && (
                  <Box bgcolor='#E8E7EA' mt={30} p={20}>
                    <Text size='paragraph' variant='italics' color='system'>{t('registrationResult.checkinQRCodeExplanation')}</Text>
                    {
                      ticketOrder.tickets.map((ticket, idx) => {
                        const ticketOption = resolveTicketOption(eventTemplate, ticket.ticketOptionId);
                        return (
                          <Box key={idx} mt={40}>
                            <QRCode value={`${ticketOrderId}-${ticket.id}`} size={150} />
                            <Text fontSize={15} color='system' mt={10}>
                              {t('registrationResult.ticket')}{idx+1}: {ticketOption.name} {ticket.seatId ? ` (${t('registrationResult.seatLabel')}: ${ticket.seatId})` : ''}
                            </Text>
                          </Box>
                        );
                      })
                    }
                  </Box>
                )
              }

              {
                ticketOrder.status !== TicketOrderStatus.CANCELLED && ticketOrder.status !== TicketOrderStatus.INIT && (
                  <CancellationPolicy
                    eventTemplate={eventTemplate}
                    ticketOrder={ticketOrder}
                  />
                )
              }

              <Box display='flex' justifyContent='center' mt={30}>
                <Stack direction='column' spacing={2} alignItems='center'>
                  <Button
                    size='large' variant='contained'
                    sx={{ borderRadius: DefaultStyleAttrs.borderRadius, px: 70 }}
                    onClick={() => navigate('/myevents')}
                  >
                    <Text>{t('myEvent.myEvent')}</Text>
                  </Button>

                  {ticketOrder.status !== TicketOrderStatus.CANCELLED && ticketOrder.status !== TicketOrderStatus.INIT && (
                    <Button size='large' variant='text' onClick={() => setShowCancellationModal(true)}>
                      <Text variant='underline'>{t('myEvent.cancelRegistration')}</Text>
                    </Button>
                  )}
                </Stack>
              </Box>
            </Box>
          </Container>
        </Box>
      </Container>
      {cancellationModal}
      {downloadModal}
    </Page>
  );
}

import * as React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import loadingLottie from '../../assets/lottie/loading-circles.json';

interface IProps {
  size?: number;
}
const Loading: React.FC<IProps> = ({ size }) => {
  return (
    <Player autoplay loop src={loadingLottie} style={{ height: size || 200 }} />
  );
};

export default Loading;

import * as React from 'react';

import { NotifierContext } from '../context/notifier';

// Usage:
//   const Example = () => {
//     const notifier = useNotifier();
//     return (
//       <Button onPress={() => notifier.showToast({ msg: "Hello world", durationMs: 3000})} />
//     );
//   };
export default () => {
  const {
    setSpinner, clearSpinner,
  } = React.useContext(NotifierContext);

  const notifier = React.useMemo(
    () => ({
      showSpinner: setSpinner,
      hideSpinner: clearSpinner,
    }),
    [setSpinner, clearSpinner],
  );

  return notifier;
};

import * as React from 'react';
import { Button, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';

import { Text } from 'common/src/components/base';

export interface IGroupButtonItem {
  title: string;
  key: any;
}

interface IProps {
  items: IGroupButtonItem[];
  selected: string;
  onChange: (key: any) => void
}

const GroupButton: React.FC<IProps> = ({ items, selected, onChange }) => {
  return (
    <Stack direction='row'>
      {items.map((item) => {
        const active = selected === item.key;
        return (
          <Button
            key={item.key}
            onClick={() => onChange(item.key)}
            sx={{
              height: 32,
              width: 15,
              backgroundColor: active ? Colors.PRIMARY : Colors.PRIMARY_BACKGROUND,
              borderColor: active ? Colors.PRIMARY_LIGHT : Colors.PRIMARY_BACKGROUND,
              borderRadius: 20,
              borderWidth: 1,
              mr: 10,
            }}
          >
            <Text
              size='subtitle'
              color={active ? Colors.WHITE : Colors.PRIMARY}
              fontWeight={active ? 'bold' : undefined}
            >
              {item.title}
            </Text>
          </Button>
        );
      })}
    </Stack>
  );
};

export default GroupButton;

export type ResourceRef = {
  id: string;
  downloadUrl: string;
}

export function deserializeResourceRef(raw: any) {
  return {
    id: raw.id,
    downloadUrl: raw.downloadUrl,
  } as ResourceRef;
}

// Checks if the two resource refs are equivalent (not necessarily equal)
// In this case, it does not compare the downloadUrl, which isn't stable.
export function resourceRefEquivalent(ref1: ResourceRef, ref2: ResourceRef): boolean {
  return ref1.id === ref2.id;
}

export enum ClientType {
  WEB = 'web',
  NATIVE = 'native'
}

import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { EventTemplate, getFullRefundDate } from 'common/src/models/event';

import { Text } from 'common/src/components/base';

interface IProps {
  eventTemplate: EventTemplate;
  ticketOrderConfirmationTs: number;
}
const CustomCancellationPolicy: React.FC<IProps> = ({ eventTemplate, ticketOrderConfirmationTs }) => {
  const { t } = useTranslation('wbevt.events');

  return (
    <Box display='flex' flexWrap='wrap'>
      <Text color='system' size='paragraph'>{
        <Trans
          t={t}
          i18nKey='cancellation.customPolicyContent'
          values={{ fullRefundDate: getFullRefundDate(eventTemplate, ticketOrderConfirmationTs) }}
        />
      }</Text>
    </Box>
  );
};

export default CustomCancellationPolicy;

import * as React from 'react';
import { Backdrop, Box, CircularProgress } from '@mui/material';

import { Text } from '../base';

interface IProps {
  message: string;
}
const Spinner: React.FC<IProps> = ({ message }) => {
  return (
    <Backdrop
      sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />
        <Text color='system' variant='bold'>{message}</Text>
      </Box>
    </Backdrop>
  );
};

export default Spinner;

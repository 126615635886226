import Env from '../env';

class StorageWrapper {
  public setItem(key: string, content: string): void {
    const effectiveKey = this.getEffectiveKey(key);
    return localStorage.setItem(effectiveKey, content);
  }

  public getItem(key: string): string | null {
    const effectiveKey = this.getEffectiveKey(key);
    return localStorage.getItem(effectiveKey);
  }

  public removeItem(key: string): void {
    const effectiveKey = this.getEffectiveKey(key);
    return localStorage.removeItem(effectiveKey);
  }

  private getEffectiveKey(key: string): string {
    return `${Env.getStack().toString().toUpperCase()}__${key}`;
  }
}

export default new StorageWrapper();

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';

import { Colors } from 'common/src/constants';
import { Location } from 'common/src/models/user';
import { DefaultStyleAttrs } from '../constants/styles';
import useAppDispatch from '../hooks/useAppDispatch';
import useAppSelector from '../hooks/useAppSelector';
import { selectMyUserInfo, updateEmail, updateProfile } from '../redux/slices/user';

import { Page, Text } from 'common/src/components/base';
import MainBackground from '../components/MainBackground';
import TopMenu from '../components/TopMenu';

const emailSchema = Yup.string().email();

export default function ProfileScreen() {
  const { t } = useTranslation('wbevt.profile');

  const dispatch = useAppDispatch();
  const myUserInfo = useAppSelector(selectMyUserInfo);

  const [saving, setSaving] = React.useState(false);
  const [email, setEmail] = React.useState(myUserInfo ? myUserInfo.contact.email : '');
  const [name, setName] = React.useState(myUserInfo ? myUserInfo.user.userProfile.coreUserInfo.name : '');
  const [location, setLocation] = React.useState(myUserInfo ? myUserInfo.user.userProfile.coreUserInfo.location : {} as Location);

  const [errMsg, setErrMsg] = React.useState('');

  const navigate = useNavigate();

  const handleSave = React.useCallback(async () => {
    if (!myUserInfo) {
      // This should never happen but added to satisfy typecheck
      return;
    }

    // Validation
    try {
      emailSchema.validateSync(email);
    } catch (error) {
      setErrMsg('Email is invalid');
      return;
    }

    // Save
    setSaving(true);
    const promises: Promise<any>[] = [];

    if (email !== myUserInfo.contact.email) {
      promises.push(dispatch(updateEmail(email)));
    }
    if (name !== myUserInfo.user.userProfile.coreUserInfo.name || location !== myUserInfo.user.userProfile.coreUserInfo.location) {
      const newUserProfile = {
        ...myUserInfo.user.userProfile,
        coreUserInfo: {
          ...myUserInfo.user.userProfile.coreUserInfo,
        },
      };
      if (name !== myUserInfo.user.userProfile.coreUserInfo.name) {
        newUserProfile.coreUserInfo.name = name;
      }
      if (location !== myUserInfo.user.userProfile.coreUserInfo.location) {
        newUserProfile.coreUserInfo.location = location;
      }
      promises.push(dispatch(updateProfile(newUserProfile)));
    }

    await Promise.all(promises);
    setSaving(false);
  }, [dispatch, email, name, location, myUserInfo]);

  React.useEffect(() => {
    if (!myUserInfo) {
      navigate('/');
    }
  }, [myUserInfo, navigate]);

  if (!myUserInfo) {
    return null;
  }

  const hasChange = email !== myUserInfo.contact.email ||
    name !== myUserInfo.user.userProfile.coreUserInfo.name ||
    JSON.stringify(location) !== JSON.stringify(myUserInfo.user.userProfile.coreUserInfo.location);

  return (
    <Page centered>
      <MainBackground />
      <TopMenu addMarginX />

      <Box position='relative' mx={DefaultStyleAttrs.responsiveMx} mt={30} p={50}
        bgcolor={Colors.WHITE} border={1} borderRadius={5} borderColor={Colors.DISABLED}
      >
        <Text size='banner' variant='bold' mb={20}>{t('profile.title')}</Text>

        <TextField
          margin='normal'
          required
          fullWidth
          label={t('profile.phone')}
          value={myUserInfo.contact.phone}
          disabled
        />

        <TextField
          margin='normal'
          fullWidth
          label={t('profile.email')}
          autoComplete='email'
          value={email}
          onChange={(event) => {
            const newEmail = event.target.value;
            setEmail(newEmail.trim());
            setErrMsg('');
          }}
          placeholder='taro@gmail.com'
        />

        <TextField
          margin='normal'
          fullWidth
          label={t('profile.name')}
          autoComplete='name'
          value={name}
          onChange={(event) => {
            const newName = event.target.value;
            setName(newName);
          }}
        />

        <TextField
          margin='normal'
          disabled
          fullWidth
          label={t('profile.city')}
          value={location.city + ', ' + location.state}
          onChange={(event) => {
            const newLocation = event.target.value;
            console.log(newLocation);
            setLocation({} as Location);
          }}
        />

        <Stack direction='column' display='flex' alignItems='center' mt={50}>
          {errMsg ? <Text size='paragraph' color='error' mb={5}>{errMsg}</Text> : null}
          <Button
            size='large' variant='contained' sx={{ borderRadius: DefaultStyleAttrs.borderRadius, px: 60 }}
            disabled={saving || !hasChange}
            onClick={handleSave}
          >
            {t('profile.save')}
            {saving ? <CircularProgress size={15} sx={{ position: 'absolute', right: 35 }}/> : null}
          </Button>
        </Stack>
      </Box>

      <Box display='flex' flexDirection='column' alignItems='center' my={30}>
        <Text size='paragraph' color='primary' mb={15}>support@hellotaro.com</Text>
        <Text size='paragraph' color='primary'>Copyright Taro LLC</Text>
      </Box>
    </Page>
  );
}

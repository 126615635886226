enum LogLevel {
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export default class Logger {
  private static instance: Logger;

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  public info(msg: string, ...optionalParams: any[]) {
    this.safelog(LogLevel.INFO, msg, optionalParams);
  }

  public error(msg: string, ...optionalParams: any[]) {
    this.safelog(LogLevel.ERROR, msg, optionalParams);
  }

  private safelog(logLevel: LogLevel, msg: string, optionalParams: any[]): void {
    try {
      this.log(logLevel, msg, optionalParams);
    } catch (err) {
      console.error('Failed to log:', err);
    }
  }

  private log(logLevel: LogLevel, msg: string, optionalParams: any[]): void {
    // TODO(hank): we need to emit logs to server eventually
    switch (logLevel) {
      case LogLevel.INFO:
        console.log(msg, ...optionalParams);
        break;
      case LogLevel.ERROR:
        console.error(msg, ...optionalParams);
        break;
      default:
        console.error('Unknown level <' + logLevel + '>: ' + msg);
    }
  }
}

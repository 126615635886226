export const Colors = {
  PRIMARY: '#6459A1',
  PRIMARY_LIGHT: '#7E71CD',
  PRIMARY_BACKGROUND: '#E4E4FB',
  DEFAULT_BACKGROUND: '#F6F6F6',

  ACTION: '#FFBCA4',
  ACTION_DARK: '#982D07',
  ACTION_ACCENT: '#FF9671',
  DISABLED: '#D1D5DB',
  ERROR: '#E64E0B',
  NOTIFICATION: '#F2F2F2',

  TEXT_SYSTEM: '#4A4A4A',
  TEXT_SYSTEM_LIGHT: '#AAAAAA',
  TEXT_DISABLED: '#CCCCCC',

  SECONDARY_BACKGROUND: '#FFAB8D',

  BUTTON_SECONDARY_TEXT: '#7C5446',
  BUTTON_SECONDARY_BACKGROUND: '#FFAB8D',

  GREY100: '#eeeeee',
  GREY500: '#9e9e9e',
  GREY600: '#757575',
  GREY700: '#616161',

  WHITE: '#FFF',
  GREEN: '#D8F3E3',
  YELLOW: '#FFECA3',
};

const TextStyles = {
  subnote: {
    fontSize: 10,
  },
  note: {
    fontSize: 12,
  },
  paragraph: {
    fontSize: 14,
  },
  subtitle: {
    fontSize: 17,
  },
  title: {
    fontSize: 20,
  },
  banner: {
    fontSize: 24,
  },
};

export const ComponentStyles = {
  text: TextStyles,
};


import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import NotFoundSvg from 'common/src/assets/svgs/NotFoundSvg';
import { Page, Text } from 'common/src/components/base';

export default function NotFoundScreen() {
  const navigate = useNavigate();

  return (
    <Page sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      <Box sx={{ width: 400 }}><NotFoundSvg /></Box>
      <Text fontSize={30} mb={24}>{'This page doesn\'t exist'}</Text>
      <Button variant='contained' onClick={() => navigate('/', { replace: true })} >
        Go back home
      </Button>
    </Page>
  );
}

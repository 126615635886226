import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AppBar, Avatar, Box, Button, CardMedia, Container, Link, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { Colors } from 'common/src/constants';
import { AuthContext } from 'common/src/context/auth';
import Env from 'common/src/env';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';
import { getEventDateLabelWithMask, TicketOrderStatus } from 'common/src/models/event';
import { getHumanReadableDateHourTimeWithWeek, getISOTime } from 'common/src/utils/time';
import { DefaultStyleAttrs } from '../../constants/styles';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  useEventTemplate, useEventTemplates, useEventTicketInventory, useVendors,
} from '../../hooks/useResource';
import { refreshTicketOrders, selectTicketOrdersByTemplate } from '../../redux/slices/event';

import { HorizontalScoll, Page, Text } from 'common/src/components/base';
import ActionModal from '../../components/ActionModal';
import CancellationPolicy from '../../components/CancellationPolicy';
import EventListingCard from '../../components/EventListingCard';
import { EventTemplateIntroHeader } from '../../components/IntroHeader';
import MainBackground from '../../components/MainBackground';
import TopMenu from '../../components/TopMenu';

export default function EventDetailsScreen() {
  const { eventTemplateId } = useParams();
  const { windowHeight } = useWindowDimensions();
  const { t } = useTranslation('wbevt.events');

  const theme = useTheme();
  const [showQRcode, setShowQRcode] = React.useState(false);
  if (!eventTemplateId) {
    throw new Error('BUG: eventTemplateId missing for EventDetailsScreen');
  }

  const [galleryIdx, setGalleryIdx] = React.useState(-1);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const eventTicketInventory = useEventTicketInventory(eventTemplateId);

  const { authStatus } = React.useContext(AuthContext);
  React.useEffect(() => {
    if (authStatus === 'loggedIn') {
      dispatch(refreshTicketOrders({ onlyIfUncached: true }));
    }
  }, [authStatus, dispatch]);

  const ticketOrders = useAppSelector((state) =>
    selectTicketOrdersByTemplate(state, eventTemplateId));

  const eventTemplate = useEventTemplate(eventTemplateId);
  const eventTemplates = useEventTemplates(/* includePastEvents */ false);
  const vendors = useVendors();

  const handleSeeAllEvents = React.useCallback(() => navigate('/'), [navigate]);

  const isSm = useMediaQuery(theme.breakpoints.only('sm'));

  if (!eventTemplate || !eventTicketInventory) {
    return null;
  }
  const payload = eventTemplate.payload.hostedEventPayload;

  const vendor = vendors ? vendors.find((vendor) => vendor.id === payload.vendorId) : null;
  const downloadModal =(
    <ActionModal
      visible={showQRcode}
      title={t('details.scanDownloadApp')}
      primaryBtnLabel='Confirm'
      onPrimaryBtnPress={()=>setShowQRcode(false)}
      onClose={()=>setShowQRcode(false)}
      onOverlayPress={()=>setShowQRcode(false)}
    >
      <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }} my={20} p={20} >
        <Box><img src={require('../../assets/images/applestore.png')} width='150'/></Box>
        <Box pl={60}> <img src={require('../../assets/images/googleplay.png')} width='150' /></Box>
      </Box>
    </ActionModal>);

  const relatedEventTemplates = Object.values(eventTemplates)
    .filter((eventTemplate_) =>
      !eventTemplate_.archived && (Env.shouldShowWIPFeatures() || eventTemplate_.published),
    )
    .filter((eventTemplate_) =>
      eventTemplate_.location.state.trim().toLowerCase() === eventTemplate.location.state.trim().toLowerCase(),
    )
    .filter((eventTemplate_) =>
      // Future event
      eventTemplate_.payload.hostedEventPayload.registrationToTs > Date.now(),
    )
    .filter((eventTemplate_) => eventTemplate_.id !== eventTemplate.id)
    .filter((eventTemplate_) => eventTemplate_.visible)
    .sort((eventTemplate1, eventTemplate2) => {
      // We prioritize showing events that are still open for registration
      // Of those, we prioritize showing events that are closing soon
      const now = Date.now();
      const ts1 = eventTemplate1.payload.hostedEventPayload.registrationToTs;
      const ts2 = eventTemplate2.payload.hostedEventPayload.registrationToTs;
      if (ts1 < now) {
        return 1;
      }
      if (ts2 < now) {
        return -1;
      }
      return ts1 - ts2;
    })
    .slice(0, 10);

  const minCostInCents = Math.min(...payload.ticketOptions
    .map((ticketOption) => ticketOption.genericCostInCents));

  let bannerActionFn;
  const activeTicketOrders = [...ticketOrders || []]
    .filter((order) => ![TicketOrderStatus.CANCELLED, TicketOrderStatus.INIT, TicketOrderStatus.ERROR].includes(order.status));
  if (activeTicketOrders.length === 1) {
    bannerActionFn = () => navigate(`/t/${activeTicketOrders[0].id}`);
  } else if (activeTicketOrders.length > 1) {
    bannerActionFn = () => navigate('/myevents');
  }

  let footerActionLabel;
  let footerActionFn;
  let footerActionDisabled = false;
  if (eventTemplate.singlesEvent) {
    footerActionLabel = t('details.downloadApp');
    footerActionFn = () => setShowQRcode(true);
  } else if (payload.registrationToTs < Date.now()) {
    footerActionLabel = t('details.registrationClosed');
    footerActionDisabled = true;
  } else if (payload.registrationFromTs > Date.now()) {
    footerActionLabel = t('details.registrationNotOpen');
    footerActionDisabled = true;
  } else if (!payload.allowMultipleTicketOrders && activeTicketOrders.length) {
    footerActionLabel = t('details.viewOrder');
    footerActionFn = bannerActionFn;
  } else {
    footerActionLabel = t('details.registerEvent');
    footerActionFn = () => navigate(`/e/${eventTemplateId}/r`);
  }

  const imgGalleryModal = galleryIdx > -1 && (
    <ActionModal onClose={() => setGalleryIdx(-1)} onOverlayPress={() => setGalleryIdx(-1)}>
      <ImageGallery
        showPlayButton={false}
        startIndex={galleryIdx}
        items={eventTemplate.extraMedia.map((media) => ({
          original: media.downloadUrl,
          thumbnail: media.downloadUrl,
        }))}
      />
    </ActionModal>
  );

  const seoEventSummary = `${eventTemplate.name} - `+
  `${getHumanReadableDateHourTimeWithWeek(eventTemplate.eventFromTs, eventTemplate.eventTimeZone, /* forceShowYear */ true)} ` +
  `at ${eventTemplate.location.summary || (eventTemplate.location.city + ', ' + eventTemplate.location.state)}`;

  const seoKeywords = [
    'Taro',
    'hellotaro',
    '香芋',
    eventTemplate.name,
    eventTemplate.location.city,
    `${eventTemplate.name} tickets`,
    `${eventTemplate.name} 门票`,
    `${eventTemplate.name} ${eventTemplate.location.city}`,
  ];

  return (
    <Page>
      <Helmet prioritizeSeoTags>
        <title>{`${seoEventSummary} | Taro`}</title>

        <meta name='description' content={`Taro - ${seoEventSummary}`} />
        <meta name='keywords' content={seoKeywords.join(', ')} />
        <meta property='og:title' content={eventTemplate.name} />
        <meta property='og:description' content={eventTemplate.intro} />
        <meta property='og:image' content={eventTemplate.media.downloadUrl} />
        <meta property='og:url' content={`https://event.hellotaro.com/e/${eventTemplateId}`} />
        <meta property='og:type' content='events.event' />
        <meta property='og:site_name' content='Taro' />
        <meta property='event:start_time' content={getISOTime(eventTemplate.eventFromTs)} />
        <meta property='event:end_time' content={getISOTime(eventTemplate.eventToTs)} />
        <meta property='event:location:latitude' content={eventTemplate.location.latlng && eventTemplate.location.latlng.split(',').length === 2 ? eventTemplate.location.latlng.split(',')[0] : ''} />
        <meta property='event:location:longitude' content={eventTemplate.location.latlng && eventTemplate.location.latlng.split(',').length === 2 ? eventTemplate.location.latlng.split(',')[1] : ''} />

        <link rel='canonical' href={`https://event.hellotaro.com/e/${eventTemplateId}`} />
      </Helmet>
      {downloadModal}
      {imgGalleryModal}
      <Box position='relative' mb={75} height={windowHeight-75} overflow='auto'>
        <Container>
          <Box mx={DefaultStyleAttrs.responsiveMx}>
            <MainBackground />
            <TopMenu />
            {bannerActionFn && (
              <Box
                position='relative' mb={isSm ? 10 : -20} bgcolor={Colors.PRIMARY_LIGHT}
                borderRadius={2} overflow='hidden'
              >
                <Stack direction='row' justifyContent='space-between' alignItems='center' py={15} px={20}>
                  <Text color='white'>{t('details.alreadyRegistered')}</Text>
                  <Link onClick={bannerActionFn} underline='always' sx={{ color: 'white', cursor: 'pointer', textDecorationColor: 'white' }}>
                    {t('details.viewOrder')}
                  </Link>
                </Stack>
              </Box>
            )}
            <EventTemplateIntroHeader variant='cover' eventTemplate={eventTemplate} />

            <Box>
              <Text size='subtitle' variant='bold' mt={30}>{t('details.eventIntro')}</Text>
              <Text size='paragraph' mt={15}>{eventTemplate.description}</Text>
            </Box>
          </Box>

          {eventTemplate.extraMedia.length > 0 && (
            <Box>
              <Box mx={DefaultStyleAttrs.responsiveMx}>
                <Text size='subtitle' variant='bold' mt={30} mb={15}>{t('details.eventPreview')}</Text>
              </Box>
              <HorizontalScoll>
                {eventTemplate.extraMedia.map((media, idx) => (
                  <CardMedia
                    key={media.id}
                    component='img'
                    image={media.downloadUrl}
                    alt={media.id}
                    loading='lazy'
                    onClick={() => setGalleryIdx(idx)}
                    sx={{ height: 185, objectFit: 'cover', cursor: 'pointer' }}
                  />
                ))}
              </HorizontalScoll>
            </Box>
          )}

          <Box mx={DefaultStyleAttrs.responsiveMx}>
            <CancellationPolicy eventTemplate={eventTemplate} />
            {vendor && (
              <Box sx={{ flexDirection: 'row', display: 'flex',
                width: '100%' }} bgcolor='#E8E7EA' my={20} p={20} >
                <Box borderRadius={100} mr={15}>
                  <Avatar
                    src={vendor.refMedia.downloadUrl} alt=''
                    variant='rounded'
                    sx={{ width: 100, height: 75 }}
                  />
                </Box>
                <Box>
                  <Text size='subtitle' >{vendor.name}</Text>
                  <Text size='paragraph' mt={10}>{vendor.description}</Text>
                </Box>
              </Box>
            )}
          </Box>
        </Container>

        <Box bgcolor='#E8E7EA' mt={50} py={70}>
          <Container>
            <Stack direction='row' justifyContent='space-between' mb={15} mx={DefaultStyleAttrs.responsiveMx}>
              <Text size='subtitle' variant='bold'>{t('details.followOtherEvents')}</Text>
              <Link onClick={handleSeeAllEvents} underline='hover'>
                {t('details.checkAllEvents')}
              </Link>
            </Stack>
            <HorizontalScoll>
              {relatedEventTemplates
                .map((eventTemplate) => (
                  <EventListingCard key={eventTemplate.id} eventTemplateId={eventTemplate.id} />
                ))}
            </HorizontalScoll>
          </Container>
        </Box>
      </Box>

      <AppBar position='fixed' color='transparent' sx={{ top: 'auto', bottom: 0 }}>
        <Box px={DefaultStyleAttrs.mx} bgcolor={Colors.WHITE}>
          <Stack direction='row' justifyContent='space-between'>
            {!isSm ? (
              <Box display='flex' alignItems='center'>
                <Stack>
                  <Text color='primary' size='paragraph' maxLines={1}>
                    {getEventDateLabelWithMask(eventTemplate, /* showTime */ true)}
                  </Text>
                  <Text size='paragraph' mt={5}>{
                    eventTemplate.singlesEvent ?
                      (eventTemplate.name + ' (' + t('details.singlesOnly') + ')') :
                      eventTemplate.name
                  }</Text>
                </Stack>
              </Box>
            ) : <Box />}
            <Stack direction='row' alignItems='center'>
              {!eventTemplate.singlesEvent && (
                <Text size='subtitle' variant='bold' mr={20}>
                  {minCostInCents % 100 === 0 ? `From $${minCostInCents / 100}` : `From $${(minCostInCents / 100).toFixed(2)}`}
                </Text>
              )}
              <Box my={20}>
                <Button onClick={footerActionFn} disabled={footerActionDisabled} variant='contained'>
                  {footerActionLabel}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </AppBar>
    </Page>
  );
}

import Logger from '../../utils/logger';
import RequestUtils from '../../utils/request';

import SessionAccessor from '../session';

const LOGGER = Logger.getInstance();

export async function invokeAuthenticatedApi(req: any) {
  const sessionToken = await SessionAccessor.getSessionToken();
  if (!sessionToken) {
    const errMsg = 'Failed to get session token';
    LOGGER.error(errMsg);
    throw new Error(errMsg);
  }
  const reqHeader = {
    sessiontoken: sessionToken,
  };

  return RequestUtils.invokeRemoteApi(req, reqHeader)
    .catch(async (e) => {
      if (e.statusCode && e.statusCode === 401) {
        // Unauthorized might mean the session has expired
        LOGGER.info('Invoking remote API resulted in 401. Retrying with new session...');
        await SessionAccessor.invalidateSessionToken();
        const newSessionToken = await SessionAccessor.getSessionToken();
        const newReqHeader = {
          sessiontoken: newSessionToken,
        };
        return RequestUtils.invokeRemoteApi(req, newReqHeader);
      } else {
        throw e;
      }
    }).catch((e) => {
      LOGGER.error('Invoking remote API failed: ' + JSON.stringify(e));
      throw e;
    });
}

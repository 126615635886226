import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GeoStateCode } from 'common/src/models/event';

import { AppDispatch, RootState } from '../store';

// ### State ###

interface SettingState {
  stateFilter: GeoStateCode | null;
  userState: GeoStateCode | null;
}

const initialState: SettingState = {
  stateFilter: null,
  userState: null,
};


// ### Actions ### //

export const determineUserStateFromIp = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('setting/determineUserStateFromIp', async (_unused, { getState, dispatch }) => {
  const state = getState();

  if (state.setting.userState) {
    return;
  }

  fetch('https://ipapi.co/json/')
    .then((response) => response.json())
    .then((data) => {
      if (data.region_code) {
        const userState = data.region_code.toLowerCase();
        if (Object.values(GeoStateCode).includes(userState)) {
          dispatch(setUserState(userState as GeoStateCode));
        }
      }
    });
});


// ### Slice ###

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setStateFilter: (state, action: PayloadAction<GeoStateCode>) => {
      state.stateFilter = action.payload;
    },
    setUserState: (state, action: PayloadAction<GeoStateCode>) => {
      state.userState = action.payload;
    },
  },
});


// ### Selectors ### //

export const selectStateFilterWithDefault = (state: RootState): GeoStateCode => {
  if (state.setting.stateFilter) {
    return state.setting.stateFilter;
  }

  if (state.setting.userState) {
    return state.setting.userState;
  }

  return GeoStateCode.CA;
};


// ### Exports ### //

export const { setStateFilter, setUserState } = settingSlice.actions;
export default settingSlice.reducer;

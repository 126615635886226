import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Button, InputAdornment, Link, TextField } from '@mui/material';

import { AuthContext, LoginResult } from 'common/src/context/auth';
import { getPhoneTokenRemote, verifyPhoneRemote } from 'common/src/system/network/identity';

import { Page, Text } from 'common/src/components/base';

export default function LoginScreen() {
  const [phone, setPhone] = React.useState('');
  const [smsCode, setSmsCode] = React.useState('');
  const [smsSent, setSmsSent] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const { login } = React.useContext(AuthContext);
  const { t } = useTranslation('wbevt.misc');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (smsSent) {
      const getPhoneTokenResp = await getPhoneTokenRemote(phone, smsCode);
      if (getPhoneTokenResp.errCode === 'invalid_principal_or_secret') {
        setErrMsg(t('login.invalidPhoneToken'));
        return;
      }

      // Received correct phone token. Now login
      const loginResult = await login(getPhoneTokenResp.token);
      switch (loginResult) {
        case LoginResult.SUCCESS:
          navigate('/');
          break;
        case LoginResult.NOT_REGISTERED:
          setErrMsg(t('login.unregistered'));
          break;
      }
    } else {
      await verifyPhoneRemote(phone);
      setSmsSent(true);
    }
  };

  return (
    <Page centered sx={{
      marginTop: 64,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 350,
    }}>
      <Avatar sx={{ m: 8, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Text size='title'>Please Login</Text>
      <TextField
        margin='normal'
        required
        fullWidth
        label='Phone'
        autoComplete='phone'
        autoFocus
        value={phone}
        onChange={(event) => {
          // Remove non-numerics and ensure size<=10
          const newPhone = event.target.value.replace(/\D/g, '').substring(0, 10);
          setErrMsg('');
          setPhone(newPhone);
          if (newPhone.length < 10) {
            setSmsSent(false);
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position='start'>+1</InputAdornment>,
        }}
      />
      {smsSent && (
        <TextField
          margin='normal'
          required
          fullWidth
          label='SMS Code'
          value={smsCode}
          onChange={(event) => {
            // Remove non-numerics and ensure size<=6
            const newSmsCode = event.target.value.replace(/\D/g, '').substring(0, 6);
            setErrMsg('');
            setSmsCode(newSmsCode);
          }}
          disabled={phone.length !== 10}
        />
      )}
      <Text size='note' color='error'>{errMsg}</Text>
      <Button
        fullWidth variant='contained' sx={{ mt: 24 }}
        disabled={(smsSent && smsCode.length !== 6) || (!smsSent && phone.length !== 10) || !!errMsg}
        onClick={handleSubmit}
      >
        {smsSent ? 'Login' : 'Send SMS Code'}
      </Button>
      <Text size='paragraph' color='system' mt={64} sx={{ opacity: 0.7 }}>
        {'Copyright © '}
        <Link color='inherit' href='https://www.hellotaro.com/'>Taro LLC</Link>{' '}
        {new Date().getFullYear()}
      </Text>
    </Page>
  );
}

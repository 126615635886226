import { Colors } from '../../constants';

import { deserializeEventFeedback, EventFeedback } from './common';
import { EventTemplate } from './eventTemplate';
import { deserializeSurveyResponse, SurveyResponse } from './survey';


// ### TicketOrder ### //

export type TicketOrder = {
  id: string;
  eventTemplateId: string;
  eventInstanceHash: string;
  userId: string;

  status: TicketOrderStatus;
  errCode: string;
  cancellationReason: string;

  tickets: Ticket[];
  costDetails: CostDetails;

  surveyResponses: SurveyResponse[];
  feedback: EventFeedback | null;
  utm: string;
  internal: boolean;

  initTs: number;
  registrationTs: number;
  confirmationTs: number;
  cancellationTs: number;
}

export function deserializeTicketOrder(raw: any) {
  const tickets = Array
    .of(...(raw.tickets || []))
    .map((entry) => deserializeTicket(entry));

  const surveyResponses = Array
    .of(...(raw.surveyResponses || []))
    .map((entry) => deserializeSurveyResponse(entry));

  return {
    id: raw.id,
    eventTemplateId: raw.eventTemplateId,
    eventInstanceHash: raw.eventInstanceHash,
    userId: raw.userId,
    status: raw.status as TicketOrderStatus,
    errCode: raw.errCode,
    cancellationReason: raw.cancellationReason,
    tickets: tickets,
    costDetails: deserializeCostDetails(raw.costDetails),
    surveyResponses: surveyResponses,
    feedback: raw.feedback ? deserializeEventFeedback(raw.feedback): null,
    utm: raw.utm,
    internal: raw.internal,
    initTs: +raw.initTs,
    registrationTs: +raw.registrationTs,
    confirmationTs: +raw.confirmationTs,
    cancellationTs: +raw.cancellationTs,
  } as TicketOrder;
}

export enum TicketOrderStatus {
  INIT = 'init',
  PENDING = 'pending',
  WAITLIST = 'waitlist',
  CONFIRMING = 'confirming',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  ERROR = 'error',
}

export enum TicketOrderVirtualStatus {
  INIT = 'init',
  PENDING = 'pending',
  WAITLIST = 'waitlist',
  CONFIRMING = 'confirming',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  CHECKED_IN = 'checked_in',
}

export enum AdjustTicketOrderAction {
  REFUND = 'refund',
  CANCEL_AND_REFUND = 'cancel_and_refund',
  CANCEL_AND_REFUND_AND_ACCEPT_WAITLIST = 'cancel_and_refund_and_accept_waitlist',
  ACCEPT_WAITLIST = 'accept_waitlist'
}

export function getTicketOrderVirtualStatus(ticketOrder: TicketOrder) {
  if (isTicketOrderFullyCheckedIn(ticketOrder)) {
    return TicketOrderVirtualStatus.CHECKED_IN;
  }
  return ticketOrder.status as unknown as TicketOrderVirtualStatus;
}

export enum TicketVirtualStatus {
  INIT = 'init',
  PENDING = 'pending',
  WAITLIST = 'waitlist',
  CONFIRMING = 'confirming',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
  ERROR = 'error',
  CHECKED_IN = 'checked_in',
}

export function getTicketVirtualStatus(ticketOrder: TicketOrder, ticket: Ticket) {
  if (ticket.status === TicketStatus.CANCELLED) {
    return TicketVirtualStatus.CANCELLED;
  } else if (ticket.status === TicketStatus.CHECKED_IN) {
    return TicketVirtualStatus.CHECKED_IN;
  }
  return ticketOrder.status as unknown as TicketVirtualStatus;
}

export function isTicketOrderFullyCheckedIn(ticketOrder: TicketOrder) {
  return ticketOrder.tickets.every((ticket) => ticket.status === TicketStatus.CHECKED_IN);
}

export function getTicketStatusLabel(ticketStatus: TicketVirtualStatus, ticketOrder: TicketOrder) {
  return getTicketOrOrderStatusLabel(ticketStatus, ticketOrder.errCode);
}

export function getTicketOrderStatusLabel(ticketOrder: TicketOrder) {
  return getTicketOrOrderStatusLabel(ticketOrder.status, ticketOrder.errCode);
}

function getTicketOrOrderStatusLabel(status: TicketVirtualStatus | TicketOrderStatus, errCode: string ) {
  let statusLabelTextKey = '';
  let statusLabelColor = '';
  let statusDescriptionKey = '';
  let statusLabelIcon = '';

  switch (status) {
    case TicketVirtualStatus.ERROR:
    case TicketOrderStatus.ERROR:
      statusLabelTextKey = 'orderStatus.label.registrationError';
      statusLabelColor = Colors.ERROR;
      statusDescriptionKey = errCode === 'payment_failed' ?
        'orderStatus.description.paymentFailed' :
        'orderStatus.description.registrationError';
      statusLabelIcon = '❌';
      break;
    case TicketVirtualStatus.CONFIRMED:
    case TicketVirtualStatus.CONFIRMING:
    case TicketOrderStatus.CONFIRMED:
    case TicketOrderStatus.CONFIRMING:
      statusLabelTextKey = 'orderStatus.label.registrationSuccess';
      statusLabelColor = Colors.GREEN;
      statusDescriptionKey = 'orderStatus.description.registrationSuccess';
      statusLabelIcon = '✅';
      break;
    case TicketVirtualStatus.CHECKED_IN:
      statusLabelTextKey = 'orderStatus.label.registrationSuccess';
      statusLabelColor = Colors.PRIMARY_LIGHT;
      statusDescriptionKey = 'orderStatus.description.registrationSuccess';
      statusLabelIcon = '✅';
      break;
    case TicketVirtualStatus.CANCELLED:
    case TicketOrderStatus.CANCELLED:
      statusLabelTextKey = 'orderStatus.label.registrationCancelled';
      statusLabelColor = Colors.TEXT_DISABLED;
      statusDescriptionKey = 'orderStatus.description.registrationCancelled';
      statusLabelIcon = '❌';
      break;
    case TicketVirtualStatus.PENDING:
    case TicketOrderStatus.PENDING:
      statusLabelTextKey = 'orderStatus.label.registrationPending';
      statusLabelColor = Colors.YELLOW;
      statusDescriptionKey = 'orderStatus.description.registrationPending';
      statusLabelIcon = '⏳';
      break;
    case TicketVirtualStatus.WAITLIST:
    case TicketOrderStatus.WAITLIST:
      statusLabelTextKey = 'orderStatus.label.registrationWaitlist';
      statusLabelColor = Colors.YELLOW;
      statusDescriptionKey = 'orderStatus.description.registrationWaitlist';
      statusLabelIcon = '⏳';
      break;
    case TicketVirtualStatus.INIT:
    case TicketOrderStatus.INIT:
      statusLabelTextKey = 'orderStatus.label.registrationInit';
      statusLabelColor = Colors.TEXT_DISABLED;
      statusDescriptionKey = 'orderStatus.description.registrationInit';
      statusLabelIcon = '';
      break;
  }

  return { statusLabelTextKey, statusLabelColor, statusDescriptionKey, statusLabelIcon };
}

export function getSelectedTicketOptions(ticketOrder: TicketOrder): SelectedTicketOption[] {
  const selectedTicketOptions: Record<string, SelectedTicketOption> = {};
  for (const ticket of ticketOrder.tickets) {
    if (ticket.status === TicketStatus.CANCELLED) {
      continue;
    }
    const hash = ticket.ticketOptionId + '__' + ticket.seatId;
    if (!selectedTicketOptions[hash]) {
      selectedTicketOptions[hash] = {
        id: ticket.ticketOptionId,
        seatId: ticket.seatId,
        quantity: 0,
      } as SelectedTicketOption;
    }
    selectedTicketOptions[hash].quantity += 1;
  }

  return Object.values(selectedTicketOptions);
}

// The most relevant ticketOrder for a user is defined as follows:
//   Note this only makes sense in the context that all ticketOrders are for a particular
//   eventTemplate.
// If there is no ticketOrders, return null
// Else return the last (by time) ticketOrder which is not INIT
// Otherwise return the last (by time) ticketOrder which is INIT
export function findMostRelevantTicketOrder(ticketOrders: TicketOrder[]) {
  return [...ticketOrders]
    .sort((order1, order2) => order1.initTs - order2.initTs)
    .reduceRight<TicketOrder | null>((selectedOrder, order) => {
      if (selectedOrder === null) {
        // initial condition
        return order;
      } else if (selectedOrder.status === TicketOrderStatus.INIT && order.status !== TicketOrderStatus.INIT) {
        // ignore INIT unless it's the only status
        return order;
      } else {
        return selectedOrder;
      }
    }, null);
}


// ### Ticket ### //

export type Ticket = {
  id: string;
  status: TicketStatus;

  ticketOptionId: string;
  seatId?: string;

  checkedInTs: number;
  cancelledTs: number;
}

export function deserializeTicket(raw: any) {
  return {
    id: raw.id,
    status: raw.status as TicketStatus,
    ticketOptionId: raw.ticketOptionId,
    seatId: raw.seatId,
    checkedInTs: +raw.checkedInTs,
    cancelledTs: +raw.cancelledTs,
  } as Ticket;
}

export enum TicketStatus {
  UNCHECKED_IN = 'unchecked_in',
  CHECKED_IN = 'checked_in',
  CANCELLED = 'cancelled',
}


// ### TicketOption ### //

export type TicketOption = {
  id: string;
  name: string;
  description: string;

  maxMaleParticipants: number;
  maxFemaleParticipants: number;
  maxGenericParticipants: number;
  maxTotalParticipants: number;

  maleCostInCents: number;
  femaleCostInCents: number;
  genericCostInCents: number;

  maxQuantityPerUser: number;

  addOns: TicketAddOn[];

  allowWaitlist: boolean;
  registrationFromTs: number;
  registrationToTs: number;
  visibilitySetting: string;
}

export type TicketAddOn = {
  id: string;
  name: string;
  description: string;

  maxQuantity: number;
  costAdjustmentInCents: number;
}

export enum TicketOptionVisibilitySetting {
  ALWAYS = 'always',
  NEVER = 'never',
  WITHIN_REGISTRATION_WINDOW = 'within_registration_window',
}

export function deserializeTicketOption(raw: any) {
  return {
    id: raw.id,
    name: raw.name,
    description: raw.description,
    maxMaleParticipants: +raw.maxMaleParticipants,
    maxFemaleParticipants: +raw.maxFemaleParticipants,
    maxGenericParticipants: +raw.maxGenericParticipants,
    maxTotalParticipants: +raw.maxTotalParticipants,
    maleCostInCents: +raw.maleCostInCents,
    femaleCostInCents: +raw.femaleCostInCents,
    genericCostInCents: +raw.genericCostInCents,
    maxQuantityPerUser: +raw.maxQuantityPerUser,
    addOns: raw.addOns.map((addOn: any) => deserializeTicketAddOn(addOn)),
    allowWaitlist: raw.allowWaitlist,
    registrationFromTs: +raw.registrationFromTs,
    registrationToTs: +raw.registrationToTs,
    visibilitySetting: raw.visibilitySetting as TicketOptionVisibilitySetting,
  } as TicketOption;
}

function deserializeTicketAddOn(raw: any) {
  return {
    id: raw.id,
    name: raw.name,
    description: raw.description,
    maxQuantity: +raw.maxQuantity,
    costAdjustmentInCents: +raw.costAdjustmentInCents,
  } as TicketAddOn;
}

export function resolveTicketOption(eventTemplate: EventTemplate, ticketOptionId: string) {
  const ticketOption = eventTemplate.payload.hostedEventPayload.ticketOptions
    .find((ticketOption) => ticketOption.id === ticketOptionId);
  if (!ticketOption) {
    throw new Error('BUG: event ' + eventTemplate.id + ' missing ticketOptionId ' + ticketOptionId);
  }
  return ticketOption;
}


// ### SelectedTicketOption ### //

export type SelectedTicketOption = {
  id: string;
  quantity: number;
  seatId?: string;
  selectedTicketAddOns: Record<string, number>; // addOn id -> quantity
}

export function collateSelectedTicketOptions(selectedTicketOptions: SelectedTicketOption[]) {
  return selectedTicketOptions.reduce((acc, selectedTicketOption) => {
    const existing = acc.find((item) => item.id === selectedTicketOption.id);
    if (existing) {
      existing.quantity += selectedTicketOption.quantity;
    } else {
      acc.push({ id: selectedTicketOption.id, quantity: selectedTicketOption.quantity });
    }
    return acc;
  }, [] as { id: string, quantity: number }[]);
}

export function deserializedSelectedTicketOption(raw: any) {
  return {
    id: raw.id,
    quantity: +raw.quantity,
    selectedTicketAddOns: raw.selectedTicketAddOns,
  } as SelectedTicketOption;
}


// ### EventTicketInventory ### //

export type EventTicketInventory = {
  eventTemplateId: string;
  // Number of tickets remaining for the ticket option
  ticketOptionToTicketsRemaining: Record<string, number>;
}

export function deserializeEventTicketInventory(raw: any) {
  return {
    eventTemplateId: raw.eventTemplateId,
    ticketOptionToTicketsRemaining: raw.ticketOptionToTicketsRemaining,
  } as EventTicketInventory;
}


// ### CostDetails ### //

export type CostDetails = {
  ticketIdToCost: Record<string, TicketCost>;
  subtotalInCents: number;
  finalCostInCents: number;
}

export type TicketCost = {
  subtotalInCents: number;
  costAdjustors: CostAdjustor[];
  taxInCents: number;
  feeInCents: number;
  finalCostInCents: number;
}

export type CostAdjustor = {
  adjustorId: string;
  adjustorName: string;
  adjustmentInCents: number;
}

export type TicketCostPreview = {
  selectedTicketOptionIdx: number;
  ticketCost: TicketCost;
}

export type CostAggregation = {
  subtotalInCents: number;
  costAdjustors: CostAdjustor[];
  taxInCents: number;
  feeInCents: number;
  finalCostInCents: number;
}

export function getCostAggregation(ticketCosts: TicketCost[]): CostAggregation {
  const costAdjustors = new Map<string, CostAdjustor>();
  for (const ticketCost of ticketCosts) {
    for (const costAdjustor of ticketCost.costAdjustors) {
      let aggCostAdjustor = costAdjustors.get(costAdjustor.adjustorId);
      if (!aggCostAdjustor) {
        aggCostAdjustor = {
          adjustorId: costAdjustor.adjustorId,
          adjustorName: costAdjustor.adjustorName,
          adjustmentInCents: 0,
        };
        costAdjustors.set(costAdjustor.adjustorId, aggCostAdjustor);
      }
      aggCostAdjustor.adjustmentInCents += costAdjustor.adjustmentInCents;
    }
  }

  return {
    subtotalInCents: ticketCosts.reduce((acc, ticketCost) => acc + ticketCost.subtotalInCents, 0),
    costAdjustors: Array.from(costAdjustors.values()),
    taxInCents: ticketCosts.reduce((acc, ticketCost) => acc + ticketCost.taxInCents, 0),
    feeInCents: ticketCosts.reduce((acc, ticketCost) => acc + ticketCost.feeInCents, 0),
    finalCostInCents: ticketCosts.reduce((acc, ticketCost) => acc + ticketCost.finalCostInCents, 0),
  };
}

export function deserializeCostDetails(raw: any) {
  const ticketIdToCost: Record<string, TicketCost> = {};
  for (const ticketId of Object.keys(raw.ticketIdToCost)) {
    ticketIdToCost[ticketId] = deserializeTicketCost(raw.ticketIdToCost[ticketId]);
  }
  return {
    ticketIdToCost: ticketIdToCost,
    subtotalInCents: +raw.subtotalInCents,
    finalCostInCents: +raw.finalCostInCents,
  } as CostDetails;
}

export function deserializeTicketCost(raw: any) {
  return {
    ticketId: raw.ticketId,
    subtotalInCents: +raw.subtotalInCents,
    costAdjustors: raw.costAdjustors
      .map((costAdjustor: any) => deserializeCostAdjustor(costAdjustor)),
    taxInCents: +raw.taxInCents,
    feeInCents: +raw.feeInCents,
    finalCostInCents: +raw.finalCostInCents,
  } as TicketCost;
}

export function deserializeCostAdjustor(raw: any) {
  return {
    adjustorId: raw.adjustorId,
    adjustorName: raw.adjustorName,
    adjustmentInCents: +raw.adjustmentInCents,
  } as CostAdjustor;
}

export function deserializeTicketCostPreview(raw: any) {
  return {
    selectedTicketOptionIdx: +raw.selectedTicketOptionIdx,
    ticketCost: deserializeTicketCost(raw.ticketCost),
  } as TicketCostPreview;
}

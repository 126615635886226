import * as React from 'react';
import { Box, Container, CssBaseline } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

interface IProps {
  children: React.ReactNode;
  centered?: boolean;
  sx?: SxProps<Theme>;
}

// This is the standard page component for screens.
// Every screen should use this as the root.
const Page: React.FC<IProps> = ({ children, centered, sx }) => {
  return centered ? (
    <Container sx={sx}>
      <CssBaseline />
      {children}
    </Container>
  ) : (
    <Box sx={sx}>
      <CssBaseline />
      {children}
    </Box>
  );
};

export default Page;

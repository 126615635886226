import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import { Box, Divider, IconButton, Menu, MenuItem, Stack } from '@mui/material';

import { Colors } from 'common/src/constants';
import { AuthContext } from 'common/src/context/auth';
import { DefaultStyleAttrs } from '../constants/styles';

import LogoSvg from 'common/src/assets/svgs/logoSvg';
import { Text } from 'common/src/components/base';
interface IProps {
  addMarginX?: boolean
}
const TopMenu: React.FC<IProps> = ({ addMarginX }) => {
  const navigate = useNavigate();

  const { authStatus, logout } = React.useContext(AuthContext);
  const { t, i18n } = useTranslation('wbevt.home');
  const isEn = i18n.language === 'en';
  const isLoggedIn = authStatus === 'loggedIn';

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuType, setMenuType] = React.useState<'main' | 'lang'>('main');

  const isMainMenuOpen = !!menuAnchorEl && menuType === 'main';
  const isLangMenuOpen = !!menuAnchorEl && menuType === 'lang';
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuType('main');
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };
  const handleToggleMenu = (type: 'main' | 'lang') => {
    setMenuType(type);
  };

  return (
    <Stack direction='row'
      left={0} right={0} color={Colors.PRIMARY_BACKGROUND} position='relative'
      py={10}
      justifyContent='space-between' alignItems='center'
      mx={addMarginX ? DefaultStyleAttrs.responsiveMx : 0}
    >
      <IconButton onClick={() => navigate('/')}>
        <LogoSvg size={35} />
      </IconButton>
      <Stack direction='row'>
        <IconButton onClick={handleOpenMenu}>
          <AccountCircleIcon sx={{ color: Colors.PRIMARY, fontSize: 30 }} />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={isMainMenuOpen}
          onClose={handleCloseMenu}
        >
          {!isLoggedIn && (
            <MenuItem onClick={() => {
              navigate('/login');
              handleCloseMenu();
            }} sx={{ py: 10, pr: 70 }}>
              <LoginIcon sx={{ mr: 10 }} />
              {t('topMenu.login')}
            </MenuItem>
          )}
          {isLoggedIn && (
            <MenuItem onClick={() => {
              navigate('/myevents');
              handleCloseMenu();
            }} sx={{ py: 10, pr: 70 }}>
              <LocalActivityOutlinedIcon sx={{ mr: 10 }} />
              {t('topMenu.myEvent')}
            </MenuItem>
          )}
          <MenuItem onClick={() => {
            handleToggleMenu('lang');
          }} sx={{ py: 10, pr: 70 }}>
            <TranslateIcon sx={{ mr: 10 }} />
            {t('topMenu.language')}
          </MenuItem>
          {isLoggedIn && (
            <MenuItem onClick={() => {
              navigate('/profile');
              handleCloseMenu();
            }} sx={{ py: 10, pr: 70 }}>
              <AccountCircleOutlinedIcon sx={{ mr: 10 }} />
              {t('topMenu.personalInfo')}
            </MenuItem>
          )}
          {isLoggedIn && (
            <MenuItem onClick={() => {
              logout();
              handleCloseMenu();
            }} sx={{ py: 10, pr: 70 }}>
              <LogoutOutlinedIcon sx={{ mr: 10 }} />
              {t('topMenu.logout')}
            </MenuItem>
          )}
        </Menu>
        <Menu
          anchorEl={menuAnchorEl}
          open={isLangMenuOpen}
          onClose={handleCloseMenu}
        >
          <Box display='flex' sx={{ py: 5, pl: 5, pr: 15 }} justifyItems='center' alignItems='center' alignContent='center'>
            <IconButton onClick={() => handleToggleMenu('main')}>
              <ArrowBackIcon />
            </IconButton>
            <Text size='subtitle'>{t('topMenu.chooseLang')}</Text>
          </Box>
          <Divider />
          <MenuItem onClick={() => {
            i18n.changeLanguage('en');
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <CheckIcon fontSize='small' sx={{ mr: 10, opacity: isEn ? 1 : 0 }} />
            <Text size='paragraph'>English</Text>
          </MenuItem>
          <MenuItem onClick={() => {
            i18n.changeLanguage('zh');
            handleCloseMenu();
          }} sx={{ py: 10, pr: 70 }}>
            <CheckIcon fontSize='small' sx={{ mr: 10, opacity: isEn ? 0 : 1 }} />
            <Text size='paragraph'>中文</Text>
          </MenuItem>
        </Menu>
      </Stack>
    </Stack>
  );
};

export default TopMenu;

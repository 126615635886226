import { UserRole } from 'common/src/models/user';
import { GetPersistentTokenErrCode, RegistrationData, RegistrationStatus } from '../../models/registration';
import RequestUtils from '../../utils/request';

export async function verifyPhoneRemote(phoneNumber: string): Promise<{
  errCode: string,
}> {
  const req: any = {
    verifyContact: {
      type: 'phone',
      principal: phoneNumber,
    },
  };
  const resp = await RequestUtils.invokeRemoteApi(req, {});

  return {
    errCode: resp.verifyContact.errCode,
  };
}

export async function getPhoneTokenRemote(phoneNumber: string, smsCode: string): Promise<{
  errCode: string,
  token: string,
}> {
  // See https://github.com/hankduan/taro/blob/master/backend/backend-api-spec/src/main/proto/com/taro/backend/api-misc.proto#L25
  const req: any = {
    getContactToken: {
      type: 'phone',
      principal: phoneNumber,
      secret: smsCode,
    },
  };
  const resp = await RequestUtils.invokeRemoteApi(req, {});

  return {
    errCode: resp.getContactToken.errCode,
    token: resp.getContactToken.token,
  };
}

export async function registerUserRemote(
  data: RegistrationData,
  deviceId: string,
): Promise<{
  status: RegistrationStatus,
  persistentToken: string,
  sessionToken: string,
  ttlTs: number,
  userRole: UserRole;
}> {
  const { phoneToken, email } = data;

  const req: any = {
    registerUser: {
      phoneToken,
      email,
      accountPreference: {
        language: 'zh', // TODO(hank): get from system
      },
      deviceId: deviceId,
      userGroup: 'general',
    },
  };
  const resp = await RequestUtils.invokeRemoteApi(req, {});

  return {
    status: resp.registerUser.registrationStatus as RegistrationStatus,
    persistentToken: resp.registerUser.persistentToken || '',
    sessionToken: resp.registerUser.sessionToken || '',
    ttlTs: resp.registerUser.ttlTs || 0,
    userRole: resp.registerUser.userRole as UserRole,
  };
}

export async function getPersistentTokenRemote(
  phoneToken: string,
): Promise<{
  errCode?: GetPersistentTokenErrCode
  persistentToken: string,
}> {
  const req: any = {
    getPersistentToken: {
      phoneToken: phoneToken,
    },
  };
  const resp = await RequestUtils.invokeRemoteApi(req, {});

  const errCode = resp.getPersistentToken.errCode ?
    resp.getPersistentToken.errCode as GetPersistentTokenErrCode :
    undefined;
  return {
    errCode: errCode,
    persistentToken: resp.getPersistentToken.persistentToken || '',
  };
}

export async function getSessionRemote(
  persistentToken: string,
  deviceId: string,
): Promise<{
  userRegistered: boolean;
  sessionToken: string;
  ttlTs: number;
  userRole: UserRole;
}> {
  const req: any = {
    getSession: {
      persistentToken: persistentToken,
      deviceId: deviceId,
    },
  };
  const resp = await RequestUtils.invokeRemoteApi(req, {});

  return {
    userRegistered: !!resp.getSession.userRegistered,
    sessionToken: resp.getSession.sessionToken || '',
    ttlTs: +resp.getSession.ttlTs || 0,
    userRole: resp.getSession.userRole as UserRole,
  };
}

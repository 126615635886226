import * as Yup from 'yup';

import { SurveyQuestion, SurveyResponse } from 'common/src/models/event';

export const questionnaireInputSchema = Yup.string().min(1, '请填写').required('请填写');

export const validateQuestionnaire = (
  surveyQuestions: SurveyQuestion[], surveyResponses: SurveyResponse[],
) => {
  const hasInvalid = surveyQuestions.find((survey) => {
    const surveyResp = surveyResponses.find((res) => res.id == survey.id);
    if (!questionnaireInputSchema.isValidSync(surveyResp?.response)) {
      return true;
    }
  });
  return !hasInvalid;
};
